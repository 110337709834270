<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>거래상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">진행내역</button>
              <button :class="{ tab: true, on: on == 'tab2' }" @click="() => (this.on = 'tab2')">상품</button>
              <button :class="{ tab: true, on: on == 'tab3' }" @click="() => (this.on = 'tab3')">구매자</button>
            </div>
            <div class="btns">
              <button v-show="on == 'tab1'" class="btn" @click="fnClickSave(true)">저장후 계속</button>
              <button v-show="on == 'tab1'" class="btn" @click="fnClickSave(false)">저장</button>
              <button class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div v-show="this.on == 'tab1'">
            <OrderTab1 ref="tab1" :orderDetail="orderDetail" />
          </div>
          <div v-show="this.on == 'tab2'">
            <OrderTab2 :orderDetail="orderDetail" />
          </div>
          <div v-show="this.on == 'tab3'">
            <OrderTab3 :userId="orderDetail.user_id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderTab1 from './OrderTab1';
import OrderTab2 from './OrderTab2';
import OrderTab3 from './OrderTab3';

export default {
  data() {
    return {
      orderId: this.selectedOrderNo,
      on: 'tab1',
      orderDetail: {},
    };
  },
  props: ['selectedOrderNo'],
  components: {
    OrderTab1,
    OrderTab2,
    OrderTab3,
  },
  methods: {
    fnClosePop() {
      this.$emit('closePop');
    },
    async fnClickSave(skipClose) {
      let success = await this.$refs.tab1.fnSave();
      if (success) {
        this.$emit('reloadData');

        if (skipClose) {
          this.fnFetchOrderDetail();
        } else {
          this.fnClosePop();
        }
      }
    },

    async fnFetchOrderDetail() {
      const { result, data } = await this.$store.dispatch('openIp/orderStore/fetchOrderDetail', this.orderId);
      if (result) {
        this.orderDetail = data.contents;
      } else {
        this.$popupAlert({
          msg: `거래정보를 가져오는데 실패했습니다(${data.error.msg})`,
        });
        this.fnClosePop();
      }
    },
  },

  mounted() {
    this.fnFetchOrderDetail();
  },
};
</script>
