<template>
  <div>
    <!--
    <MobileCustomerList v-if="isMobile" key="MobileCustomerList" />
    -->
    <PcCustomerList key="PcCustomerList" />
  </div>
</template>

<script>
// import MobileCustomerList from '../../components/pages/allOk/MobileCustomerList.vue';
import PcCustomerList from "../../components/pages/allOk/PcCustomerList";

export default {
  components: {
    // MobileCustomerList,
    PcCustomerList,
  },
  data() {
    return {
      roles: ['ADMIN', 'ALL_OK_MNG', 'ALL_OK_CNSNLT'],
    };
  },
  computed: {
    isMobile(){
      const device = this.$store.state.ui.device;
      if(device){
        return device === 'mobile'
      }
      return true;
    }
  },
  methods:{},
  beforeMount() {
    this.$store.dispatch('allOk/allOkStore/fetchFileCodeList')
  }

};
</script>
