<template>
  <div class="search_date" :class="{ on: onOff }">
    <div class="s_top">
      <span class="start">{{ from.year }}-{{ from.month && $fill0(from.month) }}-{{ from.date && $fill0(from.date) }}</span>
      <span class="end">{{ to.year }}-{{ to.month && $fill0(to.month) }}-{{ to.date && $fill0(to.date) }}</span>
    </div>
    <div class="s_cont">
      <div class="item">
        <h4 class="tit f10">시작일</h4>
        <div class="bx">
          <Select
            defaultName="년"
            :itemList="yearList"
            classNm="ipt_year"
            :value.sync="from.year"
            @change="
              () => {
                this.from.month = null;
                this.from.date = null;
              }
            " />
          <Select
            defaultName="월"
            :itemList="monthList"
            classNm="ipt_month"
            :value.sync="from.month"
            @change="
              () => {
                this.from.date = null;
              }
            " />
          <Select defaultName="일" :itemList="fromDateList" classNm="ipt_day" :value.sync="from.date" />
        </div>
      </div>
      <div class="item">
        <h4 class="tit f10">종료일</h4>
        <div class="bx">
          <Select
            defaultName="년"
            :itemList="yearList"
            classNm="ipt_year"
            :value.sync="to.year"
            @change="
              () => {
                this.to.month = null;
                this.to.date = null;
              }
            " />
          <Select
            defaultName="월"
            :itemList="monthList"
            classNm="ipt_month"
            :value.sync="to.month"
            @change="
              () => {
                this.to.date = null;
              }
            " />
          <Select defaultName="일" :itemList="toDateList" classNm="ipt_day" :value.sync="to.date" />
        </div>
      </div>
    </div>
    <div class="colb mr5 mb5">
      <div class="col"></div>
      <div class="col">
        <button
          class="btn"
          @click="
            e => {
              e.stopPropagation();
              this.fnPropagationDate();
              this.$emit('cancel');
            }
          ">
          확인
        </button>
        <button
          class="btn l_gray"
          @click="
            e => {
              e.stopPropagation();
              this.fnRestore();
              this.$emit('cancel');
            }
          ">
          취소
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '~/components/common/Select';

export default {
  props: ['onOff', 'loading'],
  components: {
    Select,
  },

  data() {
    return {
      minYear: 2015,
      maxYear: 2030,
      minMonth: 1,
      maxMonth: 12,
      minDate: 1,
      from: {
        year: null,
        month: null,
        date: null,
      },
      to: {
        year: null,
        month: null,
        date: null,
      },
      backup: {
        from: {
          year: null,
          month: null,
          date: null,
        },
        to: {
          year: null,
          month: null,
          date: null,
        },
      },
    };
  },
  computed: {
    yearList() {
      const min = this.minYear;
      const max = this.maxYear;
      return this.fnSelectList(min, max, '년');
    },
    monthList() {
      const min = this.minMonth;
      const max = this.maxMonth;
      return this.fnSelectList(min, max, '월');
    },
    fromDateList() {
      const min = this.minDate;
      const max = this.fnFromMaxDate();
      return this.fnSelectList(min, max, '일');
    },
    toDateList() {
      const min = this.minDate;
      const max = this.fnToMaxDate();
      return this.fnSelectList(min, max, '일');
    },
  },

  methods: {
    fnPropagationDate() {
      if (this.loading) {
        this.fnRestore();
        return;
      }
      if (!this.from.year || !this.from.month || !this.from.date || !this.to.year || !this.to.month || !this.to.date) {
        this.fnRestore();
        return;
      }

      this.$emit('date', {
        fromDate: `${this.from.year}${this.$fill0(this.from.month)}${this.$fill0(this.from.date)}`,
        toDate: `${this.to.year}${this.$fill0(this.to.month)}${this.$fill0(this.to.date)}`,
      });
      this.fnBackup();
    },
    fnRestore() {
      this.from = { ...this.backup.from };
      this.to = { ...this.backup.to };
    },
    fnBackup() {
      this.backup.from = { ...this.from };
      this.backup.to = { ...this.to };
    },
    fnFromMaxDate() {
      if (this.from.year && this.from.month) {
        return new Date(this.from.year, this.from.month, 0).getDate();
      }
      return 31;
    },
    fnToMaxDate() {
      if (this.to.year && this.to.month) {
        return new Date(this.to.year, this.to.month, 0).getDate();
      }
      return 31;
    },
    fnSelectList(min, max, unit) {
      let list = [];
      if (min && max) {
        for (let a = min; a <= max; a++) {
          list.push({
            name: a + ' ' + unit,
            value: a,
          });
        }
      }
      return list;
    },
    fnSetDate(from, to) {
      if (from && to) {
        this.from.year = from.getFullYear();
        this.from.month = from.getMonth() + 1;
        this.from.date = from.getDate();
        this.to.year = to.getFullYear();
        this.to.month = to.getMonth() + 1;
        this.to.date = to.getDate();
        this.fnPropagationDate();
      }
    },
  },
};
</script>
