var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('Location',{attrs:{"list":[
      {
        name: 'MAIN',
        link: '',
      },
    ]}}),_vm._v(" "),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('h1',[_vm._v("BACK OFFICE")])])
}]

export { render, staticRenderFns }