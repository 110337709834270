<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>새로운 테마</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>테마명</h4>
                  <div class="i_wrap">
                    <Input class="mt5" id="themeNm" :value.sync="addThemeForm.themeName" name="addThemeForm.themeName" type="text" placeholder="테마명을 입력하세요." />
                  </div>
                </li>
                <li>
                  <h4>보고서 개요</h4>
                  <div class="i_wrap">
                    <textarea id="outline" class="textarea" placeholder="보고서 개요를 입력해주세요" style="height: 200px" v-model="addThemeForm.rptOutline"></textarea>
                  </div>
                </li>
                <li>
                  <h4>보고서 이슈/<br />트렌드</h4>
                  <div class="i_wrap">
                    <textarea id="issue" class="textarea" placeholder="보고서 이슈/트렌드를 입력해주세요" style="height: 200px" v-model="addThemeForm.rptIssue"></textarea>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>보고 일자</h4>
                  <div class="i_wrap">
                    <DatePicker id="rptDt" :value.sync="addThemeForm.rptDt" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>정렬순서</h4>
                  <div class="i_wrap">
                    <Input id="orderNo" :value.sync="addThemeForm.orderNo" name="addThemeForm.orderNo" type="text" placeholder="정렬순서를 입력해주세요." />
                    <span class="txt_red mt5" v-if="isNaN(addThemeForm.orderNo)">숫자를 입력하세요</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import DatePicker from '~/components/pages/tenbagger/DatePicker2';

export default {
  data() {
    return {
      addThemeForm: {
        themeName: null,
        themeDesc: null,
        orderNo: null,
        rptOutline: null,
        rptIssue: null,
        rptDt: null,
      },
    };
  },

  components: {
    Input,
    DatePicker,
  },

  mounted() {},

  methods: {
    fnClosePop() {
      this.$emit('closePop');
    },

    async fnClickSave(skipClose) {
      if (!this.addThemeForm.themeName) {
        this.$popupAlert({
          msg: '테마명을 입력하세요',
        });
        return;
      }

      if (isNaN(this.addThemeForm.orderNo)) {
        this.$popupAlert({
          msg: '정렬순서는 숫자를 입력하세요',
        });
        return;
      }

      this.addThemeForm.rptDt = this.addThemeForm.rptDt ? this.addThemeForm.rptDt.replace(/\-/g, '.') : null;
      const { result, data } = await this.$store.dispatch('tenbagger/theme/addTheme', this.addThemeForm);

      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '테마가 추가되었습니다',
        });
        this.$emit('reloadData');
      } else {
        this.$popupAlert({
          msg: `테마를 추가하는데 실패했습니다(${data.error.msg})`,
        });
      }

      if (skipClose) {
        this.resetaddThemeForm();
      } else {
        this.fnClosePop('popThemeCreateState');
      }
    },

    resetaddThemeForm() {
      this.addThemeForm = {
        themeName: null,
        themeDesc: null,
        orderNo: null,
        rptOutline: null,
        rptIssue: null,
        rptDt: null,
      };
    },
  },
};
</script>
