var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('Location',{attrs:{"list":[{
    name:'OPENIP',
    link:'',
  },{
    name:'거래방식',
    link:'',
  }]}}),_vm._v(" "),_c('div',{staticClass:"inner"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"area_search"},[_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"search1"}},[_vm._v("사업자번호")]),_vm._v(" "),_c('InputBox',{attrs:{"data":{
            type: 'text',
            placeholder: '검색어를 입력하세요.',
            id:'search1'
          }}})],1),_vm._v(" "),_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"search1"}},[_vm._v("고객명")]),_vm._v(" "),_c('InputBox',{attrs:{"data":{
            type: 'text',
            placeholder: '검색어를 입력하세요.',
            id:'search1'
          }}})],1),_vm._v(" "),_c('div',{staticClass:"unit"},[_c('label',{staticClass:"tit",attrs:{"for":"search2"}},[_vm._v("담당자")]),_vm._v(" "),_c('InputBox',{attrs:{"data":{
            type: 'text',
            placeholder: '검색어를 입력하세요.',
            id:'search2'
          }}})],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"unit"},[_c('h3',{staticClass:"tit"},[_vm._v("관리단계")]),_vm._v(" "),_c('Select',{attrs:{"title":"상담 대기 중","list":[
              { name: '셀렉트1' },
              { name: '셀렉트2' },
              { name: '셀렉트3' },
              { name: '셀렉트4' },
              { name: '셀렉트5' },
              { name: '셀렉트6' },
              { name: '셀렉트7' },
              { name: '셀렉트8' },
            ]}})],1),_vm._v(" "),_c('div',{staticClass:"unit"},[_c('h3',{staticClass:"tit"},[_vm._v("진행단계")]),_vm._v(" "),_c('Select',{attrs:{"title":"실권자미팅시도중","list":[
              { name: '셀렉트1' },
              { name: '셀렉트2' },
              { name: '셀렉트3' },
              { name: '셀렉트4' },
              { name: '셀렉트5' },
              { name: '셀렉트6' },
              { name: '셀렉트7' },
              { name: '셀렉트8' },
            ]}})],1),_vm._v(" "),_c('div',{staticClass:"unit unit_date"},[_c('h3',{staticClass:"tit"},[_vm._v("기간설정")]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('div',{staticClass:"view_date"},[_c('span',{staticClass:"start"},[_vm._v("2022년 6월 16일")]),_vm._v(" "),_c('span',{staticClass:"end"},[_vm._v("2022년 6월 16일")]),_vm._v(" "),_c('div',{staticClass:"search_date on"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"s_cont"},[_c('div',{staticClass:"item"},[_c('h4',{staticClass:"tit"},[_vm._v("\n                    시작일\n                  ")]),_vm._v(" "),_c('div',{staticClass:"bx"},[_c('Select',{attrs:{"title":"2022년","list":[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_year"}}),_vm._v(" "),_c('Select',{attrs:{"title":"12월","list":[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_month"}}),_vm._v(" "),_c('Select',{attrs:{"title":"31일","list":[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_day"}})],1)]),_vm._v(" "),_c('div',{staticClass:"item"},[_c('h4',{staticClass:"tit"},[_vm._v("\n                    종료일\n                  ")]),_vm._v(" "),_c('div',{staticClass:"bx"},[_c('Select',{attrs:{"title":"2022년","list":[
                          { name: '2002년' },
                        ],"classNm":"ipt_year"}}),_vm._v(" "),_c('Select',{attrs:{"title":"12월","list":[
                          { name: '12월' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_month"}}),_vm._v(" "),_c('Select',{attrs:{"title":"31일","list":[
                          { name: '31일' },
                        ],"classNm":"ipt_day"}})],1)]),_vm._v(" "),_vm._m(2)])])]),_vm._v(" "),_c('button',{staticClass:"btn l_gray"},[_vm._v("1개월")]),_vm._v(" "),_c('button',{staticClass:"btn l_gray"},[_vm._v("3개월")])])])]),_vm._v(" "),_c('div',{staticClass:"colb"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"col unit_page"},[_c('h4',{staticClass:"l_tit"},[_vm._v("페이지당 개수 : ")]),_vm._v(" "),_c('Select',{attrs:{"title":"5개","list":[
              { name: '5개' },
              { name: '10개' },
              { name: '15개' },
            ]}}),_vm._v(" "),_c('button',{staticClass:"btn"},[_vm._v("검색")])],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area"},[_c('table',{staticClass:"tbl"},[_vm._m(4),_vm._v(" "),_c('tbody',[_vm._m(5),_vm._v(" "),_vm._l((9),function(i){return _c('tr',{key:i},[_c('td',{staticClass:"c1"},[_vm._v("519801234")]),_vm._v(" "),_c('td',{staticClass:"c2"},[_vm._v("히즈케어스(주)")]),_vm._v(" "),_c('td',{staticClass:"c3"},[_vm._v("나애리")]),_vm._v(" "),_c('td',{staticClass:"c4"},[_vm._v("상담중")]),_vm._v(" "),_c('td',{staticClass:"c5"},[_vm._v("실권자미팅시도중")]),_vm._v(" "),_c('td',{staticClass:"c6"},[_vm._v("2022-09-01")]),_vm._v(" "),_c('td',{staticClass:"c7"},[_vm._v("2022-09-02")])])})],2)])]),_vm._v(" "),_vm._m(6)])]),_vm._v(" "),_c('div',{staticClass:"inner bg_white"},[_c('ul',{staticClass:"form_list"},[_c('li',{staticClass:"half"},[_vm._m(7),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name' }}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(8),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(9),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(10),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(11),_vm._v(" "),_c('div',{staticClass:"view_date"},[_c('span',{staticClass:"start"},[_vm._v("2022년 6월 16일")]),_vm._v(" "),_c('div',{staticClass:"search_date"},[_vm._m(12),_vm._v(" "),_c('div',{staticClass:"s_cont"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"bx"},[_c('Select',{attrs:{"title":"2022년","list":[
                        { name: '셀렉트1' },
                        { name: '셀렉트2' },
                        { name: '셀렉트3' },
                        { name: '셀렉트4' },
                        { name: '셀렉트5' },
                        { name: '셀렉트6' },
                        { name: '셀렉트7' },
                        { name: '셀렉트8' },
                      ],"classNm":"ipt_year"}}),_vm._v(" "),_c('Select',{attrs:{"title":"12월","list":[
                        { name: '셀렉트1' },
                        { name: '셀렉트2' },
                        { name: '셀렉트3' },
                        { name: '셀렉트4' },
                        { name: '셀렉트5' },
                        { name: '셀렉트6' },
                        { name: '셀렉트7' },
                        { name: '셀렉트8' },
                      ],"classNm":"ipt_month"}}),_vm._v(" "),_c('Select',{attrs:{"title":"31일","list":[
                        { name: '셀렉트1' },
                        { name: '셀렉트2' },
                        { name: '셀렉트3' },
                        { name: '셀렉트4' },
                        { name: '셀렉트5' },
                        { name: '셀렉트6' },
                        { name: '셀렉트7' },
                        { name: '셀렉트8' },
                      ],"classNm":"ipt_day"}})],1)]),_vm._v(" "),_vm._m(13)])])])]),_vm._v(" "),_c('li',{staticClass:"half"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('Select',{attrs:{"title":"특허문의","list":[
          {name: '셀렉트1'},
          {name: '셀렉트2'},
          {name: '셀렉트3'},
          {name: '셀렉트4'},
        ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("소재지")]),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"item_radio top_line"},[_c('h4',[_vm._v("인증보유현황")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
              title: '연구소',
              id: 1,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '연구전단부서',
              id: 1,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '벤처인증',
              id: 1,
              name: 'radio',
              disabled: true,
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '이노비즈',
              id: 1,
              name: 'radio',
              disabled: true,
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '미보유',
              id: 1,
              name: 'radio',
              disabled: true,
            }}})],1)]),_vm._v(" "),_vm._m(14),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("대표자 연령")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
              title: '만 39세 미만',
              id: 2,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '만 39세 이상',
              id: 2,
              name: 'radio',
            }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("대표자 연령")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
              title: '만 39세 미만',
              id: 2,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '만 39세 이상',
              id: 2,
              name: 'radio',
            }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("직원수")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
              title: '5인미만',
              id: 2,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '5인 이상 ~ 10인 미만',
              id: 2,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '10인 이상',
              id: 2,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '잘 모르겠어요',
              id: 2,
              name: 'radio',
            }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("고용계획")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
              title: '네, 있어요',
              id: 9,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '잘 모르겠어요',
              id: 9,
              name: 'radio',
            }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("특허추천")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
              title: '네, 추천받고 싶어요',
              id: 10,
              name: 'radio',
            }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
              title: '잘 모르겠어요',
              id: 10,
              name: 'radio',
            }}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"inner contents_col2 bg_white"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"form_list"},[_c('li',{staticClass:"half"},[_vm._m(15),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name' }}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(16),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(17),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(18),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"half"},[_vm._m(19),_vm._v(" "),_c('div',{staticClass:"view_date"},[_c('span',{staticClass:"start"},[_vm._v("2022년 6월 16일")]),_vm._v(" "),_c('div',{staticClass:"search_date"},[_vm._m(20),_vm._v(" "),_c('div',{staticClass:"s_cont"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"bx"},[_c('Select',{attrs:{"title":"2022년","list":[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_year"}}),_vm._v(" "),_c('Select',{attrs:{"title":"12월","list":[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_month"}}),_vm._v(" "),_c('Select',{attrs:{"title":"31일","list":[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ],"classNm":"ipt_day"}})],1)]),_vm._v(" "),_vm._m(21)])])])]),_vm._v(" "),_c('li',{staticClass:"half"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('Select',{attrs:{"title":"특허문의","list":[
            {name: '셀렉트1'},
            {name: '셀렉트2'},
            {name: '셀렉트3'},
            {name: '셀렉트4'},
          ]}})],1),_vm._v(" "),_c('li',[_c('h4',[_vm._v("소재지")]),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('li',{staticClass:"item_radio top_line"},[_c('h4',[_vm._v("인증보유현황")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '연구소',
                id: 1,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '연구전단부서',
                id: 1,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '벤처인증',
                id: 1,
                name: 'radio',
                disabled: true,
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '이노비즈',
                id: 1,
                name: 'radio',
                disabled: true,
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '미보유',
                id: 1,
                name: 'radio',
                disabled: true,
              }}})],1)]),_vm._v(" "),_vm._m(22),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("대표자 연령")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '만 39세 미만',
                id: 2,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '만 39세 이상',
                id: 2,
                name: 'radio',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("대표자 연령")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '만 39세 미만',
                id: 2,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '만 39세 이상',
                id: 2,
                name: 'radio',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("직원수")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '5인미만',
                id: 2,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '5인 이상 ~ 10인 미만',
                id: 2,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '10인 이상',
                id: 2,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '잘 모르겠어요',
                id: 2,
                name: 'radio',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("고용계획")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '네, 있어요',
                id: 9,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '잘 모르겠어요',
                id: 9,
                name: 'radio',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("특허추천")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '네, 추천받고 싶어요',
                id: 10,
                name: 'radio',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '잘 모르겠어요',
                id: 10,
                name: 'radio',
              }}})],1)])]),_vm._v(" "),_c('ul',{staticClass:"form_list type2"},[_vm._m(23),_vm._v(" "),_vm._m(24),_vm._v(" "),_vm._m(25),_vm._v(" "),_vm._m(26),_vm._v(" "),_vm._m(27),_vm._v(" "),_vm._m(28),_vm._v(" "),_vm._m(29),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("인증보유현황")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '연구소',
                id: 1,
                name: 'radio1',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '연구전단부서',
                id: 2,
                name: 'radio1',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '벤처인증',
                id: 3,
                name: 'radio1',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '이노비즈',
                id: 4,
                name: 'radio1',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '미보유',
                id: 5,
                name: 'radio1',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("대표자 연령")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '만 39세 미만',
                id: 1,
                name: 'radio3',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '만 39세 이상',
                id: 2,
                name: 'radio3',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("직원수")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '5인미만',
                id: 1,
                name: 'radio4',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '5인 이상 ~ 10인 미만',
                id: 2,
                name: 'radio4',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '10인 이상',
                id: 3,
                name: 'radio4',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '잘 모르겠어요',
                id: 4,
                name: 'radio4',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("고용계획")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '네, 있어요',
                id: 1,
                name: 'radio5',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '잘 모르겠어요',
                id: 2,
                name: 'radio5',
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_radio"},[_c('h4',[_vm._v("특허추천")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('RadioBox',{attrs:{"data":{
                title: '네, 추천받고 싶어요',
                id: 1,
                name: 'radio6',
              }}}),_vm._v(" "),_c('RadioBox',{attrs:{"data":{
                title: '잘 모르겠어요',
                id: 2,
                name: 'radio6',
              }}})],1)]),_vm._v(" "),_vm._m(30),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("소재지")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name'}}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"cole"},[_vm._m(31),_vm._v(" "),_c('InputBox',{attrs:{"classNm":"half type2","data":{
                  shape: 'search',
                  type: 'text',
                  placeholder: '검색어를 입력하세요.',
                }}})],1)])]),_vm._v(" "),_vm._m(32),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"cole"},[_c('Select',{attrs:{"classNm":"half","title":"특허문의","list":[
                {name: '셀렉트1'},
                {name: '셀렉트2'},
                {name: '셀렉트3'},
                {name: '셀렉트4'},
              ]}}),_vm._v(" "),_c('InputBox',{attrs:{"classNm":"half","data":{ type: 'text', id: 'name'}}})],1),_vm._v(" "),_c('div',{staticClass:"cole"},[_c('div',{staticClass:"temp"}),_vm._v(" "),_c('InputBox',{attrs:{"classNm":"half","data":{ type: 'text', id: 'name'}}})],1)])]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"cole"},[_c('Select',{attrs:{"classNm":"half","title":"특허문의","list":[
                {name: '셀렉트1'},
                {name: '셀렉트2'},
                {name: '셀렉트3'},
                {name: '셀렉트4'},
              ]}}),_vm._v(" "),_c('InputBox',{attrs:{"classNm":"half type2","data":{
                  shape: 'search',
                  type: 'text',
                  placeholder: '검색어를 입력하세요.',
                }}})],1),_vm._v(" "),_c('div',{staticClass:"cole"},[_c('div',{staticClass:"temp"}),_vm._v(" "),_c('InputBox',{attrs:{"classNm":"half type2","data":{
                  shape: 'search',
                  type: 'text',
                  placeholder: '검색어를 입력하세요.',
                }}})],1)])])])]),_vm._v(" "),_c('div',{staticClass:"right bg_gray"},_vm._l((10),function(i){return _c('div',{key:i,staticClass:"list_goods"},[_vm._m(33,true)])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"colr"},[_c('button',{staticClass:"btn_reset"},[_vm._v("전체초기화")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s_top"},[_c('span',{staticClass:"start"},[_vm._v("2022년 12월 16일")]),_vm._v(" "),_c('span',{staticClass:"end"},[_vm._v("2022년 12월 33일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn"},[_vm._v("확인")]),_vm._v(" "),_c('button',{staticClass:"btn gray"},[_vm._v("취소")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('button',{staticClass:"btn"},[_vm._v("상품 등록")]),_vm._v(" "),_c('button',{staticClass:"btn"},[_vm._v("열기")]),_vm._v(" "),_c('button',{staticClass:"btn dark"},[_vm._v("삭제")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"c1"},[_c('b',[_vm._v("사업자번호")])]),_vm._v(" "),_c('th',{staticClass:"c2"},[_c('b',[_vm._v("고객명")])]),_vm._v(" "),_c('th',{staticClass:"c3"},[_c('b',[_vm._v("담당자")])]),_vm._v(" "),_c('th',{staticClass:"c4"},[_c('b',[_vm._v("관리단계")])]),_vm._v(" "),_c('th',{staticClass:"c5"},[_c('b',[_vm._v("진행상태")]),_vm._v(" "),_c('div',{staticClass:"bx_srot"},[_c('button',{staticClass:"btn_up"},[_vm._v("오름차순정렬")]),_vm._v(" "),_c('button',{staticClass:"btn_down"},[_vm._v("내림차순정렬")])])]),_vm._v(" "),_c('th',{staticClass:"c6"},[_c('b',[_vm._v("신청일")]),_vm._v(" "),_c('div',{staticClass:"bx_srot"},[_c('button',{staticClass:"btn_up"},[_vm._v("오름차순정렬")]),_vm._v(" "),_c('button',{staticClass:"btn_down"},[_vm._v("내림차순정렬")])])]),_vm._v(" "),_c('th',{staticClass:"c7"},[_c('b',[_vm._v("마지막처리일")]),_vm._v(" "),_c('div',{staticClass:"bx_srot"},[_c('button',{staticClass:"btn_up"},[_vm._v("오름차순정렬")]),_vm._v(" "),_c('button',{staticClass:"btn_down"},[_vm._v("내림차순정렬")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"on"},[_c('td',{staticClass:"c1"},[_vm._v("519801234")]),_vm._v(" "),_c('td',{staticClass:"c2"},[_vm._v("히즈케어스(주)")]),_vm._v(" "),_c('td',{staticClass:"c3"},[_vm._v("나애리")]),_vm._v(" "),_c('td',{staticClass:"c4"},[_vm._v("상담중")]),_vm._v(" "),_c('td',{staticClass:"c5"},[_vm._v("실권자미팅시도중")]),_vm._v(" "),_c('td',{staticClass:"c6"},[_vm._v("2022-09-01")]),_vm._v(" "),_c('td',{staticClass:"c7"},[_vm._v("2022-09-02")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination"},[_c('button',{staticClass:"btn_start"},[_vm._v("맨 앞으로")]),_vm._v(" "),_c('button',{staticClass:"btn_prev"},[_vm._v("이전")]),_vm._v(" "),_c('a',{staticClass:"btn_page",attrs:{"href":"#"}},[_vm._v("1")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("2")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("3")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("4")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("5")]),_vm._v(" "),_c('button',{staticClass:"btn_page on"},[_vm._v("6")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("7")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("8")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("9")]),_vm._v(" "),_c('button',{staticClass:"btn_page"},[_vm._v("10")]),_vm._v(" "),_c('button',{staticClass:"btn_next"},[_vm._v("다음")]),_vm._v(" "),_c('button',{staticClass:"btn_end"},[_vm._v("맨 뒤로")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("회사명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("대표자명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("이메일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("연락처")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("설립일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s_top"},[_c('span',{staticClass:"start"},[_vm._v("2022년 12월 16일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn"},[_vm._v("확인")]),_vm._v(" "),_c('button',{staticClass:"btn gray"},[_vm._v("취소")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item_skill"},[_c('h4',[_vm._v("기술보유현황")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_org"},[_vm._v("8개")])]),_vm._v(" "),_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_red"},[_vm._v("8개")])]),_vm._v(" "),_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_red"},[_vm._v("8개")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("회사명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("대표자명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("이메일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("연락처")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("설립일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s_top"},[_c('span',{staticClass:"start"},[_vm._v("2022년 12월 16일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn"},[_vm._v("확인")]),_vm._v(" "),_c('button',{staticClass:"btn gray"},[_vm._v("취소")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item_skill"},[_c('h4',[_vm._v("기술보유현황")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_org"},[_vm._v("8개")])]),_vm._v(" "),_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_red"},[_vm._v("8개")])]),_vm._v(" "),_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_red"},[_vm._v("8개")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"half"},[_c('h4',[_vm._v("회사명")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("바이오니아")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"half"},[_c('h4',[_vm._v("대표자명")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("ㅇㅇㅇ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"half"},[_c('h4',[_vm._v("이메일")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("test@test.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"half"},[_c('h4',[_vm._v("연락처")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("000-0000-0000")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"half"},[_c('h4',[_vm._v("설립일")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("2000.00.30")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"half"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("2000.00.30")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h4',[_vm._v("소재지")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v("서울시 서울구 서울로 1000")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item_skill"},[_c('h4',[_vm._v("기술보유현황")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_org"},[_vm._v("8개")])]),_vm._v(" "),_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_red"},[_vm._v("8개")])]),_vm._v(" "),_c('div',{staticClass:"bx_line"},[_c('h5',[_vm._v("보유특허수")]),_vm._v(" "),_c('strong',{staticClass:"txt_red"},[_vm._v("8개")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"half"},[_c('div',{staticClass:"bx_txt"},[_vm._v("서울시 서울 서울로 서울집값")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("업종")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('div',{staticClass:"cole"},[_c('div',{staticClass:"half"},[_c('div',{staticClass:"bx_txt"},[_vm._v("서울시 서울 서울로 서울집값")])]),_vm._v(" "),_c('div',{staticClass:"half bx_txt_search"},[_c('div',{staticClass:"bx_txt"},[_vm._v("서울시 서울 서울로 서울집값")]),_vm._v(" "),_c('button',{staticClass:"btn_search type2"},[_vm._v("검색")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"unit"},[_c('dl',[_c('dt',[_vm._v("상품명")]),_vm._v(" "),_c('dd',[_c('b',{staticClass:"f15"},[_vm._v("시니어기술 창업자금")])])]),_vm._v(" "),_c('dl',[_c('dt',[_vm._v("금융방식")]),_vm._v(" "),_c('dd',[_c('b',{staticClass:"txt_label"},[_vm._v("TCB기술금융")]),_c('b',{staticClass:"txt_label grean"},[_vm._v("TCB기술금융")]),_c('b',{staticClass:"txt_label org"},[_vm._v("TCB기술금융")])])]),_vm._v(" "),_c('dl',{staticClass:"half"},[_c('dt',[_vm._v("지원한도")]),_vm._v(" "),_c('dd',[_c('b',[_vm._v("최고 7천만원")])])]),_vm._v(" "),_c('dl',{staticClass:"half"},[_c('dt',[_vm._v("대출기간")]),_vm._v(" "),_c('dd',[_vm._v("기관별 상이")])]),_vm._v(" "),_c('dl',{staticClass:"half"},[_c('dt',[_vm._v("보증비율")]),_vm._v(" "),_c('dd',[_vm._v("95%")])]),_vm._v(" "),_c('dl',{staticClass:"half"},[_c('dt',[_vm._v("보증료율")]),_vm._v(" "),_c('dd',[_c('b',[_vm._v("연 0.8%")])])]),_vm._v(" "),_c('dl',[_c('dt',[_vm._v("금융기관")]),_vm._v(" "),_c('dd',[_vm._v("신용보증재단")])]),_vm._v(" "),_c('dl',[_c('dt',[_vm._v("비고")]),_vm._v(" "),_c('dd',[_vm._v("대표자가 대기업·중견기업·정부출연연구소 경력 보유자, 기술사, 이공계 석·박사 학위 보유자")])])])
}]

export { render, staticRenderFns }