var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('LocationWrapper',{attrs:{"list":[
      {
        name: 'OPENIP',
        link: '',
      },
      {
        name: '거래방식',
        link: '',
      },
    ]}}),_vm._v(" "),_c('div',{staticClass:"inner"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('client-only',[_c('grid',{attrs:{"data":_vm.gridProps.data,"columns":_vm.gridProps.columns}})],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('button',{staticClass:"btn"},[_vm._v("상품 등록")]),_vm._v(" "),_c('button',{staticClass:"btn"},[_vm._v("열기")]),_vm._v(" "),_c('button',{staticClass:"btn dark"},[_vm._v("삭제")])])
}]

export { render, staticRenderFns }