<template>
  <div class="i_wrap">
    <div class="view_date" @click="()=>fnSearchRangeType('etc')">
      <span class="start">{{ fromDate }}</span>
      <DatePicTooltipSingle
                      :onOff="tooltipOnOff"
                      :defaultDate="defaultDate"
                      @date="fnDateChanged"
                      @cancel="()=>this.tooltipOnOff=false"
      />
    </div>
  </div>
</template>

<script>
import DatePicTooltipSingle from "./DatePicTooltipSingle";
export default {
  name: 'DateRangeTabsSingle',
  props: {
    defaultDate: {
      type: String,
    }
  },
  components:{
    DatePicTooltipSingle,
  },
  data(){
    return {
      fromDate: null,
      toDate: null,
      defaultRangeType: '1m',
      searchRangeType : '1m',
      rangeFormDisplay : 'none',
      tooltipOnOff: false,
    }
  },
  methods:{
    fnSearchRangeType(type){
      this.searchRangeType=type;
      if('etc' === type){
        this.diffMonth = null;
        this.rangeFormDisplay = 'block';
        this.tooltipOnOff = true;
      }
      else if ('1m' === type){
        this._fnSetDate();
        this.rangeFormDisplay = 'none';
        this.tooltipOnOff = false;
        this.fnChanged();
      }
      else if ('3m' === type){
        this._fnSetDate()
        this.rangeFormDisplay = 'none';
        this.tooltipOnOff = false;
        this.fnChanged();
      }
    },
    _fnSetDefaultDate(){
      this._fnSetDate()
    },
    _fnSetDate(){
      this.diffMonth = parseInt(this.searchRangeType.replace('m',''));
    },
    _fnDateFormat(date){
      let year = date.getFullYear();
      let month = (date.getMonth() < 10) ? month = '0' + (date.getMonth() + 1) : month = date.getMonth() + 1;
      let day = (date.getDate() < 10) ? this.day = '0' + date.getDate() : this.day = date.getDate();
      return year + '-' + month + '-' + day;
    },
    fnDateChanged({fromDate, toDate}) {
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.tooltipOnOff = false;
      this.fnChanged();
    },
    fnChanged(){
      // console.log('fnChanged', this.fromDate, this.toDate)
      this.$emit('change', {fromDate: this.fromDate})
    },
    fnReset(){
      if(this.searchRangeType !== this.defaultRangeType){
        this.searchRangeType = this.defaultRangeType;
        this._fnSetDate()
        return true;
      }
      return false;
    }
  },
  mounted() {
    this._fnSetDefaultDate();
  },
}
</script>
