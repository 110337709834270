<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>종목 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner class="mt5 mr5" v-if="loading" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>종목번호</h4>
                  <div class="i_wrap mt10">
                    <span>{{ stkDetail.isuSrtCd }}</span>
                  </div>
                </li>
                <li>
                  <h4>종목명</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.isuKorNm }}</span>
                  </div>
                </li>
                <li>
                  <h4>상장일자</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.listDd && stkDetail.listDd.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3') }}</span>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>공모가</h4>
                  <div class="i_wrap">
                    <Input :value.sync="ofrPrc" name="ofrPrc" type="text" placeholder="공모가를 입력하세요" />
                  </div>
                </li>
                <li>
                  <h4>업종</h4>
                  <div class="i_wrap">
                    <span>{{ fnGetMktName(stkDetail.mktCd) }}</span>
                  </div>
                </li>
                <li>
                  <h4>시총</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.mktCap && fnFormatNumber(stkDetail.corp.mktCap) }}억원 </span>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>섹터</h4>
                  <div class="i_wrap">
                    <SelectSearch :itemList="sectorList" :value.sync="rprSector" name="rprSector" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>대표테마</h4>
                  <div class="i_wrap">
                    <SelectSearch :itemList="themeList" :value.sync="rprTheme" name="rprSector" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>소속테마</h4>
                  <div class="i_wrap">
                    <div class="cole">
                      <SelectSearch class="mr5" :itemList="themeList" :value.sync="selectedTheme" name="selectedTheme" @change="fnAddTheme" />
                      <button style="width: 15%" class="btn b_refresh" @click="fnRefreshTheme">새로고침</button>
                    </div>
                    <ul style="width: 100%">
                      <li v-for="(el, idx) in belongingTheme" :key="idx">
                        <div class="i_wrap colb">
                          <span class="f14 mr5">{{ fnGetThemeName(el) }}</span>
                          <button class="btn dark" @click="fnRemoveTheme(idx)">-</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <h4>기술투자지수(PTSR)</h4>
                  <div class="i_wrap mt10">
                    <span>{{ stkDetail.corp.tii && stkDetail.corp.tii.toFixed(2) }} </span>
                  </div>
                </li>
                <li>
                  <h4>TSS</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.tss && stkDetail.corp.tss.toFixed(2) }}</span>
                  </div>
                </li>
                <li>
                  <h4>TCG</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.tcg }}</span>
                  </div>
                </li>
                <li>
                  <h4>APES</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.apes && stkDetail.corp.apes.toFixed(2) }}</span>
                  </div>
                </li>
                <li>
                  <h4>특허보유</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.patentCount && stkDetail.corp.patentCount }}건</span>
                  </div>
                </li>
                <li>
                  <h4>기업가치추정</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.assdValue }}</span>
                  </div>
                </li>
                <li>
                  <h4>기업개요</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.crpOvrv }}</span>
                  </div>
                </li>
                <li>
                  <h4>본사주소</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.koraddr }}</span>
                  </div>
                </li>
                <li>
                  <h4>홈페이지</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.homepurl }}</span>
                  </div>
                </li>
                <li>
                  <h4>대표전화</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.tel }}</span>
                  </div>
                </li>
                <li>
                  <h4>설립일</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.etbDate && fnFormatDate(stkDetail.corp.etbDate) }}</span>
                  </div>
                </li>
                <!-- <li>
                  <h4>발행주식수</h4>
                  <div class="i_wrap">
                    <span></span>
                  </div>
                </li> -->
                <li>
                  <h4>종업원수</h4>
                  <div class="i_wrap">
                    <span>{{ stkDetail.corp.empnum ? `${stkDetail.corp.empnum}명${stkDetail.corp.empnumBseDate ? ` (${stkDetail.corp.empnumBseDate}기준)` : ''}` : '' }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import SelectSearch from '~/components/common/SelectSearch.vue';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  props: ['selectedIsuSrtCd'],
  components: {
    Input,
    SelectSearch,
    LoadingSpinner,
  },

  computed: {
    sectorList() {
      return this.$store.state.tenbagger.stock.sectorList;
    },
    themeList() {
      return this.$store.state.tenbagger.stock.themeList;
    },
  },

  data() {
    return {
      isuSrtCd: this.selectedIsuSrtCd,
      stkDetail: { corp: {} },
      ofrPrc: null,
      rprSector: null,
      rprTheme: null,
      belongingTheme: [],
      selectedTheme: null,
      loading: false,
    };
  },

  created() {
    this.fetchStk();
  },

  methods: {
    async fetchStk() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('tenbagger/stock/fetchStkDetail', this.isuSrtCd);
      this.loading = false;
      if (result) {
        this.stkDetail = data.contents;
        this.ofrPrc = data.contents.ofrPrc;
        this.rprSector = data.contents.corp.rprSectorNo;
        this.rprTheme = data.contents.corp.rprThemeNo;
        this.belongingTheme = data.contents.corpThemes.filter(el => el.themeNo).map(el => el.themeNo);
      } else {
        this.$popupAlert({
          msg: `종목정보를 가져오는데 실패했습니다(${data.error.msg})`,
        });
        this.fnClosePop();
      }
    },
    async fnClickSave(skipClose) {
      if (this.loading) return;

      if (!/^[0-9]+$/.test(this.ofrPrc)) {
        this.$popupAlert({
          msg: '공모가는 숫자를 입력해주세요',
        });
        return;
      }

      if (!this.rprSector) {
        this.$popupAlert({
          msg: '섹터를 선택해주세요',
        });
        return;
      }

      if (!this.rprTheme) {
        this.$popupAlert({
          msg: '대표테마를 선택해주세요',
        });
        return;
      }

      if (this.belongingTheme.length < 1) {
        this.$popupAlert({
          msg: '소속테마를 선택해주세요',
        });
        return;
      }

      if (this.belongingTheme.indexOf(this.rprTheme) == -1) {
        this.$popupAlert({
          msg: '대표테마가 소속테마에 속해 있지 않습니다',
        });
        return;
      }

      this.loading = true;

      let payload1 = {
        isuSrtCd: this.stkDetail.isuSrtCd,
        ofrPrc: this.ofrPrc,
      };
      const res1 = await this.$store.dispatch('tenbagger/stock/updateStkOfrPrc', payload1);
      if (!res1.result) {
        this.$popupAlert({
          msg: `저장에 실패하였습니다(${res1.data.error.msg})`,
        });
        this.loading = false;
        return;
      }

      let payload2 = {
        bizNo: this.stkDetail.corp.bizno,
        rprSectorNo: this.rprSector,
      };
      const res2 = await this.$store.dispatch('tenbagger/stock/updateCorpRprSector', payload2);
      if (!res2.result) {
        this.$popupAlert({
          msg: `저장에 실패하였습니다(${res2.data.error.msg})`,
        });
        this.loading = false;
        return;
      }

      let payload3 = {
        bizNo: this.stkDetail.corp.bizno,
        rprThemeNo: this.rprTheme,
      };
      const res3 = await this.$store.dispatch('tenbagger/stock/updateCorpRprTheme', payload3);
      if (!res3.result) {
        this.$popupAlert({
          msg: `저장에 실패하였습니다(${res3.data.error.msg})`,
        });
        this.loading = false;
        return;
      }

      let payload4 = {
        bizNo: this.stkDetail.corp.bizno,
        themeNos: this.belongingTheme,
      };
      const res4 = await this.$store.dispatch('tenbagger/stock/addCorpTheme', payload4);
      if (!res4.result) {
        this.$popupAlert({
          msg: `저장에 실패하였습니다(${res4.data.error.msg})`,
        });
        this.loading = false;
        return;
      }

      this.$popupAlert({
        msg: `저장이 완료되었습니다`,
      });
      this.$emit('reloadData');

      if (skipClose) {
        await this.fetchStk();
        this.loading = false;
      } else {
        this.fnClosePop();
      }
    },
    fnClosePop() {
      this.$emit('closePop');
    },
    fnRefreshTheme() {
      if (this.stkDetail.corpThemes) {
        this.belongingTheme = this.stkDetail.corpThemes.filter(el => el.themeNo).map(el => el.themeNo);
      }
    },
    fnAddTheme() {
      if (this.belongingTheme.indexOf(this.selectedTheme) != -1) {
        this.$popupAlert({
          msg: '이미 선택한 테마입니다',
        });
        return;
      }
      this.belongingTheme.push(this.selectedTheme);
    },
    fnRemoveTheme(idx) {
      this.belongingTheme.splice(idx, 1);
    },
    fnGetMktName(code) {
      const list = this.$store.state.tenbagger.stock.mktCd;
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },
    fnGetThemeName(code) {
      const list = this.$store.state.tenbagger.stock.themeList;
      if (list) {
        const found = list.find(item => item.value === code);
        return found ? found.name : '';
      }
      return '';
    },
    fnFormatDate(date) {
      return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>
