<template>
  <div class="join">
    <div class="inner">
      <h2><span class="logo">유니콘랜치</span> 백오피스 회원가입</h2>
      <div class="form" v-if="codeAvailable">
        <div class="item">
          <label for="id">아이디</label>
          <InputBox
            id="id"
            type='text'
            name="email"
            :placeholder="email"
            disabled
            :value.sync="email"
          />
        </div>
        <div class="item">
          <label for="password">비밀번호</label>
          <InputBox
            id="password"
            type='password'
            name="pw1"
            placeholder='비밀번호를 입력해 주세요.'
            :value.sync="password1"
          />
        </div>
        <div class="item">
          <label for="passwordChk">비밀번호 확인</label>
          <InputBox
            id="passwordChk"
            type='password'
            name="pw2"
            placeholder='비밀번호를 입력해 주세요.'
            :value.sync="password2"
          />
        </div>
        <div class="item">
          <label for="name">가입자 이름</label>
          <InputBox
            id="name"
            type='text'
            name="name"
            placeholder='가입하시는 분의 이름을 입력해 주세요.'
            :value.sync="name"
          />
        </div>
        <div class="item">
          <label for="department">소속 부서</label>
          <InputBox
            id="department"
            type='text'
            name="department"
            placeholder='소속 부서를 입력해 주세요.'
            :value.sync="department"
          />
        </div>
        <div class="item">
          <label for="id">직급</label>
          <InputBox
            id="position"
            type='text'
            name="position"
            placeholder='직급을 입력해 주세요.'
            :value.sync="position"
          />
        </div>
        <div class="btns">
          <button class="btn full" @click="fnRegist">회원가입</button>
        </div>
      </div>
      <div class="form" v-if="!codeAvailable">
        <h3>{{ codeErrMsg }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import CreatePop from '~/components/popup/Create';

export default {
  layout: 'none',
  components: {
    InputBox,
    CreatePop,
  },
  data() {
    return {
      codeAvailable: false,
      codeErrMsg: '',
      email: '',
      password1: '',
      password2: '',
      name: '',
      department: '',
      position: '',
    };
  },
  methods: {
    async fnRegist() {
      if (!this.$validate('email', this.email)) {
        this.$popupAlert({
          title: '경고 : 아이디',
          msg: '이메일형식의 아이디를 입력해 주세요.',
        });
        return;
      } else if (!this.$validate('password', this.password1)) {
        this.$popupAlert({
          title: '경고 : 비밀번호',
          msg: '숫자와 문자를 조합하여 6자리 이상 입력해 주세요.',
        });
        return;
      } else if (this.password1 != this.password2) {
        this.$popupAlert({
          title: '경고 : 비밀번호 확인',
          msg: '비밀번호가 일치하지 않습니다.',
        });
        return;
      } else if (this.name === '') {
        this.$popupAlert({
          title: '경고 : 이름 확인',
          msg: '가입하시는 분의 이름을 입력해주세요.',
        });
      } else {
        const params = {
          user_id: this.email,
          user_pw: this.password1,
          name: this.name,
          department: this.department,
          position: this.position,
        };
        const result = await this.$store.dispatch('backoffice/signUpStore/signUp', params);
        if (result) {
          this.$popupAlert({
            title: '성공 : 회원가입',
            msg: '회원가입이 완료되었습니다. <br/> 관리자의 승인 이후 로그인이 가능합니다.',
            btn: {
              name: '확인',
              link: '/login',
            },
          });
        } else {
          this.$popupAlert({
            title: '경고 : 회원가입',
            msg: '회원가입에 실패했습니다.',
            btn: {
              name: '확인',
              link: '/joinStep1',
            },
          });
        }
      }
    },
  },
  async mounted() {
    const code = this.$route.query.code;
    if (code !== null) {
      const result = await this.$store.dispatch('backoffice/signUpStore/emailValidation', { code });
      console.log(result.data)
      if (result.result) {
        this.codeAvailable = true;
        this.email = result.data.contents;
      } else {
        this.codeAvailable = false;
        this.codeErrMsg = result.data.error.msg;
      }
    }
  },
};
</script>
