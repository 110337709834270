<template>
  <div class="right">
    <div class="view_date" @click="() => (this.tooltipOnOff = true)">
      <span class="start">{{ fromDate && fnFormatDate(fromDate) }}</span>
      <span class="end">{{ toDate && fnFormatDate(toDate) }}</span>
      <DatePicTooltip2 ref="datepic" :loading="loading" :onOff="tooltipOnOff" @date="fnDateChanged" @cancel="() => (this.tooltipOnOff = false)" />
    </div>
  </div>
</template>

<script>
import DatePicTooltip2 from './DatePicTooltip2';
export default {
  props: ['loading'],
  components: {
    DatePicTooltip2,
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      tooltipOnOff: false,
    };
  },
  methods: {
    fnFormatDate(date) {
      return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3');
    },
    fnDateChanged({ fromDate, toDate }) {
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.fnChanged();
    },
    fnChanged() {
      this.$emit('change', { fromDate: this.fromDate, toDate: this.toDate });
    },

    fnSetDate(from, to) {
      this.$refs.datepic.fnSetDate(from, to);
    },
  },
};
</script>
