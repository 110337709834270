<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="item_ipt" v-for="(item, i) in status" :key="i">
          <label for="engineer"><h4><b>{{item.statusLabel}}</b></h4></label>
          <div class="i_wrap"><span style="margin-top: 10px;">{{item.processTitle}}</span></div>
          <div class="i_wrap" v-if="item.selectOptions">
            <Select title="select" id="technology" :itemList="fnSelectList(item.selectOptions)" :value.sync="nextCd"/>
          </div>
        </li>
      </ul>
    </div>
    <div class="right">
      <ul class="form_list type2">
        <div class="timeline ml15" style="height : 35vh;">
          <div class="unit" v-for="(item, i) in history" :key="i">
            <div class="u_top colb">
              <div class="col">
                <strong>{{item.statusNm}}</strong>
              </div>
              <div class="col">
                <strong>{{item.regNm}}</strong>
                <span>|</span>
                <span>{{cutDate(item.regDtm)}}</span>
              </div>
            </div>
            <div class="u_cont">
              {{item.memo}}
            </div>
            <div class="u_bottom">
            </div>
          </div>
          <!-- <div class="unit" v-for="v in 20" :key="v">
            <div class="u_top colb">
              <div class="col">
                <strong>asdsasad</strong>
              </div>
              <div class="col">
                <strong>asdsadsa</strong>
                <span>|</span>
                <span>asdsadsad</span>
              </div>
            </div>
            <div class="u_cont">
              asdasdasdas
            </div>
            <div class="u_bottom">
            </div>
          </div> -->
        </div>
        <li class="item_ipt">
          <div class="i_wrap">
            <textarea
              style="height : 15vh;"
              name="msg"
              id="content"
              class="textarea"
              v-model="memo"
              placeholder="특허 추천 및 내용 설명 등…"
            ></textarea>
          </div>
        </li>
      </ul>
      <button class="btn" style="float: right;" @click="fnSave">저장</button>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import Select from '~/components/common/Select';

import customersService from "~/service/allOk/customersService";
export default {
  name: 'CustomerTab6',
  props: ['id'],
  data() {
    return {
      memo:null,
      nextCd:null,
      status: [],
      selectI:null,
      history: []
    };
  },
  components: {
    InputBox,
    Select,
  },
  computed: {
  },
  mounted() {
    this.fnFetchStatus();
  },
  methods: {
    cutDate(payload){
      if(payload){
        return payload.slice(0,10)
      }
      return ''
    },
    async fnFetchStatus(){
      const result = await customersService.getStatus(this.$axios, this.id)
      if(result.result){
        this.status = result.data.contents
      }
      const result2 = await customersService.getHistory(this.$axios, this.id)
      if(result2.result){
        this.history = result2.data.contents
      }
    },
    fnSave(){
      const btn = {
        name : '확인',
        func : ()=> {
          this.fnClickSave();
          this._closePop();
        }
      }
      this._confirm('확인', '저장하시겠습니까?', btn);
    },
    async fnClickSave(){
      const item = this.status.find(e=>e.processType==='ING')
      const currentState = item ? item.statusCd : null
      const params = {
        memo: this.memo,
        currentStateCd: currentState,
        nextCd: this.nextCd
      }
      const {result, data} = await customersService.updateStatus(this.$axios, this.id, params)
      if(result){
        this.fnFetchStatus();
        this.memo = null;
        this.nextCd = null;
      }
    },
    fnSelectList(payload){
      return payload.map(p => {
        return {
          value: p.cd,
          name: p.nm
        }
      })
    },
    _alert(title, msg){
      let data = {
        state:true,
        type: "alert",
        title: title,
        msg: msg,
        btn:{
          name:"확인",
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn){
      let data  = {
        state:true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
  }
};
</script>
