<template>
  <section class="container">
    <Location />
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <ul class="form_list type2">
          <li class="item_ipt">
            <h4>이슈 섹터</h4>
            <div class="i_wrap mt5">
              <SelectSearch
                name="issueSector"
                :itemList="sectorList"
                placeholder="섹터명을 입력해주세요."
                :value="issueSector"
                @change="
                  v => {
                    fnClickValue('issueSector', v);
                  }
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>이슈 테마</h4>
            <div class="i_wrap mt5">
              <SelectSearch
                name="issueTheme"
                :itemList="themeList"
                placeholder="테마명을 입력해 주세요."
                :value="issueTheme"
                @change="
                  v => {
                    fnClickValue('issueTheme', v);
                  }
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>섹터랭킹<br />기본 선택 섹터</h4>
            <div class="i_wrap mt10">
              <SelectSearch
                name="defaultSector"
                :itemList="sectorList"
                placeholder="섹터명을 입력해 주세요."
                :value="defaultSector"
                @change="
                  v => {
                    fnClickValue('defaultSector', v);
                  }
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>테마랭킹<br />기본 선택 테마</h4>
            <div class="i_wrap mt10">
              <SelectSearch
                name="defaultTheme"
                :itemList="themeList"
                placeholder="테마명을 입력해 주세요."
                :value="defaultTheme"
                @change="
                  v => {
                    fnClickValue('defaultTheme', v);
                  }
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>테마랭킹</h4>
            <div class="i_wrap">
              <div class="cole">
                <SelectSearch class="mr5" :itemList="themeList" :value.sync="selectedTheme" name="selectedTheme" @change="fnAddTheme" />
                <div class="half">
                  <button class="btn b_refresh" @click="setDefault('dispThemeList')">새로고침</button>
                  <button class="btn" @click="fnClickValue('dispThemeList', null)">설정</button>
                </div>
              </div>
              <div class="ml5 mt5">선택된 {{ dispThemeList.length }} 테마 목록</div>
              <ul style="width: 100%">
                <li v-for="(el, idx) in dispThemeList" :key="idx">
                  <div class="i_wrap colb">
                    <span class="f14 mr5">{{ el.name }}</span>
                    <button class="btn dark" @click="fnRemoveTheme(idx)">-</button>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="right"></div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import SelectSearch from '~/components/common/SelectSearch';

export default {
  components: {
    Location,
    SelectSearch,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_HMSECMTS'],
      issueSector: null,
      issueTheme: null,
      defaultSector: null,
      defaultTheme: null,
      dispThemeList: [],
      selectedTheme: null,
    };
  },

  computed: {
    sectorList() {
      return this.$store.state.tenbagger.hmsecmts.sectorList;
    },
    themeList() {
      return this.$store.state.tenbagger.hmsecmts.themeList;
    },
  },
  methods: {
    fnClickValue(issueType, v) {
      const btn = {
        name: '설정',
        func: () => {
          this.updateIssue(issueType, v);
          this._closePop();
        },
      };

      if (issueType == 'issueSector') {
        this._confirm('이슈섹터 설정', `이슈섹터를 ${v.name}(으)로 설정하시겠습니까?`, btn);
      } else if (issueType == 'issueTheme') {
        this._confirm('이슈테마 설정', `이슈테마를 ${v.name}(으)로 설정하시겠습니까?`, btn);
      } else if (issueType == 'defaultSector') {
        this._confirm('섹터랭킹 기본 선택 섹터 설정', `섹터랭킹 기본 선택 섹터를 ${v.name}(으)로 설정하시겠습니까?`, btn);
      } else if (issueType == 'defaultTheme') {
        this._confirm('테마랭킹 기본 선택 테마 설정', `테마랭킹 기본 선택 테마를 ${v.name}(으)로 설정하시겠습니까?`, btn);
      } else if (issueType == 'dispThemeList') {
        this._confirm('테마랭킹 설정', `테마랭킹을 설정하시겠습니까?`, btn);
      }
    },

    setDefault(issueType) {
      if (issueType == 'issueSector') {
        this.issueSector = this.$store.state.tenbagger.hmsecmts.mtsIssue.sectorNo;
      } else if (issueType == 'issueTheme') {
        this.issueTheme = this.$store.state.tenbagger.hmsecmts.mtsIssue.themeNo;
      } else if (issueType == 'defaultSector') {
        this.defaultSector = this.$store.state.tenbagger.hmsecmts.defaultSector;
      } else if (issueType == 'defaultTheme') {
        this.defaultTheme = this.$store.state.tenbagger.hmsecmts.defaultTheme;
      } else if (issueType == 'dispThemeList') {
        this.dispThemeList = JSON.parse(JSON.stringify(this.$store.state.tenbagger.hmsecmts.dispThemeList));
      }
    },

    async updateIssue(issueType, v) {
      if (issueType === 'issueSector') {
        const { result, data } = await this.$store.dispatch('tenbagger/hmsecmts/updateMtsIssueSector', { securityType: 'HMSEC', sectorNo: v.value });
        if (result) {
          this.$popupAlert({
            title: '알림',
            msg: '설정이 완료되었습니다',
          });
          this.issueSector = v.value;
          this.$store.dispatch('tenbagger/hmsecmts/fetchMtsIssue');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (issueType === 'issueTheme') {
        const { result, data } = await this.$store.dispatch('tenbagger/hmsecmts/updateMtsIssueTheme', { securityType: 'HMSEC', themeNo: v.value });
        if (result) {
          this.$popupAlert({
            title: '알림',
            msg: '설정이 완료되었습니다',
          });
          this.issueTheme = v.value;
          this.$store.dispatch('tenbagger/hmsecmts/fetchMtsIssue');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (issueType === 'defaultSector') {
        const { result, data } = await this.$store.dispatch('tenbagger/hmsecmts/updateMtsDefaultSector', v.value);
        if (result) {
          this.$popupAlert({
            title: '알림',
            msg: '설정이 완료되었습니다',
          });
          this.defaultSector = v.value;
          this.$store.dispatch('tenbagger/hmsecmts/fetchSectorList');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (issueType === 'defaultTheme') {
        const { result, data } = await this.$store.dispatch('tenbagger/hmsecmts/updateMtsDefaultTheme', v.value);
        if (result) {
          this.$popupAlert({
            title: '알림',
            msg: '설정이 완료되었습니다',
          });
          this.defaultTheme = v.value;
          this.$store.dispatch('tenbagger/hmsecmts/fetchThemeList');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (issueType === 'dispThemeList') {
        let payload = { themeNos: this.dispThemeList.map(el => el.value) };
        const { result, data } = await this.$store.dispatch('tenbagger/hmsecmts/updateDispTheme', payload);
        if (result) {
          this.$popupAlert({
            title: '알림',
            msg: '설정이 완료되었습니다',
          });
          this.$store.dispatch('tenbagger/hmsecmts/fetchThemeList');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      }
    },

    fnAddTheme(v) {
      if (this.dispThemeList.findIndex(el => el.value == v.value) != -1) {
        this.$popupAlert({
          msg: '이미 선택한 테마입니다',
        });
        return;
      }
      this.dispThemeList.unshift(v);
    },

    fnRemoveTheme(idx) {
      this.dispThemeList.splice(idx, 1);
    },

    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },

    _closePop() {
      this.$store.dispatch('ui/setPopAlertData', {
        state: false,
        type: null,
        title: null,
        msg: null,
        btn: {
          link: '',
          name: null,
        },
      });
    },
  },

  async mounted() {
    let result1 = await this.$store.dispatch('tenbagger/hmsecmts/fetchSectorList');
    if (!result1.result) {
      this.$popupAlert({
        msg: `섹터정보를 가져오는데 실패했습니다(${result1.data.error.msg})`,
      });
      return;
    }
    let result2 = await this.$store.dispatch('tenbagger/hmsecmts/fetchThemeList');
    if (!result2.result) {
      this.$popupAlert({
        msg: `테마정보를 가져오는데 실패했습니다(${result2.data.error.msg})`,
      });
      return;
    }
    let result3 = await this.$store.dispatch('tenbagger/hmsecmts/fetchMtsIssue');
    if (!result3.result) {
      this.$popupAlert({
        msg: `MTS이슈정보를 가져오는데 실패했습니다(${result3.data.error.msg})`,
      });
      return;
    }

    this.setDefault('issueSector');
    this.setDefault('issueTheme');
    this.setDefault('defaultSector');
    this.setDefault('defaultTheme');
    this.setDefault('dispThemeList');
  },
};
</script>
