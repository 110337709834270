<template>
  <div class="search_date" :class="{ on: onOff }">
    <div class="s_top">
      <span class="start">{{ from.year }}-{{ from.month && $fill0(from.month) }}</span>
      <span class="end">{{ to.year }}-{{ to.month && $fill0(to.month) }}</span>
    </div>
    <div class="s_cont">
      <div class="item ml15">
        <h4 class="tit f10">시작년월</h4>
        <div class="bx">
          <Select
            defaultName="년"
            :itemList="yearList"
            classNm="ipt_year"
            :value.sync="from.year"
            @change="
              () => {
                this.from.month = null;
                this.from.date = null;
              }
            " />
          <Select
            defaultName="월"
            :itemList="monthList"
            classNm="ipt_month"
            :value.sync="from.month"
            @change="
              () => {
                this.from.date = null;
              }
            " />
        </div>
      </div>
      <div class="item ml15">
        <h4 class="tit f10">종료년월</h4>
        <div class="bx">
          <Select
            defaultName="년"
            :itemList="yearList"
            classNm="ipt_year"
            :value.sync="to.year"
            @change="
              () => {
                this.to.month = null;
                this.to.date = null;
              }
            " />
          <Select
            defaultName="월"
            :itemList="monthList"
            classNm="ipt_month"
            :value.sync="to.month"
            @change="
              () => {
                this.to.date = null;
              }
            " />
        </div>
      </div>
    </div>
    <div class="colb mr5 mb5">
      <div class="col"></div>
      <div class="col">
        <button
          class="btn"
          @click="
            e => {
              e.stopPropagation();
              this.fnPropagationDate();
              this.$emit('cancel');
            }
          ">
          확인
        </button>
        <button
          class="btn l_gray"
          @click="
            e => {
              e.stopPropagation();
              this.fnRestore();
              this.$emit('cancel');
            }
          ">
          취소
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '~/components/common/Select';

export default {
  props: ['onOff', 'loading'],
  components: {
    Select,
  },

  data() {
    return {
      minYear: 2015,
      maxYear: 2030,
      minMonth: 1,
      maxMonth: 12,
      from: {
        year: null,
        month: null,
      },
      to: {
        year: null,
        month: null,
      },
      backup: {
        from: {
          year: null,
          month: null,
        },
        to: {
          year: null,
          month: null,
        },
      },
    };
  },
  computed: {
    yearList() {
      const min = this.minYear;
      const max = this.maxYear;
      return this.fnSelectList(min, max, '년');
    },
    monthList() {
      const min = this.minMonth;
      const max = this.maxMonth;
      return this.fnSelectList(min, max, '월');
    },
  },

  methods: {
    fnPropagationDate() {
      if (this.loading) {
        this.fnRestore();
        return;
      }
      if (!this.from.year || !this.from.month || !this.to.year || !this.to.month) {
        this.fnRestore();
        return;
      }

      this.$emit('date', {
        fromDate: `${this.from.year}${this.$fill0(this.from.month)}`,
        toDate: `${this.to.year}${this.$fill0(this.to.month)}`,
      });
      this.fnBackup();
    },
    fnRestore() {
      this.from = { ...this.backup.from };
      this.to = { ...this.backup.to };
    },
    fnBackup() {
      this.backup.from = { ...this.from };
      this.backup.to = { ...this.to };
    },
    fnSelectList(min, max, unit) {
      let list = [];
      if (min && max) {
        for (let a = min; a <= max; a++) {
          list.push({
            name: a + ' ' + unit,
            value: a,
          });
        }
      }
      return list;
    },
  },
};
</script>
