<template>
  <div class="item_patent">
    <!-- <div class="id">{{data.id}}</div> -->
    <!-- <div class="title" v-html="data.title"></div> -->
    <input type="text" class="ipt id" v-model="item.id" placeholder="특허번호" disabled />
    <input type="text" class="ipt tit" v-model="item.tit" placeholder="특허 이름을 입력해주세요" disabled />
    <button
      v-show="!item.disabled"
      class="btn_close"
      @click="
        () => {
          this.$emit('delete');
        }
      "
    >
      삭제
    </button>
  </div>
</template>
<script>
import InputBox from '~/components/common/Input';
export default {
  props: ['data'],
  data() {
    return {
      item: this.data || {},
    };
  },
  components: {
    InputBox,
  },
};
</script>
