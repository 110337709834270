<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>고객상세</h2>
        <button class="btn_close" @click="fnClosePop">닫기</button>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button class="tab on">고객상세</button>
              <button class="tab">대출 상담 내역</button>
            </div>
            <div class="btns">
              <button to="" class="btn">저장 후 계속</button>
              <button to="" class="btn">저장</button>
              <button to="" class="btn dark">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <!-- 타입1 -->
            <!-- <div class="left">
              <ul class="form_list">
                <li class="half">
                  <label for="name"><b>회사명</b></label>
                  <InputBox
                    :data="{ type: 'text', id: 'name' }"
                  />
                </li>
                <li class="half">
                  <label for="name"><b>대표자명</b></label>
                  <InputBox
                    :data="{ type: 'text', id: 'name'}"
                  />
                </li>
                <li class="half">
                  <label for="name"><b>이메일</b></label>
                  <InputBox
                    :data="{ type: 'text', id: 'name'}"
                  />
                </li>
                <li class="half">
                  <label for="name"><b>연락처</b></label>
                  <InputBox
                    :data="{ type: 'text', id: 'name'}"
                  />
                </li>
                <li class="half">
                  <h4>설립일</h4>
                  <div class="view_date">
                    <span class="start">2022년 6월 16일</span>
                    <div class="search_date"> on
                      <div class="s_top">
                        <span class="start">2022년 12월 16일</span>
                      </div>
                      <div class="s_cont">
                        <div class="item">
                          <div class="bx">
                            <Select
                                title="2022년"
                                :list="[
                                  { name: '셀렉트1' },
                                  { name: '셀렉트2' },
                                  { name: '셀렉트3' },
                                  { name: '셀렉트4' },
                                  { name: '셀렉트5' },
                                  { name: '셀렉트6' },
                                  { name: '셀렉트7' },
                                  { name: '셀렉트8' },
                                ]"
                                classNm="ipt_year"
                              />
                            <Select
                                title="12월"
                                :list="[
                                  { name: '셀렉트1' },
                                  { name: '셀렉트2' },
                                  { name: '셀렉트3' },
                                  { name: '셀렉트4' },
                                  { name: '셀렉트5' },
                                  { name: '셀렉트6' },
                                  { name: '셀렉트7' },
                                  { name: '셀렉트8' },
                                ]"
                                classNm="ipt_month"
                              />
                            <Select
                                title="31일"
                                :list="[
                                  { name: '셀렉트1' },
                                  { name: '셀렉트2' },
                                  { name: '셀렉트3' },
                                  { name: '셀렉트4' },
                                  { name: '셀렉트5' },
                                  { name: '셀렉트6' },
                                  { name: '셀렉트7' },
                                  { name: '셀렉트8' },
                                ]"
                                classNm="ipt_day"
                              />
                          </div>
                        </div>
                        <div class="btns">
                          <button class="btn">확인</button>
                          <button class="btn gray">취소</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="half">
                  <h4>업종</h4>
                  <Select title="특허문의" :list="[
                    {name: '셀렉트1'},
                    {name: '셀렉트2'},
                    {name: '셀렉트3'},
                    {name: '셀렉트4'},
                  ]"/>
                </li>
                <li>
                  <h4>소재지</h4>
                  <InputBox
                    :data="{ type: 'text', id: 'name'}"
                  />
                </li>
                <li class="item_radio top_line">
                  <h4>인증보유현황</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '연구소',
                        id: 1,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '연구전단부서',
                        id: 1,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '벤처인증',
                        id: 1,
                        name: 'radio',
                        disabled: true,
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '이노비즈',
                        id: 1,
                        name: 'radio',
                        disabled: true,
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '미보유',
                        id: 1,
                        name: 'radio',
                        disabled: true,
                      }"
                    />
                  </div>
                </li>
                <li class="item_skill">
                  <h4>기술보유현황</h4>
                  <div class="i_wrap">
                    <div class="bx_line">
                      <h5>보유특허수</h5>
                      <strong class="txt_org">8개</strong>
                    </div>
                    <div class="bx_line">
                      <h5>보유특허수</h5>
                      <strong class="txt_red">8개</strong>
                    </div>
                    <div class="bx_line">
                      <h5>보유특허수</h5>
                      <strong class="txt_red">8개</strong>
                    </div>
                  </div>
                </li>
                <li class="item_radio">
                  <h4>대표자 연령</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '만 39세 미만',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '만 39세 이상',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>대표자 연령</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '만 39세 미만',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '만 39세 이상',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>직원수</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '5인미만',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '5인 이상 ~ 10인 미만',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '10인 이상',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '잘 모르겠어요',
                        id: 2,
                        name: 'radio',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>고용계획</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '네, 있어요',
                        id: 9,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '잘 모르겠어요',
                        id: 9,
                        name: 'radio',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>특허추천</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '네, 추천받고 싶어요',
                        id: 10,
                        name: 'radio',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '잘 모르겠어요',
                        id: 10,
                        name: 'radio',
                      }"
                    />
                  </div>
                </li>
              </ul>
            </div> -->
            <!-- 타입2 -->
            <div class="left">
              <ul class="form_list type2">
                <li class="half">
                  <h4>회사명</h4>
                  <div class="i_wrap"><span>바이오니아</span></div>
                </li>
                <li class="half">
                  <h4>대표자명</h4>
                  <div class="i_wrap"><span>ㅇㅇㅇ</span></div>
                </li>
                <li class="half">
                  <h4>이메일</h4>
                  <div class="i_wrap"><span>test@test.com</span></div>
                </li>
                <li class="half">
                  <h4>연락처</h4>
                  <div class="i_wrap"><span>000-0000-0000</span></div>
                </li>
                <li class="half">
                  <h4>설립일</h4>
                  <div class="i_wrap"><span>2000.00.30</span></div>
                </li>
                <li class="half">
                  <h4>업종</h4>
                  <div class="i_wrap"><span>2000.00.30</span></div>
                </li>
                <li>
                  <h4>소재지</h4>
                  <div class="i_wrap"><span>서울시 서울구 서울로 1000</span></div>
                </li>
                <li class="item_radio">
                  <h4>인증보유현황</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '연구소',
                        id: 1,
                        name: 'radio1',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '연구전단부서',
                        id: 2,
                        name: 'radio1',
                      }"/>
                    <RadioBox
                      :data="{
                        title: '벤처인증',
                        id: 3,
                        name: 'radio1',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '이노비즈',
                        id: 4,
                        name: 'radio1',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '미보유',
                        id: 5,
                        name: 'radio1',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>대표자 연령</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '만 39세 미만',
                        id: 1,
                        name: 'radio3',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '만 39세 이상',
                        id: 2,
                        name: 'radio3',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>직원수</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '5인미만',
                        id: 1,
                        name: 'radio4',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '5인 이상 ~ 10인 미만',
                        id: 2,
                        name: 'radio4',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '10인 이상',
                        id: 3,
                        name: 'radio4',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '잘 모르겠어요',
                        id: 4,
                        name: 'radio4',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>고용계획</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '네, 있어요',
                        id: 1,
                        name: 'radio5',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '잘 모르겠어요',
                        id: 2,
                        name: 'radio5',
                      }"
                    />
                  </div>
                </li>
                <li class="item_radio">
                  <h4>특허추천</h4>
                  <div class="i_wrap">
                    <RadioBox
                      :data="{
                        title: '네, 추천받고 싶어요',
                        id: 1,
                        name: 'radio6',
                      }"
                    />
                    <RadioBox
                      :data="{
                        title: '잘 모르겠어요',
                        id: 2,
                        name: 'radio6',
                      }"
                    />
                  </div>
                </li>
                <li class="item_skill">
                  <h4>기술보유현황</h4>
                  <div class="i_wrap">
                    <div class="bx_line">
                      <h5>보유특허수</h5>
                      <strong class="txt_org">8개</strong>
                    </div>
                    <div class="bx_line">
                      <h5>보유특허수</h5>
                      <strong class="txt_red">8개</strong>
                    </div>
                    <div class="bx_line">
                      <h5>보유특허수</h5>
                      <strong class="txt_red">8개</strong>
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>소재지</h4>
                  <div class="i_wrap">
                    <InputBox
                      type="text"
                      name="name"
                    />
                  </div>
                </li>
                <li class="item_add_file">
                  <h4>서류제출</h4>
                  <div class="i_wrap">
                    <div class="wrap_file">
                      <div class="w_top">
                        <h5>정책자금 검토 필수서류 (필수)</h5>
                        <button class="btn">업로드</button>
                      </div>
                      <div class="bx_fileview">
                        <dl>
                          <dt><span>사업자등록증 사본</span></dt>
                          <dd>
                            <div class="item_file" v-for="i in 3" :key="i">
                              <span>유니콘이다.jpg</span>
                              <button class="btn_delete">삭제</button>
                            </div>
                          </dd>
                        </dl>
                      </div>
                      <div class="w_top">
                        <h5>TCB 가평가 필요서류 (필수)</h5>
                        <button class="btn">업로드</button>
                      </div>
                      <div class="bx_fileview">
                        <dl>
                          <dt><span>정보활용동의서</span></dt>
                          <dd>
                            <div class="item_file" v-for="i in 5" :key="i">
                              <span>유니콘이다.jpg</span>
                              <button class="btn_delete">삭제</button>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt><span>기술신용조사서(예비평가용)</span></dt>
                          <dd>
                            <div class="item_file" v-for="i in 5" :key="i">
                              <span>유니콘이다.jpg</span>
                              <button class="btn_delete">삭제</button>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt><span>기술신용조사서(예비평가용)</span></dt>
                          <dd>
                            <div class="item_file" v-for="i in 5" :key="i">
                              <span>유니콘이다.jpg</span>
                              <button class="btn_delete">삭제</button>
                            </div>
                          </dd>
                        </dl>
                      </div>
                      <div class="w_top">
                        <h5>정책자금 검토 부가서류 (선택)</h5>
                        <button class="btn">업로드</button>
                      </div>
                      <div class="bx_fileview">
                        <dl>
                          <dt><span>연구소, 벤처, 이노비즈 등 인증서 사본</span></dt>
                          <dd>
                            <div class="item_file" v-for="i in 3" :key="i">
                              <span>유니콘이다.jpg</span>
                              <button class="btn_delete">삭제</button>
                            </div>
                          </dd>
                        </dl>
                      </div>
                      <div class="colr">
                        <button class="btn b_down">전체다운로드</button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>업종</h4>
                  <div class="i_wrap">
                    <div class="cole">
                      <div class="half">
                        <div class="bx_txt">서울시 서울 서울로 서울집값</div>
                      </div>
                      <InputBox
                        classNm="half type2"
                        shape="search"
                        type='text'
                        placeholder="검색어를 입력하세요."
                      />
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>업종</h4>
                  <div class="i_wrap">
                    <div class="cole">
                      <div class="half">
                        <div class="bx_txt">서울시 서울 서울로 서울집값</div>
                      </div>
                      <div class="half bx_txt_search">
                        <div class="bx_txt">서울시 서울 서울로 서울집값</div>
                        <button class="btn_search type2">검색</button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>업종</h4>
                  <div class="i_wrap">
                    <div class="cole">
                      <Select classNm="half" title="특허문의" :itemList="[
                        {name: '셀렉트1', value:1},
                        {name: '셀렉트2', value:2},
                        {name: '셀렉트3', value:3},
                        {name: '셀렉트4', value:4},
                      ]"/>
                      <InputBox
                        classNm="half"
                        type="text"
                        name="name"
                      />
                    </div>
                    <div class="cole">
                      <div class="temp"></div>
                      <InputBox
                        classNm="half"
                        type="text"
                        name="name"
                      />
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>업종</h4>
                  <div class="i_wrap">
                    <div class="cole">
                      <Select classNm="half" title="특허문의" :itemList="[
                        {name: '셀렉트1'},
                        {name: '셀렉트2'},
                        {name: '셀렉트3'},
                        {name: '셀렉트4'},
                      ]"/>
                      <InputBox
                        classNm="half type2"
                        shape="search"
                        type="text"
                        placeholder="검색어를 입력하세요"
                      />
                    </div>
                    <div class="cole">
                      <div class="temp"></div>
                      <InputBox
                        classNm="half type2"
                        shape="search"
                        type="text"
                        placeholder="검색어를 입력하세요"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right bg_gray">
              <div class="list_goods" v-for="i in 10" :key="i">
                <div class="unit">
                  <dl>
                    <dt>상품명</dt>
                    <dd><b class="f15">시니어기술 창업자금</b></dd>
                  </dl>
                  <dl>
                    <dt>금융방식</dt>
                    <dd><b class="txt_label">TCB기술금융</b><b class="txt_label grean">TCB기술금융</b><b class="txt_label org">TCB기술금융</b></dd>
                  </dl>
                  <dl class="half">
                    <dt>지원한도</dt>
                    <dd><b>최고 7천만원</b></dd>
                  </dl>
                  <dl class="half">
                    <dt>대출기간</dt>
                    <dd>기관별 상이</dd>
                  </dl>
                  <dl class="half">
                    <dt>보증비율</dt>
                    <dd>95%</dd>
                  </dl>
                  <dl class="half">
                    <dt>보증료율</dt>
                    <dd><b>연 0.8%</b></dd>
                  </dl>
                  <dl>
                    <dt>금융기관</dt>
                    <dd>신용보증재단</dd>
                  </dl>
                  <dl>
                    <dt>비고</dt>
                    <dd>대표자가 대기업·중견기업·정부출연연구소 경력 보유자, 기술사, 이공계 석·박사 학위 보유자</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { values } from "methods";
import InputBox from "~/components/common/Input";
import ItemPatent from "~/components/common/ItemPatent";
import Select from "~/components/common/Select";
import SelectSearch from "~/components/common/SelectSearch";
import RadioBox from "~/components/common/RadioBox";


export default {
  data() {
    return {
    };
  },
  components: {
    InputBox,
    ItemPatent,
    Select,
    SelectSearch,
    RadioBox,
  },
  mounted() {
  },
  methods: {
    fnClosePop(){
        this.$store.dispatch('ui/setPopCs2State', false);
    },
  },
};
</script>
