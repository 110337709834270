<template>
  <section class="container">
    <Location />
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <ul class="form_list type2">
          <li class="item_ipt">
            <h4>기술섹터</h4>
            <div class="i_wrap mt5">
              <SelectSearch
                :itemList="sectorList"
                placeholder="섹터명을 입력해 주세요."
                :value.sync="newsConf.TECH_SECTOR"
                @change="
                  $popupConfirm({
                    id: 'TECH_SECTOR',
                    title: '기술 섹터 설정',
                    msg: '기술 섹터를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>기술주<br />(특허현황)</h4>
            <div class="i_wrap mt5">
              <StockSearch
                :stk.sync="newsConf.TECH_STOCK1"
                @updateStk="
                  $popupConfirm({
                    id: 'TECH_STOCK1',
                    title: '기술주(특허현황) 설정',
                    msg: '기술주(특허현황)를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                "
              ></StockSearch>
            </div>
          </li>
          <li class="item_ipt">
            <h4>기술주<br />(주요기술부문)</h4>
            <div class="i_wrap mt5">
              <StockSearch
                :stk.sync="newsConf.TECH_STOCK2"
                @updateStk="
                  $popupConfirm({
                    id: 'TECH_STOCK2',
                    title: '기술주(주요기술부문) 설정',
                    msg: '기술주(주요기술부문)를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                "
              ></StockSearch>
            </div>
          </li>
          <li class="item_ipt">
            <h4>기술 테마<br />(기술주)</h4>
            <div class="i_wrap mt10">
              <SelectSearch
                :itemList="themeList"
                placeholder="검색어를 입력해 주세요."
                :value.sync="newsConf.TECH_THEME1"
                @change="
                  $popupConfirm({
                    id: 'TECH_THEME1',
                    title: '기술 테마(기술주) 설정',
                    msg: '기술 테마(기술주)를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>기술 테마<br />(특허 현황)</h4>
            <div class="i_wrap mt10">
              <SelectSearch
                :itemList="themeList"
                placeholder="검색어를 입력해 주세요."
                :value.sync="newsConf.TECH_THEME2"
                @change="
                  $popupConfirm({
                    id: 'TECH_THEME2',
                    title: '기술 테마(특허 현황) 설정',
                    msg: '기술 테마(특허 현황)를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                "
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>기술 테마<br />(주요기술부문)</h4>
            <div class="i_wrap mt10">
              <SelectSearch
                :itemList="themeList"
                placeholder="검색어를 입력해 주세요."
                :value.sync="newsConf.TECH_THEME3"
                @change="
                  $popupConfirm({
                    id: 'TECH_THEME3',
                    title: '기술 테마(주요 기술부문)',
                    msg: '기술 테마(주요 기술부문) 를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                "
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="right"></div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <Confirm
        v-if="$store.state.ui.popConfirmData.state == true"
        :key="$store.state.ui.popConfirmData.state"
        @confirm="
          id => {
            fnUpdateConf(id, true);
          }
        "
        @cancel="
          id => {
            fnUpdateConf(id, false);
          }
        "
      />
    </transition>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import SelectSearch from '~/components/common/SelectSearch';
import StockSearch from '~/components/pages/tenbagger/StockSearch';
import Confirm from '~/components/popup/Confirm';

export default {
  components: {
    Location,
    SelectSearch,
    StockSearch,
    Confirm,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_NEWS'],
      newsConf: {
        TECH_SECTOR: null,
        TECH_THEME1: null,
        TECH_THEME2: null,
        TECH_THEME3: null,
        TECH_STOCK1: {},
        TECH_STOCK2: {},
      },
    };
  },
  computed: {
    sectorList() {
      return this.$store.state.tenbagger.news.sectorList;
    },
    themeList() {
      return this.$store.state.tenbagger.news.themeList;
    },
  },

  methods: {
    setDefault(confKey) {
      let newsConf = this.$store.state.tenbagger.news.newsConf;
      if (confKey === 'TECH_STOCK1' || confKey === 'TECH_STOCK2') {
        let confValue = newsConf.find(el => el.confKey === confKey).confValue;
        let confValueDetail = newsConf.find(el => el.confKey === confKey).confValueDetail;
        this.newsConf[confKey] = { isuSrtCd: confValue, isuKorNm: confValueDetail };
      } else {
        let confValue = newsConf.find(el => el.confKey === confKey).confValue;
        this.newsConf[confKey] = parseInt(confValue);
      }
    },

    async fnUpdateConf(confKey, isConfirm) {
      if (isConfirm) {
        let confValue = '';
        if (confKey == 'TECH_STOCK1' || confKey == 'TECH_STOCK2') {
          confValue = this.newsConf[confKey].isuSrtCd;
        } else {
          confValue = this.newsConf[confKey];
        }

        const { result, data } = await this.$store.dispatch('tenbagger/news/updateNewsConf', {
          confKey: confKey,
          confValue: confValue,
        });

        if (result) {
          this.$popupAlert({
            title: '알림',
            msg: '설정이 완료되었습니다',
          });
          this.$store.dispatch('tenbagger/news/fetchNewsConf');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
          this.setDefault(confKey);
        }
      } else {
        this.setDefault(confKey);
      }
    },
  },

  async mounted() {
    let result1 = await this.$store.dispatch('tenbagger/news/fetchSectorList');
    if (!result1.result) {
      this.$popupAlert({
        msg: `섹터정보를 가져오는데 실패했습니다(${result1.data.error.msg})`,
      });
      return;
    }

    let result2 = await this.$store.dispatch('tenbagger/news/fetchThemeList');
    if (!result2.result) {
      this.$popupAlert({
        msg: `테마정보를 가져오는데 실패했습니다(${result2.data.error.msg})`,
      });
      return;
    }

    let result3 = await this.$store.dispatch('tenbagger/news/fetchNewsConf');
    if (result3.result) {
      this.setDefault('TECH_SECTOR');
      this.setDefault('TECH_THEME1');
      this.setDefault('TECH_THEME2');
      this.setDefault('TECH_THEME3');
      this.setDefault('TECH_STOCK1');
      this.setDefault('TECH_STOCK2');
    } else {
      this.$popupAlert({
        msg: `뉴스설정정보를 가져오는데 실패했습니다(${result3.data.error.msg})`,
      });
      return;
    }
  },
};
</script>
