<template>
  <Cs />
</template>

<script>
import Cs from '../../components/popup/Cs2'

export default {
  name: 'cs',
  components: {
    Cs
  }
}
</script>
