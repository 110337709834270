<template>
  <section class="container page_mobile">
    <div class="top">
      <div class="area">
          <InputBox
          :data="{
            shape: 'search',
            type: 'text',
            placeholder: '기업명으로 검색',
          }"
        />
        <div class="btns_filter">
          <button class="btn">1개월</button>
          <button class="btn on">3개월</button>
          <button class="btn">직접설정</button>
        </div>
      </div>
      <div class="area_date">
        <div class="a_inner">
          <div class="wrap_datepicker">
            <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
              <input type="text" id="tui-date-picker-target" aria-label="Date-Time" />
              <span class="tui-ico-date"></span>
            </div>
            <div id="tui-date-picker-container" style="margin-top: -1px;"></div>
          </div>
          <div class="wrap_datepicker">
            <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
              <input type="text" id="tui-date-picker-target" aria-label="Date-Time" />
              <span class="tui-ico-date"></span>
            </div>
            <div id="tui-date-picker-container" style="margin-top: -1px;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="inner"> <!-- .no_padding 테이블 들어간 페이지에 추가해주세요 -->
      <div class="contents">
        <div class="list_cs">
          <div class="unit" v-for="i in 10" :key="i">
            <dl>
              <dt>기업명</dt>
              <dd><b>유니콘랜치</b></dd>
            </dl>
            <dl>
              <dt>대표자명</dt>
              <dd>전호</dd>
            </dl>
            <dl class="full">
              <dt>소재지</dt>
              <dd>수원시 권선구 000로 00 0000호</dd>
            </dl>
            <dl>
              <dt>신청일</dt>
              <dd>2022.09.01</dd>
            </dl>
            <dl>
              <dt>진행상태</dt>
              <dd>신청중</dd>
            </dl>
          </div>
          <button class="btn full mt15">더보기</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "~/components/layouts/Header";
import Location from "~/components/layouts/Location";
import InputBox from "~/components/common/Input";

export default {
  name: "Mobile",
  // layout: "none", //헤더랑 메뉴 없는페이지를 원하시면 layout 주석 풀어주세요
  data() {
    return {}
  },
  components: {
    Header,
    Location,
    InputBox
  },
  methods: {
  }
};
</script>