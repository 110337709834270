<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="searchWrd">테마명</label>
          <Input id="searchWrd" :value.sync="form.searchWrd" name="form.searchWrd" type="text" shape="search" placeholder="테마명을 입력하세요." @enter="fnClickSearch" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnOpenPop('popStateCreate')">새로운 테마</button>
          <button class="btn" @click="fnClickOpen('popStateDetail')">열기</button>
          <button class="btn" @click="fnClickDelete">삭제</button>
          <button class="btn" @click="fnClickOpen('popStateStock1')">대표종목</button>
          <button class="btn" @click="fnClickOpen('popStateStock2')">소속종목</button>
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <ThemeCreatePop
              v-if="popStateCreate == true"
              :key="popStateCreate"
              @reloadData="fnReloadData"
              @closePop="
                () => {
                  this.fnChangePopState('popStateCreate', false);
                }
              " />
            <ThemeDetailPop
              v-if="popStateDetail == true"
              :key="popStateDetail"
              :selectedTheme="selectedTheme"
              @reloadData="fnReloadData"
              @closePop="
                () => {
                  this.fnChangePopState('popStateDetail', false);
                }
              " />
            <ThemeStock1Pop
              v-if="popStateStock1 == true"
              :key="popStateStock1"
              :selectedTheme="selectedTheme"
              @closePop="
                () => {
                  this.fnChangePopState('popStateStock1', false);
                }
              " />
            <ThemeStock2Pop
              v-if="popStateStock2 == true"
              :key="popStateStock2"
              :selectedTheme="selectedTheme"
              @closePop="
                () => {
                  this.fnChangePopState('popStateStock2', false);
                }
              " />
            <Confirm v-if="$store.state.ui.popConfirmData.state == true" :key="$store.state.ui.popConfirmData.state" @confirm="fnDeleteTheme" />
          </transition>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select id="pageSize" title="20개" :itemList="pageSizeList" :value.sync="form.pageSize" @change="fnClickSearch" />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="10%" />
              <col width="15%" />
              <col width="35%" />
              <col width="30%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <b>테마번호</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'THEME_NO' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'THEME_NO', sortOrdr: 'ASC' })">
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'THEME_NO' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'THEME_NO', sortOrdr: 'DESC' })">
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>테마이름</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'THEME_NAME' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'THEME_NAME', sortOrdr: 'ASC' })">
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'THEME_NAME' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'THEME_NAME', sortOrdr: 'DESC' })">
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th><b>보고서 개요</b></th>
                <th><b>보고서 이슈/트렌드</b></th>
                <th>
                  <b>보고 일자</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'RPT_DT' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'RPT_DT', sortOrdr: 'ASC' })">
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'RPT_DT' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'RPT_DT', sortOrdr: 'DESC' })">
                      내림차순정렬
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.themeNo" :class="{ on: item.themeNo === selectedTheme }" @click="fnClickTheme(item.themeNo)" @dblclick="fnClickOpen('popStateDetail')">
                <td>{{ item.themeNo }}</td>
                <td>{{ item.themeName }}</td>
                <td>{{ fnTextLengthOverCut(item.rptOutline) }}</td>
                <td>{{ fnTextLengthOverCut(item.rptIssue) }}</td>
                <td>{{ item.rptDt }}</td>
              </tr>
              <tr v-if="contents.length === 0">
                <td colspan="7">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              this.form.pageNo = p;
              this.fnFetchTheme();
            }
          " />
      </div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import ThemeCreatePop from '~/components/pages/tenbagger/theme/ThemeCreate';
import ThemeDetailPop from '~/components/pages/tenbagger/theme/ThemeDetail';
import ThemeStock1Pop from '~/components/pages/tenbagger/theme/ThemeStock1';
import ThemeStock2Pop from '~/components/pages/tenbagger/theme/ThemeStock2';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import Confirm from '~/components/popup/Confirm';

export default {
  components: {
    Location,
    ThemeCreatePop,
    ThemeDetailPop,
    ThemeStock1Pop,
    ThemeStock2Pop,
    Pagination,
    Input,
    Select,
    Confirm,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_THEME'],
      form: {
        pageNo: 1,
        pageSize: 20, // 한 페이지에 보여줄 rows의 갯수
        sortColumn: null,
        sortOrdr: null,
        searchWrd: null,
      },
      contents: [],
      pagination: {
        totalCount: 0,
      },
      selectedTheme: 0,
      popStateCreate: false,
      popStateDetail: false,
      popStateStock1: false,
      popStateStock2: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '30개', value: 30 },
      ],
    };
  },

  methods: {
    fnClickTheme(themeNo) {
      this.selectedTheme = themeNo;
    },

    fnClickSort({ sortColumn, sortOrdr }) {
      if (this.form.sortColumn !== sortColumn) {
        this.form.sortColumn = sortColumn;
        this.form.sortOrdr = sortOrdr;
      } else if (this.form.sortOrdr !== sortOrdr) {
        this.form.sortOrdr = sortOrdr;
      } else {
        this.form.sortColumn = null;
        this.form.sortOrdr = null;
      }
      this.fnFetchTheme();
    },

    fnClickSearch() {
      this.form.pageNo = 1;
      this.fnFetchTheme();
    },

    async fnFetchTheme() {
      const { result, data } = await this.$store.dispatch('tenbagger/theme/fetchTheme', this.form);
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `테마리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnClickOpen(key) {
      if (!this.selectedTheme) {
        this.$popupAlert({
          msg: '테마를 선택해주세요',
        });
      } else {
        this.fnOpenPop(key);
      }
    },

    fnClickDelete() {
      if (!this.selectedTheme) {
        this.$popupAlert({
          msg: '테마를 선택해주세요',
        });
      } else {
        this.$popupConfirm({
          title: '테마 삭제',
          msg: '테마를 삭제하시겠습니까?',
          btn: '삭제',
        });
      }
    },

    async fnDeleteTheme() {
      const { result, data } = await this.$store.dispatch('tenbagger/theme/deleteTheme', this.selectedTheme);
      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '테마정보가 삭제되었습니다',
        });
        this.selectedTheme = 0;
        this.fnReloadData();
      } else {
        this.$popupAlert({
          msg: `테마정보를 삭제하는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnOpenPop(key) {
      // this.$store.dispatch('ui/setMenuState', false);
      this.fnChangePopState(key, true);
    },

    fnReloadData() {
      this.fnFetchTheme();
    },

    fnChangePopState(key, pop) {
      this[key] = pop;
    },

    fnTextLengthOverCut(txt) {
      if (txt != null && txt.length > 50) {
        txt = txt.substr(0, 50) + '...';
      }
      return txt;
    },
  },

  mounted() {
    this.fnFetchTheme();
  },
};
</script>
