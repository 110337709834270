<template>
  <section class="container">
    <Location />
    <div class="tabs1">
      <button v-for="el in tabList" :key="el.id" class="tab" :class="{ on: id == el.id }" v-html="el.name" @click="fnClickTab(el.id)"></button>
    </div>
    <RbpMoney v-if="id == 1" />
    <RbpRate v-if="id == 2" />
    <RbpRateMonth v-if="id == 3" />
    <RbpRateMonthKospi v-if="id == 4" />
    <RbpRateTrem v-if="id == 5" />
    <RbpRateTremKospi v-if="id == 6" />
    <RbpStock v-if="id == 7" />
    <RbpTradingBuy v-if="id == 8" />
    <RbpTradingSell v-if="id == 9" />
    <RbpTradingTotal v-if="id == 10" />
    <RoboRecomSignal v-if="id == 11" />
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper.vue';
import RbpMoney from '~/components/pages/tenbagger/thinkpool/RbpMoney.vue';
import RbpRate from '~/components/pages/tenbagger/thinkpool/RbpRate.vue';
import RbpRateMonth from '~/components/pages/tenbagger/thinkpool/RbpRateMonth.vue';
import RbpRateMonthKospi from '~/components/pages/tenbagger/thinkpool/RbpRateMonthKospi.vue';
import RbpRateTrem from '~/components/pages/tenbagger/thinkpool/RbpRateTrem.vue';
import RbpRateTremKospi from '~/components/pages/tenbagger/thinkpool/RbpRateTremKospi.vue';
import RbpStock from '~/components/pages/tenbagger/thinkpool/RbpStock.vue';
import RbpTradingBuy from '~/components/pages/tenbagger/thinkpool/RbpTradingBuy.vue';
import RbpTradingSell from '~/components/pages/tenbagger/thinkpool/RbpTradingSell.vue';
import RbpTradingTotal from '~/components/pages/tenbagger/thinkpool/RbpTradingTotal.vue';
import RoboRecomSignal from '~/components/pages/tenbagger/thinkpool/RoboRecomSignal.vue';

export default {
  components: {
    Location,
    RbpMoney,
    RbpRate,
    RbpRateMonth,
    RbpRateMonthKospi,
    RbpRateTrem,
    RbpRateTremKospi,
    RbpStock,
    RbpTradingBuy,
    RbpTradingSell,
    RbpTradingTotal,
    RoboRecomSignal,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_THINKPOOL'],
      tabList: [
        { id: 1, name: 'RBP<br />MONEY' },
        { id: 2, name: 'RBP<br />RATE' },
        { id: 3, name: 'RBP<br />RATE MONTH' },
        { id: 4, name: 'RBP<br />RATE MONTH KOSPI' },
        { id: 5, name: 'RBP<br />RATE TREM' },
        { id: 6, name: 'RBP<br />RATE TREM KOSPI' },
        { id: 7, name: 'RBP<br />STOCK' },
        { id: 8, name: 'RBP<br />TRADING BUY' },
        { id: 9, name: 'RBP<br />TRADING SELL' },
        { id: 10, name: 'RBP<br />TRADING TOTAL' },
        { id: 11, name: 'ROBO<br />RECOM SIGNAL' },
      ],
      id: 1,
    };
  },

  methods: {
    fnClickTab(id) {
      this.id = id;
    },
  },
};
</script>
