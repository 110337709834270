<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="item_ipt">
          <h4>신청일</h4>
          <div class="i_wrap mt10">
            <span>{{ orderDetail.reg_dtm }}</span>
          </div>
        </li>
        <li>
          <h4>기업명</h4>
          <div class="i_wrap">
            <span>{{ orderDetail.co_name }}</span>
          </div>
        </li>
        <li>
          <h4>담당자</h4>
          <div class="i_wrap">
            <span>{{ orderDetail.applicant }}</span>
          </div>
        </li>
        <li>
          <h4>거래방식</h4>
          <div class="i_wrap">
            <span>{{ fnGetCodeName('common', 'TM00', orderDetail.trd_mthd) }}</span>
          </div>
        </li>
        <li>
          <h4>거래조건</h4>
          <div class="i_wrap">
            <span>{{ fnGetCodeName('common', 'TT00', orderDetail.trd_trms) }}</span>
          </div>
        </li>
        <li>
          <h4>지급조건</h4>
          <div class="i_wrap">
            <span>{{ fnGetCodeName('common', 'PTR00', orderDetail.pymn_trms) }}</span>
          </div>
        </li>
        <li class="item_ipt">
          <h4>진행상태</h4>
          <div class="i_wrap">
            <Select :itemList="stateCd" :value.sync="selectedStateCd" name="selectedStateCd" />
          </div>
        </li>
        <li></li>
      </ul>
      <div class="cs_top">
        <div class="col">
          <span>2020-01-01</span>
          <span>(주)위메프</span>
        </div>
        <div class="btns">
          <button class="btn b_add"><b>새로운 상담 내용</b></button>
        </div>
      </div>
      <div class="timeline">
        <div class="unit on">
          <div class="u_top colb">
            <div class="col">
              <strong>아무나</strong>
              <span>유니콘랜치 삼성전자 담당자</span>
              <button class="btn_phone">전화</button>
              <button class="btn_mail">메일</button>
              <em class="new">new</em>
            </div>
            <div class="col">
              <span>2022.01.23</span>
            </div>
          </div>
          <div class="u_cont">대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.</div>
          <div class="u_bottom"></div>
        </div>
        <div class="unit">
          <div class="u_top colb">
            <div class="col">
              <strong>아무나</strong>
              <span>유니콘랜치 삼성전자 담당자</span>
              <button class="btn_phone">전화</button>
              <button class="btn_mail">메일</button>
              <em class="new">new</em>
            </div>
            <div class="col">
              <span>2022.01.23</span>
            </div>
          </div>
          <div class="u_cont">대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.</div>
          <div class="u_bottom"></div>
        </div>
      </div>
    </div>
    <div class="right">
      <h3>새로운 상담 내용</h3>
      <!-- <h3 >상담 내용</h3> -->
      <ul class="form_list type2">
        <li class="item_ipt">
          <h4>상담일시</h4>
          <div class="i_wrap">
            <DateRangeTabsSingle
              ref="dateRangeTabs"
              @change="
                ({ fromDate }) => {
                  this.form.meetDate = fromDate;
                }
              "
            />
          </div>
        </li>
        <li class="item_ipt">
          <h4>미팅 고객명</h4>
          <div class="i_wrap">
            <Input :value.sync="form.meetName" name="form.meetName" type="text" placeholder="이름을 입력하세요" />
          </div>
        </li>
        <li class="item_ipt">
          <h4>미팅 고객 <br />전화</h4>
          <div class="i_wrap mt5">
            <Input :value.sync="form.meetPhone" name="form.meetPhone" type="text" placeholder="전화번호를 입력하세요" />
          </div>
        </li>
        <li class="item_ipt">
          <h4>미팅 고객 <br />이메일</h4>
          <div class="i_wrap mt5">
            <Input :value.sync="form.meetEmail" name="form.meetEmail" type="text" placeholder="이메일을 입력하세요" />
          </div>
        </li>

        <li class="item_ipt">
          <h4>상담 유형</h4>
          <div class="i_wrap">
            <Select
              :itemList="[
                { name: '미팅', value: 1 },
                { name: '전화', value: 2 },
                { name: '이메일', value: 3 },
                { name: '기타', value: 4 },
              ]"
              defaultName="상담유형을 선택해주세요"
              :value.sync="form.meetType"
              name="form.meetType"
            />
          </div>
        </li>
        <li>
          <h4>상담내용</h4>
          <div class="i_wrap">
            <textarea class="textarea" placeholder="상담내용을 입력해주세요" style="height: 200px" v-model="form.meetDesc"></textarea>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import Select from '~/components/common/Select';
import DateRangeTabsSingle from '../../allOk/DateRangeTabsSingle.vue';

export default {
  props: ['orderDetail'],

  components: {
    Input,
    Select,
    DateRangeTabsSingle,
  },

  data() {
    return {
      selectedStateCd: null,
      form: {
        meetDate: null,
        meetName: null,
        meetPhone: null,
        meetEmail: null,
        meetType: null,
        meetDesc: null,
      },
    };
  },
  computed: {
    stateCd() {
      return this.$store.state.openIp.order.RS000 ? this.$store.state.openIp.order.RS000.map(el => ({ name: el.codeName, value: el.code })) : [];
    },
  },

  methods: {
    async fnSave() {
      const payload = {
        orderId: this.orderDetail.id,
        stateCd: this.selectedStateCd,
      };
      const { result, data } = await this.$store.dispatch('openIp/orderStore/updateOrderStateCd', payload);
      if (result) {
        this.$popupAlert({
          msg: '변경 저장완료',
        });
        return true;
      } else {
        this.$popupAlert({
          msg: `변경 실패(${data.error.msg})`,
        });
        return false;
      }
    },

    fnGetCodeName(codeGrp1, codeGrp2, code) {
      const list = this.$store.state.openIp[codeGrp1][codeGrp2];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },
  },

  watch: {
    orderDetail(value) {
      this.selectedStateCd = value.state_cd;
    },
  },
};
</script>
