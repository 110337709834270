<template>
  <section class="container">
    <Location :list="[{
      name:'OPENIP',
      link:'',
    },{
      name:'거래방식',
      link:'',
    }]"/>
    <div class="inner"> <!-- .no_padding 테이블 들어간 페이지에 추가해주세요 -->
      <div class="colr">
        <button class="btn_reset">전체초기화</button>
      </div>
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="search1">사업자번호</label>
          <InputBox
            :data="{
              type: 'text',
              placeholder: '검색어를 입력하세요.',
              id:'search1'
            }"
          />
        </div>
        <div class="unit">
          <label class="tit" for="search1">고객명</label>
          <InputBox
            :data="{
              type: 'text',
              placeholder: '검색어를 입력하세요.',
              id:'search1'
            }"
          />
        </div>
        <div class="unit">
          <label class="tit" for="search2">담당자</label>
          <InputBox
            :data="{
              type: 'text',
              placeholder: '검색어를 입력하세요.',
              id:'search2'
            }"
          />
        </div>
        <br/>
        <div class="unit">
          <h3 class="tit">관리단계</h3>
          <Select
              title="상담 대기 중"
              :list="[
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
                { name: '셀렉트5' },
                { name: '셀렉트6' },
                { name: '셀렉트7' },
                { name: '셀렉트8' },
              ]"
            />
        </div>
        <div class="unit">
          <h3 class="tit">진행단계</h3>
          <Select
              title="실권자미팅시도중"
              :list="[
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
                { name: '셀렉트5' },
                { name: '셀렉트6' },
                { name: '셀렉트7' },
                { name: '셀렉트8' },
              ]"
            />
        </div>
        <div class="unit unit_date">
          <h3 class="tit">기간설정</h3>
          <div class="right">
            <div class="view_date">
              <span class="start">2022년 6월 16일</span>
              <span class="end">2022년 6월 16일</span>
              <div class="search_date on">
                <div class="s_top">
                  <span class="start">2022년 12월 16일</span>
                  <span class="end">2022년 12월 33일</span>
                </div>
                <div class="s_cont">
                  <div class="item">
                    <h4 class="tit">
                      시작일
                    </h4>
                    <div class="bx">
                      <Select
                          title="2022년"
                          :list="[
                            { name: '셀렉트1' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_year"
                        />
                      <Select
                          title="12월"
                          :list="[
                            { name: '셀렉트1' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_month"
                        />
                      <Select
                          title="31일"
                          :list="[
                            { name: '셀렉트1' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_day"
                        />
                    </div>
                  </div>
                  <div class="item">
                    <h4 class="tit">
                      종료일
                    </h4>
                    <div class="bx">
                      <Select
                          title="2022년"
                          :list="[
                            { name: '2002년' },
                          ]"
                          classNm="ipt_year"
                        />
                      <Select
                          title="12월"
                          :list="[
                            { name: '12월' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_month"
                        />
                      <Select
                          title="31일"
                          :list="[
                            { name: '31일' },
                          ]"
                          classNm="ipt_day"
                        />
                    </div>
                  </div>
                  <div class="btns">
                    <button class="btn">확인</button>
                    <button class="btn gray">취소</button>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn l_gray">1개월</button>
            <button class="btn l_gray">3개월</button>
          </div>
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn">상품 등록</button>
          <button class="btn">열기</button>
          <button class="btn dark">삭제</button>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 : </h4>
          <Select
              title="5개"
              :list="[
                { name: '5개' },
                { name: '10개' },
                { name: '15개' },
              ]"
            />
          <button class="btn">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <!--
              직접 셀크기를 조정하고 싶을때 사용하면 됩니다.
              이경우 c1~c7 클래스 삭제해주세요
              -->
            <!-- <colgroup>
              <col width="200"/>
              <col width="130"/>
              <col width="110"/>
              <col width="150"/>
              <col width="150"/>
              <col width="140"/>
              <col width="140"/>
            </colgroup> -->
            <thead>
              <tr>
                <th class="c1"><b>사업자번호</b></th>
                <th class="c2"><b>고객명</b></th>
                <th class="c3"><b>담당자</b></th>
                <th class="c4"><b>관리단계</b></th>
                <th class="c5">
                  <b>진행상태</b>
                  <div class="bx_srot">
                    <button class="btn_up">오름차순정렬</button>
                    <button class="btn_down">내림차순정렬</button>
                  </div>
                </th>
                <th class="c6">
                  <b>신청일</b>
                  <div class="bx_srot">
                    <button class="btn_up">오름차순정렬</button>
                    <button class="btn_down">내림차순정렬</button>
                  </div>
                </th>
                <th class="c7">
                  <b>마지막처리일</b>
                  <div class="bx_srot">
                    <button class="btn_up">오름차순정렬</button>
                    <button class="btn_down">내림차순정렬</button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="on">
                <td class="c1">519801234</td>
                <td class="c2">히즈케어스(주)</td>
                <td class="c3">나애리</td>
                <td class="c4">상담중</td>
                <td class="c5">실권자미팅시도중</td>
                <td class="c6">2022-09-01</td>
                <td class="c7">2022-09-02</td>
              </tr>
              <tr v-for="i in 9" :key="i">
                <td class="c1">519801234</td>
                <td class="c2">히즈케어스(주)</td>
                <td class="c3">나애리</td>
                <td class="c4">상담중</td>
                <td class="c5">실권자미팅시도중</td>
                <td class="c6">2022-09-01</td>
                <td class="c7">2022-09-02</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <button class="btn_start">맨 앞으로</button>
          <button class="btn_prev">이전</button>
          <a href="#" class="btn_page">1</a>
          <button class="btn_page">2</button>
          <button class="btn_page">3</button>
          <button class="btn_page">4</button>
          <button class="btn_page">5</button>
          <button class="btn_page on">6</button>
          <button class="btn_page">7</button>
          <button class="btn_page">8</button>
          <button class="btn_page">9</button>
          <button class="btn_page">10</button>

          <button class="btn_next">다음</button>
          <button class="btn_end">맨 뒤로</button>
        </div>
      </div>
    </div>
    <div class="inner bg_white">
      <ul class="form_list">
        <li class="half">
          <label for="name"><b>회사명</b></label>
          <InputBox
            :data="{ type: 'text', id: 'name' }"
          />
        </li>
        <li class="half">
          <label for="name"><b>대표자명</b></label>
          <InputBox
            :data="{ type: 'text', id: 'name'}"
          />
        </li>
        <li class="half">
          <label for="name"><b>이메일</b></label>
          <InputBox
            :data="{ type: 'text', id: 'name'}"
          />
        </li>
        <li class="half">
          <label for="name"><b>연락처</b></label>
          <InputBox
            :data="{ type: 'text', id: 'name'}"
          />
        </li>
        <li class="half">
          <label for="name"><b>설립일</b></label>
          <div class="view_date">
            <span class="start">2022년 6월 16일</span>
            <div class="search_date"><!-- on -->
              <div class="s_top">
                <span class="start">2022년 12월 16일</span>
              </div>
              <div class="s_cont">
                <div class="item">
                  <div class="bx">
                    <Select
                        title="2022년"
                        :list="[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                        classNm="ipt_year"
                      />
                    <Select
                        title="12월"
                        :list="[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                        classNm="ipt_month"
                      />
                    <Select
                        title="31일"
                        :list="[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                        classNm="ipt_day"
                      />
                  </div>
                </div>
                <div class="btns">
                  <button class="btn">확인</button>
                  <button class="btn gray">취소</button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="half">
          <h4>업종</h4>
          <Select title="특허문의" :list="[
            {name: '셀렉트1'},
            {name: '셀렉트2'},
            {name: '셀렉트3'},
            {name: '셀렉트4'},
          ]"/>
        </li>
        <li>
          <h4>소재지</h4>
          <InputBox
            :data="{ type: 'text', id: 'name'}"
          />
        </li>
        <li class="item_radio top_line">
          <h4>인증보유현황</h4>
          <div class="i_wrap">
            <RadioBox
              :data="{
                title: '연구소',
                id: 1,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '연구전단부서',
                id: 1,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '벤처인증',
                id: 1,
                name: 'radio',
                disabled: true,
              }"
            />
            <RadioBox
              :data="{
                title: '이노비즈',
                id: 1,
                name: 'radio',
                disabled: true,
              }"
            />
            <RadioBox
              :data="{
                title: '미보유',
                id: 1,
                name: 'radio',
                disabled: true,
              }"
            />
          </div>
        </li>
        <li class="item_skill">
          <h4>기술보유현황</h4>
          <div class="i_wrap">
            <div class="bx_line">
              <h5>보유특허수</h5>
              <strong class="txt_org">8개</strong>
            </div>
            <div class="bx_line">
              <h5>보유특허수</h5>
              <strong class="txt_red">8개</strong>
            </div>
            <div class="bx_line">
              <h5>보유특허수</h5>
              <strong class="txt_red">8개</strong>
            </div>
          </div>
        </li>
        <li class="item_radio">
          <h4>대표자 연령</h4>
          <div class="i_wrap">
            <RadioBox
              :data="{
                title: '만 39세 미만',
                id: 2,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '만 39세 이상',
                id: 2,
                name: 'radio',
              }"
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>대표자 연령</h4>
          <div class="i_wrap">
            <RadioBox
              :data="{
                title: '만 39세 미만',
                id: 2,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '만 39세 이상',
                id: 2,
                name: 'radio',
              }"
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>직원수</h4>
          <div class="i_wrap">
            <RadioBox
              :data="{
                title: '5인미만',
                id: 2,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '5인 이상 ~ 10인 미만',
                id: 2,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '10인 이상',
                id: 2,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '잘 모르겠어요',
                id: 2,
                name: 'radio',
              }"
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>고용계획</h4>
          <div class="i_wrap">
            <RadioBox
              :data="{
                title: '네, 있어요',
                id: 9,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '잘 모르겠어요',
                id: 9,
                name: 'radio',
              }"
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>특허추천</h4>
          <div class="i_wrap">
            <RadioBox
              :data="{
                title: '네, 추천받고 싶어요',
                id: 10,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '잘 모르겠어요',
                id: 10,
                name: 'radio',
              }"
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <ul class="form_list">
          <li class="half">
            <label for="name"><b>회사명</b></label>
            <InputBox
              :data="{ type: 'text', id: 'name' }"
            />
          </li>
          <li class="half">
            <label for="name"><b>대표자명</b></label>
            <InputBox
              :data="{ type: 'text', id: 'name'}"
            />
          </li>
          <li class="half">
            <label for="name"><b>이메일</b></label>
            <InputBox
              :data="{ type: 'text', id: 'name'}"
            />
          </li>
          <li class="half">
            <label for="name"><b>연락처</b></label>
            <InputBox
              :data="{ type: 'text', id: 'name'}"
            />
          </li>
          <li class="half">
            <label for="name"><b>설립일</b></label>
            <div class="view_date">
              <span class="start">2022년 6월 16일</span>
              <div class="search_date"><!-- on -->
                <div class="s_top">
                  <span class="start">2022년 12월 16일</span>
                </div>
                <div class="s_cont">
                  <div class="item">
                    <div class="bx">
                      <Select
                          title="2022년"
                          :list="[
                            { name: '셀렉트1' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_year"
                        />
                      <Select
                          title="12월"
                          :list="[
                            { name: '셀렉트1' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_month"
                        />
                      <Select
                          title="31일"
                          :list="[
                            { name: '셀렉트1' },
                            { name: '셀렉트2' },
                            { name: '셀렉트3' },
                            { name: '셀렉트4' },
                            { name: '셀렉트5' },
                            { name: '셀렉트6' },
                            { name: '셀렉트7' },
                            { name: '셀렉트8' },
                          ]"
                          classNm="ipt_day"
                        />
                    </div>
                  </div>
                  <div class="btns">
                    <button class="btn">확인</button>
                    <button class="btn gray">취소</button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="half">
            <h4>업종</h4>
            <Select title="특허문의" :list="[
              {name: '셀렉트1'},
              {name: '셀렉트2'},
              {name: '셀렉트3'},
              {name: '셀렉트4'},
            ]"/>
          </li>
          <li>
            <h4>소재지</h4>
            <InputBox
              :data="{ type: 'text', id: 'name'}"
            />
          </li>
          <li class="item_radio top_line">
            <h4>인증보유현황</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '연구소',
                  id: 1,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '연구전단부서',
                  id: 1,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '벤처인증',
                  id: 1,
                  name: 'radio',
                  disabled: true,
                }"
              />
              <RadioBox
                :data="{
                  title: '이노비즈',
                  id: 1,
                  name: 'radio',
                  disabled: true,
                }"
              />
              <RadioBox
                :data="{
                  title: '미보유',
                  id: 1,
                  name: 'radio',
                  disabled: true,
                }"
              />
            </div>
          </li>
          <li class="item_skill">
            <h4>기술보유현황</h4>
            <div class="i_wrap">
              <div class="bx_line">
                <h5>보유특허수</h5>
                <strong class="txt_org">8개</strong>
              </div>
              <div class="bx_line">
                <h5>보유특허수</h5>
                <strong class="txt_red">8개</strong>
              </div>
              <div class="bx_line">
                <h5>보유특허수</h5>
                <strong class="txt_red">8개</strong>
              </div>
            </div>
          </li>
          <li class="item_radio">
            <h4>대표자 연령</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '만 39세 미만',
                  id: 2,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '만 39세 이상',
                  id: 2,
                  name: 'radio',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>대표자 연령</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '만 39세 미만',
                  id: 2,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '만 39세 이상',
                  id: 2,
                  name: 'radio',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>직원수</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '5인미만',
                  id: 2,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '5인 이상 ~ 10인 미만',
                  id: 2,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '10인 이상',
                  id: 2,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '잘 모르겠어요',
                  id: 2,
                  name: 'radio',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>고용계획</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '네, 있어요',
                  id: 9,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '잘 모르겠어요',
                  id: 9,
                  name: 'radio',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>특허추천</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '네, 추천받고 싶어요',
                  id: 10,
                  name: 'radio',
                }"
              />
              <RadioBox
                :data="{
                  title: '잘 모르겠어요',
                  id: 10,
                  name: 'radio',
                }"
              />
            </div>
          </li>
        </ul>
        <ul class="form_list type2">
          <li class="half">
            <h4>회사명</h4>
            <div class="i_wrap"><span>바이오니아</span></div>
          </li>
          <li class="half">
            <h4>대표자명</h4>
            <div class="i_wrap"><span>ㅇㅇㅇ</span></div>
          </li>
          <li class="half">
            <h4>이메일</h4>
            <div class="i_wrap"><span>test@test.com</span></div>
          </li>
          <li class="half">
            <h4>연락처</h4>
            <div class="i_wrap"><span>000-0000-0000</span></div>
          </li>
          <li class="half">
            <h4>설립일</h4>
            <div class="i_wrap"><span>2000.00.30</span></div>
          </li>
          <li class="half">
            <h4>업종</h4>
            <div class="i_wrap"><span>2000.00.30</span></div>
          </li>
          <li>
            <h4>소재지</h4>
            <div class="i_wrap"><span>서울시 서울구 서울로 1000</span></div>
          </li>
          <li class="item_radio">
            <h4>인증보유현황</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '연구소',
                  id: 1,
                  name: 'radio1',
                }"
              />
              <RadioBox
                :data="{
                  title: '연구전단부서',
                  id: 2,
                  name: 'radio1',
                }"/>
              <RadioBox
                :data="{
                  title: '벤처인증',
                  id: 3,
                  name: 'radio1',
                }"
              />
              <RadioBox
                :data="{
                  title: '이노비즈',
                  id: 4,
                  name: 'radio1',
                }"
              />
              <RadioBox
                :data="{
                  title: '미보유',
                  id: 5,
                  name: 'radio1',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>대표자 연령</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '만 39세 미만',
                  id: 1,
                  name: 'radio3',
                }"
              />
              <RadioBox
                :data="{
                  title: '만 39세 이상',
                  id: 2,
                  name: 'radio3',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>직원수</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '5인미만',
                  id: 1,
                  name: 'radio4',
                }"
              />
              <RadioBox
                :data="{
                  title: '5인 이상 ~ 10인 미만',
                  id: 2,
                  name: 'radio4',
                }"
              />
              <RadioBox
                :data="{
                  title: '10인 이상',
                  id: 3,
                  name: 'radio4',
                }"
              />
              <RadioBox
                :data="{
                  title: '잘 모르겠어요',
                  id: 4,
                  name: 'radio4',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>고용계획</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '네, 있어요',
                  id: 1,
                  name: 'radio5',
                }"
              />
              <RadioBox
                :data="{
                  title: '잘 모르겠어요',
                  id: 2,
                  name: 'radio5',
                }"
              />
            </div>
          </li>
          <li class="item_radio">
            <h4>특허추천</h4>
            <div class="i_wrap">
              <RadioBox
                :data="{
                  title: '네, 추천받고 싶어요',
                  id: 1,
                  name: 'radio6',
                }"
              />
              <RadioBox
                :data="{
                  title: '잘 모르겠어요',
                  id: 2,
                  name: 'radio6',
                }"
              />
            </div>
          </li>
          <li class="item_skill">
            <h4>기술보유현황</h4>
            <div class="i_wrap">
              <div class="bx_line">
                <h5>보유특허수</h5>
                <strong class="txt_org">8개</strong>
              </div>
              <div class="bx_line">
                <h5>보유특허수</h5>
                <strong class="txt_red">8개</strong>
              </div>
              <div class="bx_line">
                <h5>보유특허수</h5>
                <strong class="txt_red">8개</strong>
              </div>
            </div>
          </li>
          <li class="item_ipt">
            <h4>소재지</h4>
            <div class="i_wrap">
              <InputBox
                :data="{ type: 'text', id: 'name'}"
              />
            </div>
          </li>
          <li class="item_ipt">
            <h4>업종</h4>
            <div class="i_wrap">
              <div class="cole">
                <div class="half">
                  <div class="bx_txt">서울시 서울 서울로 서울집값</div>
                </div>
                <InputBox classNm="half type2"
                  :data="{
                    shape: 'search',
                    type: 'text',
                    placeholder: '검색어를 입력하세요.',
                  }"
                />
              </div>
            </div>
          </li>
          <li class="item_ipt">
            <h4>업종</h4>
            <div class="i_wrap">
              <div class="cole">
                <div class="half">
                  <div class="bx_txt">서울시 서울 서울로 서울집값</div>
                </div>
                <div class="half bx_txt_search">
                  <div class="bx_txt">서울시 서울 서울로 서울집값</div>
                  <button class="btn_search type2">검색</button>
                </div>
              </div>
            </div>
          </li>
          <li class="item_ipt">
            <h4>업종</h4>
            <div class="i_wrap">
              <div class="cole">
                <Select classNm="half" title="특허문의" :list="[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]"/>
                <InputBox classNm="half"
                  :data="{ type: 'text', id: 'name'}"
                />
              </div>
              <div class="cole">
                <div class="temp"></div>
                <InputBox classNm="half"
                  :data="{ type: 'text', id: 'name'}"
                />
              </div>
            </div>
          </li>
          <li class="item_ipt">
            <h4>업종</h4>
            <div class="i_wrap">
              <div class="cole">
                <Select classNm="half" title="특허문의" :list="[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]"/>
                <InputBox classNm="half type2"
                  :data="{
                    shape: 'search',
                    type: 'text',
                    placeholder: '검색어를 입력하세요.',
                  }"
                />
              </div>
              <div class="cole">
                <div class="temp"></div>
                <InputBox classNm="half type2"
                  :data="{
                    shape: 'search',
                    type: 'text',
                    placeholder: '검색어를 입력하세요.',
                  }"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="right bg_gray">
        <div class="list_goods" v-for="i in 10" :key="i">
          <div class="unit">
            <dl>
              <dt>상품명</dt>
              <dd><b class="f15">시니어기술 창업자금</b></dd>
            </dl>
            <dl>
              <dt>금융방식</dt>
              <dd><b class="txt_label">TCB기술금융</b><b class="txt_label grean">TCB기술금융</b><b class="txt_label org">TCB기술금융</b></dd>
            </dl>
            <dl class="half">
              <dt>지원한도</dt>
              <dd><b>최고 7천만원</b></dd>
            </dl>
            <dl class="half">
              <dt>대출기간</dt>
              <dd>기관별 상이</dd>
            </dl>
            <dl class="half">
              <dt>보증비율</dt>
              <dd>95%</dd>
            </dl>
            <dl class="half">
              <dt>보증료율</dt>
              <dd><b>연 0.8%</b></dd>
            </dl>
            <dl>
              <dt>금융기관</dt>
              <dd>신용보증재단</dd>
            </dl>
            <dl>
              <dt>비고</dt>
              <dd>대표자가 대기업·중견기업·정부출연연구소 경력 보유자, 기술사, 이공계 석·박사 학위 보유자</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "~/components/layouts/Header";
import Location from "~/components/layouts/Location";
import InputBox from "~/components/common/Input";
import Select from "~/components/common/Select";
import RadioBox from "~/components/common/RadioBox";

export default {
  data() {
    return {}
  },
  created() {
  },
  components: {
    Header,
    Location,
    InputBox,
    Select,
    RadioBox,
    // Grid
  },
  methods: {
  }
};
</script>

