<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>새로운 기사</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner class="mt5 mr5" v-if="loading" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="$emit('closePop')">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>제목</h4>
                  <div class="i_wrap">
                    <Input class="mt5" :value.sync="body.Title" name="body.Title" type="text" placeholder="제목을 입력해주세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>언론사</h4>
                  <div class="i_wrap">
                    <Input :value.sync="body.Press" name="body.Press" type="text" placeholder="언론사를 입력해주세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>기사 URL</h4>
                  <div class="i_wrap">
                    <Input :value.sync="body.Link" name="body.Link" type="text" placeholder="기사 URL을 입력해주세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>기사 작성 날짜</h4>
                  <div class="i_wrap">
                    <DatePicker :value.sync="body.Date" />
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleService from '~/service/Cms/articleService';
import Input from '~/components/common/Input';
import DatePicker from '~/components/pages/tenbagger/DatePicker2';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  components: {
    Input,
    DatePicker,
    LoadingSpinner,
  },

  data() {
    return {
      body: {
        Title: null,
        Press: null,
        Link: null,
        Date: null,
      },
      loading: false,
    };
  },

  methods: {
    async fnClickSave(skipClose) {
      if (this.loading) return;
      if (!this.body.Title) {
        this.$popupAlert({
          msg: '제목을 입력하세요',
        });
        return;
      }
      if (!this.body.Press) {
        this.$popupAlert({
          msg: '언론사를 입력하세요',
        });
        return;
      }
      if (!/^http[s]?:\/\/([\S]{3,})/i.test(this.body.Link)) {
        this.$popupAlert({
          msg: 'URL 형식에 맞지 않습니다',
        });
        return;
      }
      if (!this.body.Date) {
        this.$popupAlert({
          msg: '기사 작성 날짜를 선택하세요',
        });
        return;
      }

      this.loading = true;
      const { result, data } = await articleService.addArticle(this.$axios, this.body);
      this.loading = false;
      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '새로운 기사가 추가되었습니다',
        });
        this.$emit('reloadData');
      } else {
        this.$popupAlert({
          msg: `기사를 추가하는데 실패했습니다(${data.error.msg})`,
        });
      }

      if (skipClose) {
        this.resetBody();
      } else {
        this.$emit('closePop');
      }
    },
    resetBody() {
      this.body = {
        Title: null,
        Press: null,
        Link: null,
        Date: null,
      };
    },
  },
};
</script>
