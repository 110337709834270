<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>특허 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClosePop">확인</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>특허번호</h4>
                  <div class="i_wrap mt10">
                    <span>{{ patnDetail.patn }}</span>
                  </div>
                </li>
                <li>
                  <h4>대표 IPC</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.ipcCd }}</span>
                  </div>
                </li>
                <li>
                  <h4>출원번호</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.appPatn }}</span>
                  </div>
                </li>
                <li>
                  <h4>등록번호</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.regPatn }}</span>
                  </div>
                </li>
                <li>
                  <h4>출원일</h4>
                  <div class="i_wrap">
                    <span>{{ fnChangeDateFormat(patnDetail.appPatnDate) }}</span>
                  </div>
                </li>
                <li>
                  <h4>등록일</h4>
                  <div class="i_wrap">
                    <span>{{ fnChangeDateFormat(patnDetail.regPatnDate) }}</span>
                  </div>
                </li>
                <li>
                  <h4>권리만료일</h4>
                  <div class="i_wrap">
                    <span>{{ fnChangeDateFormat(patnDetail.expPatnDate) }}</span>
                  </div>
                </li>
                <li>
                  <h4>권리자</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.hldr }}</span>
                  </div>
                </li>
                <li>
                  <h4>출원인</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.aplc }}</span>
                  </div>
                </li>
                <li>
                  <h4>발명자</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.invn }}</span>
                  </div>
                </li>
                <li>
                  <h4>상태</h4>
                  <div class="i_wrap">
                    <span>{{ fnGetCodeName('PAS00', patnDetail.patnStatus) }}</span>
                  </div>
                </li>
                <li>
                  <h4>평가등급</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.evGrade }}</span>
                  </div>
                </li>
                <li>
                  <h4>링크</h4>
                  <div class="i_wrap">
                    <span>
                      <a :href="patnDetail.patnLink" target="_blank">{{ patnDetail.patnLink }}</a>
                    </span>
                  </div>
                </li>
                <li>
                  <h4>명칭</h4>
                  <div class="i_wrap">
                    <span>{{ patnDetail.patnTitle }}</span>
                  </div>
                </li>
                <li>
                  <h4>요약</h4>
                  <div class="i_wrap">
                    <span v-html="patnDetail.patnDesc"></span>
                  </div>
                </li>
                <li>
                  <h4>추천기업</h4>
                  <div class="i_wrap">
                    <span v-html="fnChangeCorpStrFormat(patnDetail.rcmdCorp)"></span>
                  </div>
                </li>
                <li>
                  <h4>유사기업</h4>
                  <div class="i_wrap">
                    <span v-html="fnChangeCorpStrFormat(patnDetail.smlrCorp)"></span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';

export default {
  props: ['selectedPatnNo'],
  components: {
    Input,
  },

  data() {
    return {
      patnDetail: {},
    };
  },

  created() {
    this.fetchPatn();
  },

  methods: {
    async fetchPatn() {
      const { result, data } = await this.$store.dispatch('openIp/patnStore/fetchPatnDetail', this.selectedPatnNo);
      if (result) {
        this.patnDetail = data.contents.patnDetail;
      } else {
        this.$popupAlert({
          msg: `특허정보를 가져오는데 실패했습니다(${data.error.msg})`,
        });
        this.fnClosePop();
      }
    },

    fnClosePop() {
      this.$emit('closePop');
    },

    fnChangeDateFormat(yyyymmdd) {
      if (yyyymmdd) {
        return yyyymmdd.replace(/(\d{4})(\d{2})(\d{2})/g, '$1년 $2월 $3일');
      }
      return yyyymmdd;
    },

    fnChangeCorpStrFormat(str) {
      if (str) {
        let arr = str.split(', ');
        if (arr) {
          return arr.map(el => `- ${el}`).join('<br/>');
        }
      }
      return str;
    },

    fnGetCodeName(codeGroup, code) {
      const list = this.$store.state.openIp.common[codeGroup];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeDesc : '';
      }
      return '';
    },
  },
};
</script>
