var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('Location'),_vm._v(" "),_c('div',{staticClass:"inner bg_white"},[_c('div',{staticClass:"colb"},[_c('div',{staticClass:"top"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.fnChangePopState('popStateCreate', true)}}},[_vm._v("새로운 공지사항")]),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":_vm.fnClickOpen}},[_vm._v("열기")]),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":_vm.fnClickDelete}},[_vm._v("삭제")]),_vm._v(" "),_c('strong',{staticClass:"ml10 mt15"},[_vm._v("\n          검색결과 : 현재페이지 [ "+_vm._s(Math.min(_vm.form.pageSize * (_vm.form.pageNo - 1) + 1, _vm.pagination.totalCount))+" ~ "+_vm._s(Math.min(_vm.form.pageSize * _vm.form.pageNo, _vm.pagination.totalCount))+" ] / 전체 : [\n          "+_vm._s(_vm.pagination.totalCount)+" ]\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col unit_page"},[(_vm.loading)?_c('LoadingSpinner',{staticClass:"mr5"}):_vm._e(),_vm._v(" "),_c('h4',{staticClass:"l_tit ml10"},[_vm._v("페이지당 개수 :")]),_vm._v(" "),_c('Select',{attrs:{"id":"pageSize","title":"20개","itemList":_vm.pageSizeList,"value":_vm.form.pageSize},on:{"change":item => {
              if (!this.loading) {
                this.form.pageNo = 1;
                this.form.pageSize = item.value;
                this.fnFetchData();
              }
            }}})],1)]),_vm._v(" "),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"tbl_area"},[_c('table',{staticClass:"tbl"},[_c('colgroup',_vm._l((_vm.headers),function(el,idx){return _c('col',{key:idx,attrs:{"width":el.colWidth}})}),0),_vm._v(" "),_c('thead',[_c('tr',_vm._l((_vm.headers),function(el,idx){return _c('th',{key:idx},[_c('b',[_vm._v(_vm._s(el.colName))]),_vm._v(" "),(el.colSortNm)?_c('div',{staticClass:"bx_srot"},[_c('button',{staticClass:"btn_up",class:{
                      on: _vm.form.sortColumn !== el.colSortNm || _vm.form.sortOrdr === 'ASC',
                    },on:{"click":() => _vm.fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'ASC' })}},[_vm._v("\n                    오름차순정렬\n                  ")]),_vm._v(" "),_c('button',{staticClass:"btn_down",class:{
                      on: _vm.form.sortColumn !== el.colSortNm || _vm.form.sortOrdr === 'DESC',
                    },on:{"click":() => _vm.fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'DESC' })}},[_vm._v("\n                    내림차순정렬\n                  ")])]):_vm._e()])}),0)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.contents),function(item){return _c('tr',{key:item.id,class:{ on: item.id === _vm.selectedNotice },on:{"click":function($event){return _vm.fnClickNotice(item.id)},"dblclick":_vm.fnClickOpen}},[_c('td',[_vm._v(_vm._s(item.id))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.attributes.Title))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.attributes.updatedAt && _vm.fnUtcToDate(item.attributes.updatedAt)))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.attributes.createdAt && _vm.fnUtcToDate(item.attributes.createdAt)))])])}),_vm._v(" "),(_vm.pagination.totalCount == 0)?_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v("데이터가 없습니다.")])]):_vm._e()],2)])]),_vm._v(" "),_c('Pagination',{attrs:{"page":_vm.form.pageNo,"perPage":_vm.form.pageSize,"totalCount":_vm.pagination.totalCount},on:{"change":p => {
            if (!this.loading) {
              this.form.pageNo = p;
              this.fnFetchData();
            }
          }}})],1)]),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.popStateCreate == true)?_c('NoticeCreate',{on:{"reloadData":_vm.fnFetchData,"closePop":function($event){return _vm.fnChangePopState('popStateCreate', false)}}}):_vm._e(),_vm._v(" "),(_vm.popStateDetail == true)?_c('NoticeDetail',{attrs:{"selectedNotice":_vm.selectedNotice},on:{"reloadData":_vm.fnFetchData,"closePop":function($event){return _vm.fnChangePopState('popStateDetail', false)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }