<template>
  <div class="inner bg_white">
    <h3>매수매도 종합</h3>
    <div class="area_search">
      <div class="unit">
        <label class="tit" for="pmsCode">PMS코드</label>
        <Input id="pmsCode" :value.sync="search.pmsCode" name="search.pmsCode" type="text" placeholder="PMS코드를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="code">종목코드</label>
        <Input id="code" :value.sync="search.code" name="search.code" type="text" placeholder="종목코드를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="logicId">로직ID</label>
        <Input id="logicId" :value.sync="search.logicId" name="search.logicId" type="text" placeholder="로직ID를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit colb">
        <div></div>
        <button class="btn" @click="fnClickSearch">검색</button>
      </div>
    </div>
    <div class="colb">
      <h4 class="mt15">
        검색결과 : 현재페이지 [ {{ Math.min(form.pageSize * (form.pageNo - 1) + 1, pagination.totalCount) }} ~ {{ Math.min(form.pageSize * form.pageNo, pagination.totalCount) }} ] / 전체 : [
        {{ pagination.totalCount }} ]
      </h4>
      <div class="col unit_page">
        <LoadingSpinner class="mr5" v-if="loading" />
        <h4 class="l_tit ml10">검색기간 :</h4>
        <DateRangeTabs
          :loading="loading"
          @change="
            ({ fromDate, toDate }) => {
              form.pageNo = 1;
              form.startBuyDatedeal = fromDate;
              form.endBuyDatedeal = toDate;
              this.fnFetchData();
            }
          " />
        <h4 class="l_tit ml10">페이지당 개수 :</h4>
        <Select
          id="pageSize"
          title="20개"
          :itemList="pageSizeList"
          :value="form.pageSize"
          @change="
            item => {
              if (!this.loading) {
                this.form.pageNo = 1;
                this.form.pageSize = item.value;
                this.fnFetchData();
              }
            }
          " />
      </div>
    </div>
    <div class="contents">
      <div class="tbl_area">
        <table class="tbl">
          <colgroup>
            <col v-for="(el, idx) in headers" :key="idx" :width="el.colWidth" />
          </colgroup>
          <thead>
            <tr>
              <th v-for="(el, idx) in headers" :key="idx">
                <b>{{ el.colName }}</b>
                <div class="bx_srot" v-if="el.colSortNm">
                  <button
                    class="btn_up"
                    :class="{
                      on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'ASC',
                    }"
                    @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'ASC' })">
                    오름차순정렬
                  </button>
                  <button
                    class="btn_down"
                    :class="{
                      on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'DESC',
                    }"
                    @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'DESC' })">
                    내림차순정렬
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in contents" :key="idx">
              <td>{{ item.pmsCode }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.logicId }}</td>
              <td>{{ item.buyDatedeal && fnFormatDate(item.buyDatedeal) }}</td>
              <td>{{ item.sumBuyVol && fnFormatNumber(item.sumBuyVol) }}주</td>
              <td>{{ item.avgBuyPrice && fnFormatNumber(item.avgBuyPrice) }}원</td>
              <td>{{ item.sumSellVol && fnFormatNumber(item.sumSellVol) }}주</td>
              <td>{{ item.avgSellPrice && fnFormatNumber(item.avgSellPrice) }}원</td>
              <td>{{ item.profit && fnFormatNumber(item.profit) }}원</td>
              <td>{{ item.rate }}</td>
              <td>{{ item.regdate && item.regdate.replace(/-/gi, '/') }}</td>
              <td></td>
            </tr>
            <tr v-if="pagination.totalCount == 0">
              <td colspan="12">데이터가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :page="form.pageNo"
        :perPage="form.pageSize"
        :totalCount="pagination.totalCount"
        @change="
          p => {
            if (!this.loading) {
              this.form.pageNo = p;
              this.fnFetchData();
            }
          }
        " />
    </div>
  </div>
</template>

<script>
import thinkpoolService from '~/service/commonTenbagger/thinkpoolService';
import Location from '~/components/layouts/LocationDataWrapper';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';
import DateRangeTabs from '../DateRangeTabs.vue';

export default {
  components: {
    Location,
    Pagination,
    Input,
    Select,
    SelectSearch,
    LoadingSpinner,
    DateRangeTabs,
  },

  data() {
    return {
      headers: [
        { colName: 'PMS코드', colSortNm: 'PMS_CODE', colWidth: '7%' },
        { colName: '종목코드', colSortNm: 'CODE', colWidth: '6%' },
        { colName: '로직ID', colSortNm: 'LOGIC_ID', colWidth: '6%' },
        { colName: '최초매수일자', colSortNm: 'BUY_DATEDEAL', colWidth: '8%' },
        { colName: '매수수량누적', colSortNm: 'SUM_BUY_VOL', colWidth: '8%' },
        { colName: '평균매수단가', colSortNm: 'AVG_BUY_PRICE', colWidth: '8%' },
        { colName: '매도수량누적', colSortNm: 'SUM_SELL_VOL', colWidth: '8%' },
        { colName: '평균매도단가', colSortNm: 'AVG_SELL_PRICE', colWidth: '8%' },
        { colName: '평가손익', colSortNm: 'PROFIT', colWidth: '8%' },
        { colName: '수익률(%)', colSortNm: 'RATE', colWidth: '8%' },
        { colName: '입력일자', colSortNm: 'REGDATE', colWidth: '8%' },
        { colName: '비고', colSortNm: null, colWidth: '17%' },
      ],
      form: {
        pageNo: 1,
        pageSize: 10,
        pmsCode: null,
        code: null,
        logicId: null,
        startBuyDatedeal: null,
        endBuyDatedeal: null,
        sortColumn: 'BUY_DATEDEAL',
        sortOrdr: 'DESC',
      },
      search: {
        pmsCode: null,
        code: null,
        logicId: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '50개', value: 50 },
        { name: '100개', value: 100 },
      ],
      loading: false,
    };
  },

  methods: {
    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchData();
      }
    },

    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchData();
      }
    },

    async fnFetchData() {
      this.loading = true;
      const { result, data } = await thinkpoolService.getRbpTradingTotal(this.$axios, this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `데이터를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnFormatDate(date) {
      return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3');
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

  mounted() {
    this.fnFetchData();
  },
};
</script>
