<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="searchWrd">섹터명</label>
          <Input id="searchWrd" :value.sync="form.searchWrd" name="form.searchWrd" type="text" shape="search" placeholder="섹터명을 입력하세요." @enter="fnClickSearch" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnOpenPop('popStateCreate')">새로운 섹터</button>
          <button class="btn" @click="fnClickOpen('popStateDetail')">열기</button>
          <button class="btn" @click="fnClickDelete">삭제</button>
          <button class="btn" @click="fnClickOpen('popStateStock')">종목</button>
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <SectorCreatePop
              v-if="popStateCreate == true"
              :key="popStateCreate"
              @reloadData="fnReloadData"
              @closePop="
                () => {
                  this.fnChangePopState('popStateCreate', false);
                }
              "
            />
            <SectorDetailPop
              v-if="popStateDetail == true"
              :key="popStateDetail"
              :selectedSector="selectedSector"
              @reloadData="fnReloadData"
              @closePop="
                () => {
                  this.fnChangePopState('popStateDetail', false);
                }
              "
            />
            <SectorStockPop
              v-if="popStateStock == true"
              :key="popStateStock"
              :selectedSector="selectedSector"
              @closePop="
                () => {
                  this.fnChangePopState('popStateStock', false);
                }
              "
            />
            <Confirm v-if="$store.state.ui.popConfirmData.state == true" :key="$store.state.ui.popConfirmData.state" @confirm="fnDeleteSector" />
          </transition>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select id="pageSize" title="20개" :itemList="pageSizeList" :value.sync="form.pageSize" @change="fnClickSearch" />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="35%" />
              <col width="35%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <b>섹터번호</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'SECTOR_NO' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'SECTOR_NO', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'SECTOR_NO' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'SECTOR_NO', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>섹터이름</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'SECTOR_NAME' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'SECTOR_NAME', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'SECTOR_NAME' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'SECTOR_NAME', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th><b>보고서 개요</b></th>
                <th><b>보고서 이슈/트렌드</b></th>
                <th>
                  <b>보고 일자</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'RPT_DT' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'RPT_DT', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'RPT_DT' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'RPT_DT', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.sectorNo" :class="{ on: item.sectorNo === selectedSector }" @click="fnClickSector(item.sectorNo)" @dblclick="fnClickOpen('popStateDetail')">
                <td>{{ item.sectorNo }}</td>
                <td>{{ item.sectorName }}</td>
                <td>{{ fnTextLengthOverCut(item.rptOutline) }}</td>
                <td>{{ fnTextLengthOverCut(item.rptIssue) }}</td>
                <td>{{ item.rptDt }}</td>
              </tr>
              <tr v-if="contents.length === 0">
                <td colspan="7">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              this.form.pageNo = p;
              this.fnFetchSector();
            }
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import SectorCreatePop from '~/components/pages/tenbagger/sector/SectorCreate';
import SectorDetailPop from '~/components/pages/tenbagger/sector/SectorDetail';
import SectorStockPop from '~/components/pages/tenbagger/sector/SectorStock';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import Confirm from '~/components/popup/Confirm';

export default {
  components: {
    Location,
    SectorCreatePop,
    SectorDetailPop,
    SectorStockPop,
    Pagination,
    Input,
    Select,
    Confirm,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_SECTOR'],
      form: {
        pageNo: 1,
        pageSize: 20, // 한 페이지에 보여줄 rows의 갯수
        sortColumn: null,
        sortOrdr: null,
        searchWrd: null,
      },
      contents: [],
      pagination: {
        totalCount: 0,
      },
      selectedSector: 0,
      popStateCreate: false,
      popStateDetail: false,
      popStateStock: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '30개', value: 30 },
      ],
    };
  },

  methods: {
    fnClickSector(sectorNo) {
      this.selectedSector = sectorNo;
    },

    fnClickSort({ sortColumn, sortOrdr }) {
      if (this.form.sortColumn !== sortColumn) {
        this.form.sortColumn = sortColumn;
        this.form.sortOrdr = sortOrdr;
      } else if (this.form.sortOrdr !== sortOrdr) {
        this.form.sortOrdr = sortOrdr;
      } else {
        this.form.sortColumn = null;
        this.form.sortOrdr = null;
      }
      this.fnFetchSector();
    },

    fnClickSearch() {
      this.form.pageNo = 1;
      this.fnFetchSector();
    },

    async fnFetchSector() {
      const { result, data } = await this.$store.dispatch('tenbagger/sector/fetchSector', this.form);
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `섹터리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnClickOpen(key) {
      if (!this.selectedSector) {
        this.$popupAlert({
          msg: '섹터를 선택해주세요',
        });
      } else {
        this.fnOpenPop(key);
      }
    },

    fnClickDelete() {
      if (!this.selectedSector) {
        this.$popupAlert({
          msg: '섹터를 선택해주세요',
        });
      } else {
        this.$popupConfirm({
          title: '섹터 삭제',
          msg: '섹터를 삭제하시겠습니까?',
          btn: '삭제',
        });
      }
    },

    async fnDeleteSector() {
      const { result, data } = await this.$store.dispatch('tenbagger/sector/deleteSector', this.selectedSector);
      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '섹터정보가 삭제되었습니다',
        });
        this.selectedSector = 0;
        this.fnReloadData();
      } else {
        this.$popupAlert({
          msg: `섹터정보를 삭제하는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnOpenPop(key) {
      // this.$store.dispatch('ui/setMenuState', false);
      this.fnChangePopState(key, true);
    },

    fnReloadData() {
      this.fnFetchSector();
    },

    fnChangePopState(key, pop) {
      this[key] = pop;
    },

    fnTextLengthOverCut(txt) {
      if (txt != null && txt.length > 50) {
        txt = txt.substr(0, 50) + '...';
      }
      return txt;
    },
  },

  mounted() {
    this.fnFetchSector();
  },
};
</script>
