<template>
  <div class="inner bg_white">
    <h3>매수종목 신호</h3>
    <div class="area_search">
      <div class="unit">
        <label class="tit" for="pmsCode">PMS코드</label>
        <Input id="pmsCode" :value.sync="search.pmsCode" name="search.pmsCode" type="text" placeholder="PMS코드를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="code">종목코드</label>
        <Input id="code" :value.sync="search.code" name="search.code" type="text" placeholder="종목코드를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="codename">종목명</label>
        <Input id="codename" :value.sync="search.codename" name="search.codename" type="text" placeholder="종목명을 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="logicId">로직ID</label>
        <Input id="logicId" :value.sync="search.logicId" name="search.logicId" type="text" placeholder="로직ID를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="stockSise">종목규모</label>
        <Input id="stockSise" :value.sync="search.stockSise" name="search.stockSise" type="text" placeholder="종목규모를 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="buyWeight">매수투자비중</label>
        <Input id="buyWeight" :value.sync="search.buyWeight" name="search.buyWeight" type="text" placeholder="매수투자비중을 입력하세요" @enter="fnClickSearch" />
      </div>
      <div class="unit">
        <label class="tit" for="status">현재상태</label>
        <Select id="status" :itemList="statusList" :value.sync="search.status" name="search.status" />
      </div>
      <div class="unit">
        <label class="tit" for="sendFlag">퀀트전송</label>
        <Select id="sendFlag" :itemList="sendFlagList" :value.sync="search.sendFlag" name="search.sendFlag" />
      </div>
      <div class="unit"></div>
      <div class="unit colb">
        <div></div>
        <button class="btn" @click="fnClickSearch">검색</button>
      </div>
    </div>
    <div class="colb">
      <h4 class="mt15">
        검색결과 : 현재페이지 [ {{ Math.min(form.pageSize * (form.pageNo - 1) + 1, pagination.totalCount) }} ~ {{ Math.min(form.pageSize * form.pageNo, pagination.totalCount) }} ] / 전체 : [
        {{ pagination.totalCount }} ]
      </h4>
      <div class="col unit_page">
        <LoadingSpinner class="mr5" v-if="loading" />
        <h4 class="l_tit ml10">검색기간 :</h4>
        <DateRangeTabs
          :loading="loading"
          @change="
            ({ fromDate, toDate }) => {
              form.pageNo = 1;
              form.startDatedeal = fromDate;
              form.endDatedeal = toDate;
              this.fnFetchData();
            }
          " />
        <h4 class="l_tit ml10">페이지당 개수 :</h4>
        <Select
          id="pageSize"
          title="20개"
          :itemList="pageSizeList"
          :value="form.pageSize"
          @change="
            item => {
              if (!this.loading) {
                this.form.pageNo = 1;
                this.form.pageSize = item.value;
                this.fnFetchData();
              }
            }
          " />
      </div>
    </div>
    <div class="contents">
      <div class="tbl_area">
        <table class="tbl">
          <colgroup>
            <col v-for="(el, idx) in headers" :key="idx" :width="el.colWidth" />
          </colgroup>
          <thead>
            <tr>
              <th v-for="(el, idx) in headers" :key="idx">
                <b>{{ el.colName }}</b>
                <div class="bx_srot" v-if="el.colSortNm">
                  <button
                    class="btn_up"
                    :class="{
                      on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'ASC',
                    }"
                    @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'ASC' })">
                    오름차순정렬
                  </button>
                  <button
                    class="btn_down"
                    :class="{
                      on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'DESC',
                    }"
                    @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'DESC' })">
                    내림차순정렬
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in contents" :key="idx">
              <td>{{ item.pmsCode }}</td>
              <td>{{ item.datedeal && fnFormatDate(item.datedeal) }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.codename }}</td>
              <td>{{ item.logicId }}</td>
              <td>{{ item.stockSise }}</td>
              <td>{{ item.buyPrice && fnFormatNumber(item.buyPrice) }}원</td>
              <td>{{ item.buyWeight }}</td>
              <td>{{ item.buyVol && fnFormatNumber(item.buyVol) }}주</td>
              <td>{{ item.status }}</td>
              <td>{{ item.regdate && item.regdate.replace(/-/gi, '/') }}</td>
              <td>{{ item.sendFlag }}</td>
              <td>{{ item.goalPrice && fnFormatNumber(item.goalPrice) }}원</td>
              <td></td>
            </tr>
            <tr v-if="pagination.totalCount == 0">
              <td colspan="13">데이터가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :page="form.pageNo"
        :perPage="form.pageSize"
        :totalCount="pagination.totalCount"
        @change="
          p => {
            if (!this.loading) {
              this.form.pageNo = p;
              this.fnFetchData();
            }
          }
        " />
    </div>
  </div>
</template>

<script>
import thinkpoolService from '~/service/commonTenbagger/thinkpoolService';
import Location from '~/components/layouts/LocationDataWrapper';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';
import DateRangeTabs from '../DateRangeTabs.vue';

export default {
  components: {
    Location,
    Pagination,
    Input,
    Select,
    SelectSearch,
    LoadingSpinner,
    DateRangeTabs,
  },

  data() {
    return {
      headers: [
        { colName: 'PMS코드', colSortNm: 'PMS_CODE', colWidth: '7%' },
        { colName: '매수일자', colSortNm: 'DATEDEAL', colWidth: '7%' },
        { colName: '종목코드', colSortNm: 'CODE', colWidth: '6%' },
        { colName: '종목명', colSortNm: 'CODENAME', colWidth: '12%' },
        { colName: '로직ID', colSortNm: 'LOGIC_ID', colWidth: '6%' },
        { colName: '종목규모', colSortNm: 'STOCK_SISE', colWidth: '6%' },
        { colName: '매수단가', colSortNm: 'BUY_PRICE', colWidth: '8%' },
        { colName: '매수투자비중', colSortNm: 'BUY_WEIGHT', colWidth: '8%' },
        { colName: '매수수량', colSortNm: 'BUY_VOL', colWidth: '8%' },
        { colName: '현재상태[E:예정/C:체결]', colSortNm: 'STATUS', colWidth: '12%' },
        { colName: '입력/수정일자', colSortNm: 'REGDATE', colWidth: '8%' },
        { colName: '퀀트전송', colSortNm: 'SEND_FLAG', colWidth: '6%' },
        { colName: '목표가', colSortNm: 'GOAL_PRICE', colWidth: '6%' },
      ],
      form: {
        pageNo: 1,
        pageSize: 10,
        pmsCode: null,
        code: null,
        codename: null,
        logicId: null,
        stockSise: null,
        buyWeight: null,
        status: null,
        sendFlag: null,
        startDatedeal: null,
        endDatedeal: null,
        sortColumn: 'DATEDEAL',
        sortOrdr: 'DESC',
      },
      search: {
        pmsCode: null,
        code: null,
        codename: null,
        logicId: null,
        stockSise: null,
        buyWeight: null,
        status: null,
        sendFlag: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '50개', value: 50 },
        { name: '100개', value: 100 },
      ],
      statusList: [
        { name: '전체', value: null },
        { name: 'E(예정)', value: 'E' },
        { name: 'C(체결)', value: 'C' },
      ],
      sendFlagList: [
        { name: '전체', value: null },
        { name: 'Y', value: 'Y' },
        { name: 'N', value: 'N' },
      ],
      loading: false,
    };
  },

  methods: {
    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchData();
      }
    },

    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchData();
      }
    },

    async fnFetchData() {
      this.loading = true;
      const { result, data } = await thinkpoolService.getRbpTradingBuy(this.$axios, this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `데이터를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnFormatDate(date) {
      return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3');
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

  mounted() {
    this.fnFetchData();
  },
};
</script>
