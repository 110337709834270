<template>
  <div class="popup">
    <div class="overlay" @click="$emit('close')"></div>
    <div class="content">
      <div class="cont1">
        <p>Alert</p>
        <button class="btn_close" @click="$emit('close')">닫기</button>
      </div>
      <div class="cont2">
        <p>HELLO!</p>
        <p>I'M POPUP</p>
      </div>
      <div class="cont3">
        <button class="btn" @click="$emit('close')">Save</button>
        <button class="btn dark" @click="$emit('close')">Close</button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* Modal */
</style>
