<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ol>
        <li>
          <label for="stk"><b>배정된 담당자:</b></label>
          <Input
            id="stk"
            :value="customer.cnsltntNm"
            name="consultant"
            type="text"
            placeholder="담당자를 선택해주세요"
            :disabled="true"
            :styleAttr="{ width: '100%' }"
          />
        </li>
      </ol>
    </div>
    <div class="right">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="consultantNm">코드<br />담당자 이름</label>
          <Input
            id="consultantNm"
            :value.sync="form.consultantNm"
            name="consultantNm"
            type="text"
            shape="search"
            placeholder="코드 혹은 담당자 이름을 입력해주세요"
            :styleAttr="{ width: '100%' }"
          />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area" style="min-width: auto">
          <table class="tbl">
            <colgroup>
              <col width="35%" />
              <col width="50%" />
              <col width="15%" />
            </colgroup>
            <thead>
            <tr>
              <th>
                <b>코드</b>
              </th>
              <th>
                <b>담당자</b>
              </th>
              <th>
                <b>&nbsp;</b>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in consultantList" :key="item.id">
              <td>
                {{ item.consultant_cd }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <button class="btn" @click="fnSelect(item)">선택</button>
              </td>
            </tr>
            <tr v-if="consultants.length === 0">
              <td colspan="2">데이터가 없습니다.</td>
            </tr>
            </tbody>
          </table>
          <Pagination
              v-if="!form.consultantNm"
              :page="pageNum+1"
              :perPage="pageSize"
              :totalCount="pagination.totalCount"
              @change="p => {
                this.pageNum=p-1;
              }"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import customersService from '~/service/allOk/customersService';
import consultantsService from "~/service/allOk/consultantsService";
import Pagination from "~/components/common/Pagination";

export default {
  name: 'CustomerTab3',
  props: ['id'],
  components: {
    Input,
    ItemPatent,
    Select,
    Pagination,
  },
  data() {
    return {
      consultant_id: null,
      customer: {},
      consultants:[],
      form:{
        page: 1,
        pageSize: 20,
        consultantNm: null,
      },
      pagination: {
        totalCount: 0,
      },
      dataList:[],
      pageSize: 15,
      pageNum: 0
    };
  },
  computed: {
    consultantList(){
      if(this.form.consultantNm){
          return this.consultants.filter( c => c.name.indexOf(this.form.consultantNm) >= 0|| c.consultant_cd.indexOf(this.form.consultantNm) >= 0)
      } else {
        const start = this.pageNum*this.pageSize
        const end = start+this.pageSize
        return this.consultants.slice(start, end)
      }
    }
  },
  beforeMount() {
    this.fetchCustomer();
    this.fetchConsultantList();
  },
  methods: {
    fetchConsultantList() {
      consultantsService.fetchConsultants(this.$axios)
      .then(res => {
        const {data : {data,result} } = res;
        if(result === true){
          this.consultants = data.contents;
          this.pagination.totalCount = data.contents.length;
        } else {
          this.consultants = [];
        }
      });
    },
    fetchCustomer(){
      customersService.fetchCustomerDetail(this.$axios, this.id)
      .then( res => {
        this.customer = res.data.data.contents;
      })
    },
    fnSelect(item){
      this.customer.cnsltnt_id = item.id;
      this.customer.cnsltntNm = item.name;
      this.customer.consultantCd = item.consultant_cd;
      const btn = {
        name: '확인',
        func: () => {
          this.form.technology = item.value
          this._closeConfirm();
          this.fnSave();
        }
      }
      this._confirm('확인', '저장하시겠습니까?', btn);
    },
    async fnSave(){
      const res = await customersService.addConsultant(this.$axios, this.id, {
        cnsltntCd : this.customer.consultantCd,
        cnsltId: this.id
      })

      const { result } = res.data;
      if(result === true){
        let data = {
          state:true,
          type: "alert",
          title: "완료",
          msg: `${this.customer.cnsltntNm}(담당자)를 배정완료했습니다.`,
          btn:{
            name:"확인",
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      }
    },
    _alert(title, msg){
      let data = {
        state:true,
        type: "alert",
        title: title,
        msg: msg,
        btn:{
          name:"확인",
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn){
      let data  = {
        state:true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closeConfirm(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
    _closePop(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
  }
};
</script>
