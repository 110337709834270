<template>
  <div>
    <div class="bx_txt_search">
      <div class="bx_txt">{{ stk.isuSrtCd ? `${stk.isuSrtCd}(${stk.isuKorNm})` : null }}</div>
      <button class="btn_search type2" @click="fnSearch">검색</button>
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <StockSelect
        v-if="popState == true"
        :key="popState"
        @selectStk="fnSelectStk"
        @closePop="
          () => {
            this.fnChangePopState(false);
          }
        "
        :stk="stk"
      />
    </transition>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import StockSelect from '~/components/pages/tenbagger/StockSelect';

export default {
  components: {
    Input,
    StockSelect,
  },

  props: ['stk'],
  data() {
    return {
      popState: false,
    };
  },
  methods: {
    fnChangePopState(pop) {
      this.popState = pop;
    },

    fnSearch() {
      this.fnChangePopState(true);
    },

    fnSelectStk(stk) {
      this.fnChangePopState(false);
      this.$emit('update:stk', stk);
      this.$emit('updateStk');
    },
  },
};
</script>

<style></style>
