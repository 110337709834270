<template>
  <div>
    <h1>상품 목록 페이지</h1>
    <button v-if="$functionGroupId(2)">상품등록</button>
    <a href="http://localhost:3000">링크</a>
  </div>
</template>
<script>
export default {
  data(){
    return {
      fgii: 1, // function group item id
    }
  },
}
</script>
