<template>
  <div>
    <ul class="form_list type2">
      <li class="item_ipt">
        <div class="i_wrap">
          <div class="bx_txt_search">
            <div class="bx_txt f14">{{ desc }}</div>
            <button class="btn_search type2" @click="fnSearch">검색</button>
          </div>
        </div>
      </li>
      <li v-for="(el, index) in stkList" :key="index">
        <div class="i_wrap">
          <span>{{ `${el.isuSrtCd}(${el.isuKorNm})` }}</span>
        </div>
      </li>
    </ul>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <MultiStockSelect
        v-if="popState == true"
        :key="popState"
        @selectStkList="fnSelectStkList"
        @closePop="
          () => {
            this.fnChangePopState(false);
          }
        "
        :stkList="stkList"
      />
    </transition>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import MultiStockSelect from './MultiStockSelect.vue';

export default {
  components: {
    Input,
    MultiStockSelect,
  },

  props: ['stkList', 'desc'],
  data() {
    return {
      popState: false,
    };
  },

  methods: {
    fnChangePopState(pop) {
      this.popState = pop;
    },

    fnSearch() {
      this.fnChangePopState(true);
    },

    fnSelectStkList(stks) {
      this.fnChangePopState(false);
      this.$emit('update:stkList', stks);
      this.$emit('updateStk');
    },
  },
};
</script>

<style></style>
