<template>
  <div class="view_date" @click="() => (this.tooltipOnOff = true)">
    <span class="start">{{ value }}</span>
    <DatePicTooltip
      :onOff="tooltipOnOff"
      :value="value"
      @date="fnDateChanged"
      @cancel="() => (this.tooltipOnOff = false)"
    />
  </div>
</template>

<script>
import DatePicTooltip from './DatePicTooltip';
export default {
  props: ['value'],
  components: {
    DatePicTooltip,
  },
  data() {
    return {
      tooltipOnOff: false,
    };
  },
  methods: {
    fnDateChanged(date) {
      this.tooltipOnOff = false;
      this.$emit('update:value', date);
      this.$emit('change', date);
    },
  },
};
</script>
