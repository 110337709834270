<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="item_ipt">
          <h4>고객명<br />(아이디)</h4>
          <div class="i_wrap mt15">{{ `${userDetail.userNm}(${userDetail.userId})` }}</div>
        </li>
        <li>
          <h4>기관 또는 기업<br />(사업자번호)</h4>
          <div class="i_wrap mt10">
            <span> {{ userDetail.organizationNm ? (userDetail.bizNo ? `${userDetail.organizationNm}(${userDetail.bizNo})` : userDetail.organizationNm) : '' }}</span>
          </div>
        </li>
        <li>
          <h4>대표자명</h4>
          <div class="i_wrap">
            <span>{{ userDetail.presidentNm }}</span>
          </div>
        </li>
        <li>
          <h4>담당자명</h4>
          <div class="i_wrap">
            <span>{{ userDetail.managerNm }}</span>
          </div>
        </li>
        <li>
          <h4>연락처</h4>
          <div class="i_wrap">
            <span>{{ userDetail.phoneNo }}</span>
          </div>
        </li>
        <li>
          <h4>키워드</h4>
          <div class="i_wrap">
            <span>{{ userDetail.keywords }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
  props: ['userId'],
  data() {
    return {
      userDetail: {},
    };
  },

  methods: {
    async fnGetUserDetail() {
      if (this.userId) {
        const { result, data } = await this.$store.dispatch('openIp/fetchUserDetail', this.userId);
        console.log(result, data);
        if (result) {
          console.log(data);
          this.userDetail = data.contents;
        }
      }
    },
  },
  watch: {
    userId(value) {
      this.fnGetUserDetail();
    },
  },
};
</script>

<style></style>
