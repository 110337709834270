<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="searchPrdcType">상품타입</label>
          <Select id="searchPrdcType" :itemList="prdcType" :value.sync="search.searchPrdcType" name="search.searchPrdcType" />
        </div>
        <div class="unit">
          <label class="tit" for="searchStateCd">진행상태</label>
          <Select id="searchStateCd" :itemList="stateCd" :value.sync="search.searchStateCd" name="search.searchStateCd" />
        </div>
        <div class="unit">
          <label class="tit" for="searchPrdc">상품번호</label>
          <Input id="searchPrdc" :value.sync="search.searchPrdc" name="search.searchPrdc" type="text" placeholder="상품번호를 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchPrdcNm">상품명</label>
          <Input id="searchPrdcNm" :value.sync="search.searchPrdcNm" name="search.searchPrdcNm" type="text" placeholder="상품명을 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchHldr">기업명</label>
          <Input id="searchCoName" :value.sync="search.searchCoName" name="search.searchCoName" type="text" placeholder="기업명을 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchApplicant">담당자</label>
          <Input id="searchApplicant" :value.sync="search.searchApplicant" name="search.searchApplicant" type="text" placeholder="담당자를 입력하세요." @enter="fnClickSearch" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnClickOpen">열기</button>
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <OrderDetailPop
              v-if="popStateDetail == true"
              :key="popStateDetail"
              :selectedOrderNo="selectedOrderNo"
              @reloadData="fnFetchOrder"
              @closePop="
                () => {
                  this.fnChangePopState('popStateDetail', false);
                }
              "
            />
          </transition>
        </div>
        <div class="col unit_page">
          <LoadingSpinner class="mr5" v-if="loading" />
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select
            id="pageSize"
            title="20개"
            :itemList="pageSizeList"
            :value="form.pageSize"
            @change="
              item => {
                if (!this.loading) {
                  this.form.pageNo = 1;
                  this.form.pageSize = item.value;
                  this.fnFetchOrder();
                }
              }
            "
          />
          <button class="btn" @click="fnClickSearch">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="30%" />
              <col width="15%" />
              <col width="15%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <b>상품타입</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'prdc_type' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'prdc_type', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'prdc_type' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'prdc_type', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>상품번호</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'prdc' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'prdc', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'prdc' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'prdc', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>상품명</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'prdc_nm' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'prdc_nm', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'prdc_nm' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'prdc_nm', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>기업명</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'co_name' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'co_name', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'co_name' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'co_name', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>담당자</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'applicant' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'applicant', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'applicant' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'applicant', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>신청일</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'reg_dtm' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'reg_dtm', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'reg_dtm' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'reg_dtm', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
                <th>
                  <b>진행상태</b>
                  <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'state_cd' || form.sortOrdr === 'asc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'state_cd', sortOrdr: 'asc' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'state_cd' || form.sortOrdr === 'desc',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'state_cd', sortOrdr: 'desc' })"
                    >
                      내림차순정렬
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.id" :class="{ on: item.id === selectedOrderNo }" @click="fnClickOrder(item.id)" @dblclick="fnClickOpen">
                <td>{{ fnGetCodeName('common', 'PT00', item.prdc_type) }}</td>
                <td>{{ item.prdc }}</td>
                <td>{{ item.prdc_nm }}</td>
                <td>{{ item.co_name }}</td>
                <td>{{ item.applicant }}</td>
                <td>{{ item.reg_dtm }}</td>
                <td>{{ fnGetCodeName('order', 'RS000', item.state_cd) }}</td>
              </tr>
              <tr v-if="pagination.totalCount == 0">
                <td colspan="7">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              if (!this.loading) {
                this.form.pageNo = p;
                this.fnFetchOrder();
              }
            }
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import OrderDetailPop from '~/components/pages/openip/order/OrderDetail';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  components: {
    Location,
    OrderDetailPop,
    Pagination,
    Input,
    Select,
    LoadingSpinner,
  },

  data() {
    return {
      roles: ['OPENIP'],
      form: {
        pageNo: 1,
        pageSize: 10,
        searchPrdcType: null,
        searchStateCd: null,
        searchPrdc: null,
        searchPrdcNm: null,
        searchCoName: null,
        searchApplicant: null,
        sortColumn: null,
        sortOrdr: null,
      },
      search: {
        searchPrdcType: null,
        searchStateCd: null,
        searchPrdc: null,
        searchPrdcNm: null,
        searchCoName: null,
        searchApplicant: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      selectedOrderNo: null,
      popStateDetail: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
      ],
      loading: false,
    };
  },

  computed: {
    prdcType() {
      let prdcType = this.$store.state.openIp.common.PT00 ? this.$store.state.openIp.common.PT00.map(el => ({ name: el.codeName, value: el.code })) : [];
      prdcType.unshift({ name: '전체', value: null });
      return prdcType;
    },
    stateCd() {
      let stateCd = this.$store.state.openIp.order.RS000 ? this.$store.state.openIp.order.RS000.map(el => ({ name: el.codeName, value: el.code })) : [];
      stateCd.unshift({ name: '전체', value: null });
      return stateCd;
    },
  },

  methods: {
    fnClickOrder(orderNo) {
      this.selectedOrderNo = orderNo;
    },

    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchOrder();
      }
    },

    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchOrder();
      }
    },

    async fnFetchOrder() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('openIp/orderStore/fetchOrder', this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
        this.selectedOrderNo = null;
      } else {
        this.$popupAlert({
          msg: `거래리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnClickOpen() {
      if (!this.selectedOrderNo) {
        this.$popupAlert({
          msg: '거래를 선택해주세요',
        });
      } else {
        this.fnOpenPop('popStateDetail');
      }
    },

    fnOpenPop(key) {
      // this.$store.dispatch('ui/setMenuState', false);
      this.fnChangePopState(key, true);
    },

    fnChangePopState(key, pop) {
      this[key] = pop;
    },

    fnGetCodeName(codeGrp1, codeGrp2, code) {
      const list = this.$store.state.openIp[codeGrp1][codeGrp2];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },
  },

  async mounted() {
    await this.$store.dispatch('openIp/fetchCodes');
    this.fnFetchOrder();
  },
};
</script>
