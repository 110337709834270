<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>상품 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner v-if="loading" class="mt5 mr5" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>상품번호</h4>
                  <div class="i_wrap mt10">
                    <span>{{ form.prdc }}</span>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>이름</h4>
                  <div class="i_wrap">
                    <Input id="prtfNm" :value.sync="form.prtfNm" name="form.prtfNm" type="text" placeholder="이름을 입력하세요" />
                  </div>
                </li>
                <li>
                  <h4>상품의 형태</h4>
                  <div class="i_wrap">
                    <span>{{ fnGetCodeName('PT00', prdcType) }}</span>
                  </div>
                </li>
                <li>
                  <h4>구성된 특허</h4>
                  <div class="list_patent ml5">
                    <ItemPatent v-for="el in patns" :key="el.patn" :data="{ id: el.patn, tit: el.patnTitle, disabled: true }" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>이미지</h4>
                  <div class="i_wrap">
                    <InputFile id="file" name="form.file" placeholder="파일을 선택하세요" :value.sync="form.file" />
                    <div class="bx_img">
                      <img :src="filePath" class="img" alt="첨부한 이미지" />
                    </div>
                  </div>
                </li>
                <li>
                  <h4>설명</h4>
                  <div class="i_wrap">
                    <textarea id="issue" class="textarea" placeholder="설명을 입력해주세요" style="height: 200px" v-model="form.prdcDesc"></textarea>
                  </div>
                </li>
                <li>
                  <h4>등급</h4>
                  <div class="i_wrap">
                    <span>{{ evGrade }}</span>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>가격</h4>
                  <div class="i_wrap">
                    <Input class="mt5" id="prdcPrice" :value.sync="form.prdcPrice" name="form.prdcPrice" type="text" placeholder="가격을 입력하세요" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>거래방식</h4>
                  <div class="i_wrap">
                    <Select :itemList="trdMthd" :value.sync="form.trdMthd" name="form.trdMthd" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>거래조건</h4>
                  <div class="i_wrap">
                    <Select :itemList="trdTrms" :value.sync="form.trdTrms" name="form.trdTrms" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>지급조건</h4>
                  <div class="i_wrap">
                    <Select :itemList="pymnTrms" :value.sync="form.pymnTrms" name="form.pymnTrms" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>라이선스 조건</h4>
                  <div class="i_wrap">
                    <Select :itemList="lcnsType" :value.sync="form.lcnsType" name="form.lcnsType" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>추천 형태</h4>
                  <div class="i_wrap">
                    <Select :itemList="rcmdTypes" name="rcmdTypes" @change="fnAddRcmdTypes" />
                    <div v-for="(el, idx) in form.rcmdTypes" :key="idx" class="btns_ico mr5 mt5">
                      <span class="f13">{{ fnGetCodeName('RT00', el) }}</span>
                      <button class="btn dark" @click="fnRemoveRcmdTypes(idx)">-</button>
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>시장성 타입</h4>
                  <div class="i_wrap">
                    <Select :itemList="mktType" :value.sync="form.mktType" name="form.mktType" />
                  </div>
                </li>

                <li class="item_ipt">
                  <h4>메인 노출 여부</h4>
                  <div class="i_wrap">
                    <Select
                      :itemList="[
                        { name: 'N', value: 'N' },
                        { name: 'Y', value: 'Y' },
                      ]"
                      :value.sync="form.mainDispYN"
                      name="form.mainDispYN"
                    />
                  </div>
                </li>
                <li>
                  <h4>카테고리</h4>
                  <div class="i_wrap">
                    <span v-html="fnChangeCatgStrFormat(this.catgNm)"></span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import InputFile from '~/components/common/InputFile';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import PatentSelect from '~/components/pages/openip/product/PatentSelect';
import ItemPatent from '~/components/common/ItemPatent.vue';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  props: ['prdc'],
  components: {
    Input,
    InputFile,
    Select,
    SelectSearch,
    PatentSelect,
    ItemPatent,
    LoadingSpinner,
  },

  data() {
    return {
      loading: false,
      form: {
        prdc: this.prdc,
        prtfNm: null,
        file: null,
        prdcDesc: null,
        prdcPrice: null,
        trdMthd: null,
        trdTrms: null,
        pymnTrms: null,
        lcnsType: null,
        rcmdTypes: [],
        mktType: null,
        mainDispYN: null,
      },
      prdcType: null,
      patns: [],
      filePath: null,
      evGrade: null,
      catgNm: null,
    };
  },

  computed: {
    trdMthd() {
      return this.$store.state.openIp.common.TM00 ? this.$store.state.openIp.common.TM00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    trdTrms() {
      return this.$store.state.openIp.common.TT00 ? this.$store.state.openIp.common.TT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    pymnTrms() {
      return this.$store.state.openIp.common.PTR00 ? this.$store.state.openIp.common.PTR00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    lcnsType() {
      return this.$store.state.openIp.common.LT00 ? this.$store.state.openIp.common.LT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    rcmdTypes() {
      return this.$store.state.openIp.common.RT00 ? this.$store.state.openIp.common.RT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    mktType() {
      return this.$store.state.openIp.common.MT00 ? this.$store.state.openIp.common.MT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },
  },

  mounted() {
    this.fetchPrdcDetail();
  },
  methods: {
    fnChangePopState(value) {
      this.popState = value;
    },

    fnClosePop() {
      this.$emit('closePop');
    },

    async fnClickSave(skipClose) {
      if (this.loading) return;

      if (!this.form.prtfNm) {
        this.$popupAlert({
          msg: '상품명을 입력하세요',
        });
        return;
      }

      this.loading = true;
      const { result, data } = await this.$store.dispatch('openIp/prdcStore/updatePrdc', this.form);
      if (result) {
        this.$popupAlert({
          msg: '상품 수정이 완료됐습니다',
        });
      } else {
        this.$popupAlert({
          msg: `상품 수정을 실패했습니다${data.error.msg})`,
        });
        this.loading = false;
        return;
      }
      this.$emit('reloadData');

      if (skipClose) {
        await this.fetchPrdcDetail();
      } else {
        this.fnClosePop();
      }
    },

    async fetchPrdcDetail() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('openIp/prdcStore/fetchPrdcDetail', this.form.prdc);
      this.loading = false;
      if (result) {
        let { prdcDetail, patns } = data.contents;
        this.form.prtfNm = prdcDetail.prdcNm;
        this.form.prdcDesc = prdcDetail.prdcDesc;
        this.form.prdcPrice = prdcDetail.prdcPrice;
        this.form.trdMthd = prdcDetail.trdMthd;
        this.form.trdTrms = prdcDetail.trdTrms;
        this.form.pymnTrms = prdcDetail.pymnTrms;
        this.form.lcnsType = prdcDetail.lcnsType;
        this.form.rcmdTypes = prdcDetail.rcmdType ? prdcDetail.rcmdType.split(', ') : [];
        this.form.mktType = prdcDetail.mktType;
        this.form.mainDispYN = prdcDetail.mainDispYn;
        this.prdcType = prdcDetail.prdcType;
        this.patns = patns.map(el => el.patnDetail);
        this.filePath = prdcDetail.file.filePath;
        this.evGrade = prdcDetail.evGrade;
        this.catgNm = prdcDetail.catgNm;
      } else {
        this.$popupAlert({
          msg: `상품 상세정보 가져오기 실패(${data.error.msg})`,
        });
        this.fnClosePop();
      }
    },

    fnAddRcmdTypes({ value }) {
      if (this.form.rcmdTypes.indexOf(value) != -1) {
        this.$popupAlert({
          msg: '이미 추가된 타입입니다',
        });
        return;
      }
      this.form.rcmdTypes.push(value);
    },

    fnRemoveRcmdTypes(idx) {
      this.form.rcmdTypes.splice(idx, 1);
    },

    fnGetCodeName(codeGroup, code) {
      const list = this.$store.state.openIp.common[codeGroup];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },

    fnChangeCatgStrFormat(str) {
      if (str) {
        let arr = str.split(', ');
        if (arr) {
          return arr.map(el => `- ${el}`).join('<br/>');
        }
      }
      return str;
    },
  },
};
</script>
