<template>
  <section class="container guide">
    <article class="g_bx" id="g_bx1">
      <h2>🎯 스타일 가이드</h2>
      <div class="col2">
        <div class="g_left">
          <h3>여백 : margin</h3>
          <div class="g_cont">
            5px 간격으로 0px ~ 50px 까지 클래스(m*5~50)가 있습니다<br />
            margin-top : ex) .mt5 (margin-top 5px)<br />
            margin-bottom : ex) .mb25 (margin-bottom 25px)<br />
            margin-left : ex) .ml10 (margin-left 10px)<br />
            margin-right : ex) .mr50 (margin-right 50px)<br />
          </div>
          <h3>여백 : padding</h3>
          <div class="g_cont">
            5px 간격으로 0px ~ 50px 까지 클래스(m*5~50)가 있습니다<br />
            padding-top : ex) .mt5 (padding-top 5px)<br />
            padding-bottom : ex) .mb25 (padding-bottom 25px)<br />
            padding-left : ex) .ml10 (padding-left 10px)<br />
            padding-right : ex) .mr50 (padding-right 50px)<br />
          </div>
          <h3>폰트 크기 스타일</h3>
          <div class="g_cont">
            <span>글꼴 :Pretendard 경량화 버전을 사용중입니다.</span>
            <span>기본 글꼴 크기 : 14px</span>
            <span>기본 글자 색상 : #262626</span>
          </div>
          <h3>폰트 사이즈</h3>
          <div class="g_cont">
            폰트 사이즈 10px ~ 20px까지 클래스(f10~f20)가 있습니다<br />
            <span class="f10">ex) .f10 : 폰트 사이즈 10px</span>
            <span class="f15">ex) .f15 : 폰트 사이즈 18px</span>
            <span class="f18">ex) .f18 : 폰트 사이즈 18px</span>
          </div>
          <h3>폰트 색상</h3>
          <div class="g_cont">
            <span class="txt_point">.txt_point(포인트 색상) : #2B3041</span>
            <span class="txt_black">.txt_black(검정) : #262626</span>
            <span
              class="txt_white"
              style="background: #aaa; display: inline-block"
              >.txt_white (화이트) : #ffffff</span
            >
            <span class="txt_red">.txt_red(빨강) : #DD4848</span>
            <span class="txt_blue">.txt_blue(파랑) : #3076CB</span>
            <span class="txt_sky">.txt_sky(하늘) : #66A3EC</span>
            <span class="txt_gray">.txt_gray(회색)  #666</span>
            <span class="txt_light_gray">.txt_light_gray(연회색) : #B5B5B5</span>
          </div>
        </div>
        <div class="g_right">
          <h3>배경</h3>
          <div class="g_cont">
            <span class="bg_white"> .bg_white : 배경 (흰색)</span><br />
            <span class="bg_black txt_white">.bg_black : 배경 (검정)</span><br />
            <span class="bg_gray txt_white">.bg_gray : 배경 (회색)</span><br />
            <span class="bg_point txt_white">.bg_point : 배경 (포인트)</span><br />
            <span class="bg_red">.bg_red : 배경 (빨강)</span><br />
            <span class="bg_blue">.bg_blue : 배경 (파랑)</span><br />
            <span class="bg_sky">.bg_sky : 배경 (하늘)</span><br />
          </div>
          <h3>텍스트 줄임(한줄)</h3>
          <div class="g_cont">
            <h5 class="mt15">.ellipsis 1줄</h5>
            <p class="ellipsis">
              보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여
            </p>
            <h5 class="mt15">.ellipsis 2줄</h5>
            <p class="ellipsis2">
              보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여
            </p>
            <h5 class="mt15">.ellipsis 3줄</h5>
            <p class="ellipsis3">
              보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여
            </p>
            <h5 class="mt15">.ellipsis 4줄</h5>
            <p class="ellipsis4">
              보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여보험상품 가입에 관하여보험상품 가입에
              관하여보험상품 가입에 관하여
            </p>
          </div>
          <h3>단 나누기</h3>
          <div class="g_cont">
            <h5 class="mt15">
              .col2 : div2개 가로값 50% 일렬로 정렬 (하위요소는 col)
            </h5>
            <div class="col2 mb10">
              <div class="col bg_red">div1</div>
              <div class="col bg_blue">div2</div>
            </div>
            <h5 class="mt15">
              .col3 : div3개 가로값 33.3% 일렬로 정렬 (하위요소는 col)
            </h5>
            <div class="col3">
              <div class="col bg_red">div1</div>
              <div class="col bg_blue">div2</div>
              <div class="col bg_sky">div3</div>
            </div>
            <h5 class="mt15">
              .col4 : div4개 가로값 25% 일렬로 정렬 (하위요소는 col)
            </h5>
            <div class="col4">
              <div class="col bg_red">div1</div>
              <div class="col bg_blue">div2</div>
              <div class="col bg_sky">div3</div>
              <div class="col bg_gray">div4</div>
            </div>
            <h5 class="mt15">
              .col5 : div5개 가로값 20% 일렬로 정렬 (하위요소는 col)
            </h5>
            <div class="col5">
              <div class="col bg_red">div1</div>
              <div class="col bg_blue">div2</div>
              <div class="col bg_sky">div3</div>
              <div class="col bg_gray">div4</div>
              <div class="col bg_blue">div5</div>
            </div>
            <h5 class="mt15">
              .colb : 요소가 2개일땐 양쪽 끝으로 정렬, 3개 이상 일땐 비율로 정렬
            </h5>
            <div class="colb mt10 mb15">
              <strong class="item bg_blue">span1</strong>
              <strong class="item bg_blue">span2</strong>
            </div>
            <div class="colb">
              <strong class="item bg_blue">span1</strong>
              <strong class="item bg_blue">span2</strong>
              <strong class="item bg_blue">span3</strong>
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="g_bx" id="g_bx1">
      <h2>🎯 button & tab 가이드</h2>
      <div class="col2">
        <div class="g_left">
          <p class="mb40">.btn : 기본 색상 파랑색, .full 클래스를 추가하면 가로사이즈 100% 적용</p>
          <div class="g_cont">
            <h3>DEFAULT BUTTONS</h3>
            <p></p>
            <button class="btn full">.btn.full</button><br>
            <button class="btn">.btn</button>
            <button class="btn sky">.btn.sky</button>
            <button class="btn red">.btn.red</button>
            <button class="btn dark">.btn.dark</button>
            <button class="btn dis">.btn.dis</button>
          </div>
          <div class="g_cont">
            <h3>OUTLINE BUTTONS</h3>
            <button class="btn l_blue">.btn.l_blue</button>
            <button class="btn l_sky">.btn.l_sky</button>
            <button class="btn l_red">.btn.l_red</button>
            <button class="btn l_dark">.btn.l_dark</button>
            <button class="btn l_dis">.btn.l_dis</button>
          </div>
          <div class="g_cont">
            <h3>ICON+BUTTONS</h3>
            <button class="btn b_refresh"><b>.btn.b_refresh</b></button>
            <button class="btn b_setting"><b>btn.b_setting</b></button>
            <button class="btn b_cancel"><b>btn.b_cancel</b></button>
            <button class="btn b_checked"><b>.btn.b_checked</b></button>
            <button class="btn b_add"><b>.btn.b_add</b></button>
            <button class="btn b_sub"><b>.btn.b_sub</b></button>
            <button class="btn b_down"><b>.btn.b_down</b></button>
          </div>
          <div class="g_cont">
            <h3>ICON BUTTONS</h3>
            <button class="btn_search type2">검색</button>
          </div>
        </div>
        <div class="g_right">
          <div class="g_cont">
            <h3>TAB</h3>
            <p class="mt20 mb5">.tabs1</p>
            <div class="tabs1">
              <button class="tab on">TAB01</button>
              <button class="tab">TAB02</button>
            </div>
            <p class="mt20 mb5">.tabs2</p>
            <div class="tabs2">
              <button class="tab on">TAB01</button>
              <button class="tab">TAB02</button>
              <button class="tab">TAB03</button>
              <button class="tab">TAB04</button>
            </div>
            <p class="mt20 mb5">.tabs3</p>
            <div class="tabs3">
              <button class="tab">TAB01</button>
              <button class="tab on">TAB02</button>
              <button class="tab">TAB03</button>
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="g_bx" id="g_bx1">
      <h2>🎯 Input 가이드</h2>
      <div class="col2">
        <div class="g_left">
          <div class="g_cont">
            <h3>Input</h3>
            <p class="mb15">
              Input components : 기본 가로사이즈 100%, data.width로 가로값 조정
              가능<br />
              id를 넣으면, label도 이용가능
            </p>
            <InputBox
              :data="{ type: 'text', id: 'id', placeholder: '입력해 주세요.' }"
            />
            <InputBox
              :data="{
                type: 'text',
                placeholder: '입력해주세요',
                width: '150px',
              }"
            />
            <InputBox
              :data="{ type: 'text', placeholder: '비활성화', disabled: true }"
            />
            <InputBox
              :data="{
                shape: 'search',
                type: 'text',
                placeholder: '검색어를 입력하세요.',
              }"
            />
            <InputBox
              :data="{
                shape: 'search',
                type: 'text',
                placeholder: '검색어를 입력하세요.',
                disabled: true,
              }"
            />
            <InputBox
              :data="{ shape: 'file', placeholder: '파일을 선택하세요.' }"
            />
            <InputBox
              :data="{
                shape: 'file',
                placeholder: '파일을 선택하세요.',
                disabled: true,
              }"
            />
          </div>
          <div class="g_cont">
            <h3>Textarea</h3>
            <p class="mb10">
              .textarea : 기본너비 :100%, 기본높이: 90px<br />
              inline-style 로 가로, 세로 조정가능
            </p>
            <textarea class="textarea" placeholder="입력해주세요!"></textarea>
            <textarea
              class="textarea"
              style="width: 50%; height: 50px"
              placeholder="입력해주세요!"
            ></textarea>
          </div>
        </div>
        <div class="g_right">
          <div class="g_cont">
            <h3>Check Box</h3>
            <p>CheckBox components</p>
            <CheckBox
              :data="{
                title: '<b>아이디기억하기</b>',
                id: '1',
              }"
            />
            <CheckBox
              :data="{
                title: '<b>비활성화</b>',
                id: '2',
                checked:true,
                disabled: true,
              }"
            />
            <h3 class="mt20">Radio Box</h3>
            <p>RadioBox components</p>
            <RadioBox
              :data="{
                title: '라디오버튼1',
                // title:'<b>라디오버튼1</b>',
                id: 1,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '라디오버튼2',
                // title:'<b>라디오버튼 비활성화</b>',
                id: 2,
                name: 'radio',
              }"
            />
            <RadioBox
              :data="{
                title: '라디오버튼 비활성화',
                // title:'<b>라디오버튼 비활성화</b>',
                id: 3,
                name: 'radio',
                disabled: true,
              }"
            />
            <RadioBox
              :data="{
                title: '라디오버튼 비활성화',
                // title:'<b>라디오버튼 비활성화</b>',
                id: 3,
                name: 'radio',
                disabled: true,
                checked:true,
              }"
            />
          </div>
          <div class="g_cont">
            <h3>Select</h3>
            <p class="mb20">
              Select components 기본 텍스트는 title에 넣어주세요. 없는 경우
              생략<br />
              가로값은 기본 100% width에 px or %으로 넣으면 조정 가능
            </p>
            <Select
              title="선택해주세요."
              :list="[
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
              ]"
            />
            <Select
              title="비활성화"
              :list="[
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
                { name: '셀렉트5' },
                { name: '셀렉트6' },
                { name: '셀렉트7' },
                { name: '셀렉트8' },
              ]"
              classNm="disabled"
            />
            <h3 class="mt20">Search Select</h3>
            <p class="mb20">
              SelectSearch components 기본 텍스트는 select, input 각각 title에
              넣어주세요. 없는 경우 생략<br />
              가로값은 기본 100% width에 px or %으로 넣으면 조정 가능
            </p>
            <SelectSearch
              title="선택해주세요."
              :list="[
                { name: '셀렉트1' },
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
                { name: '셀렉트5' },
                { name: '셀렉트6' },
                { name: '셀렉트7' },
                { name: '셀렉트8' },
              ]"
              :data="{
                placeholder: '검색어를 입력해 주세요.',
              }"
            />
            <SelectSearch
              :list="[
                { name: '셀렉트1', select: true },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
              ]"
              :data="{
                placeholder: '검색어를 입력해 주세요.',
              }"
              classNm="disabled"
            />
          </div>
        </div>
      </div>
    </article>
    <article class="g_bx" id="g_bx1">
      <h2>🎯 팝업 가이드</h2>
      <div class="col2">
        <div class="g_left">
          <div class="g_cont">
            <p class="mb15">
              * 팝업 컴포먼트 사용시 부모요소로 꼭 &lt;transition ...&gt;를 녛어주세요.<br />
              transition 태그로 에니메션 추가
            </p>
            <button class="btn" @click="fnOpenPop(1)">
              상품 등록 팝업
            </button>
            <button class="btn" @click="fnOpenPop(4)">
              상담내역 팝업
            </button>
            <button class="btn" @click="fnOpenPop(5)">
              상담내역2 팝업 <em class="new">new</em>
            </button>
            <button class="btn" @click="fnOpenPop(2)">
              경고창
            </button>
            <button class="btn" @click="fnOpenPop(3)">
              확인창
            </button>
            <transition
              name="custom-classes-transition"
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut">
              <CreatePop  v-if="$store.state.ui.popCreateState == true" :key="$store.state.ui.popCreateState"/>
              <CsPop  v-if="$store.state.ui.popCsState == true" :key="$store.state.ui.popCsState"/>
              <Cs2Pop  v-if="$store.state.ui.popCs2State == true" :key="$store.state.ui.popCsS2tate"/>
            </transition>
          </div>
        </div>
        <div class="g_right"></div>
      </div>
    </article>
  </section>
</template>

<script>
import InputBox from "~/components/common/Input";
import CheckBox from "~/components/common/CheckBox";
import RadioBox from "~/components/common/RadioBox";
import Select from "~/components/common/Select";
import SelectSearch from "~/components/common/SelectSearch";
import CreatePop from "~/components/popup/Create";
import CsPop from "~/components/popup/Cs";
import Cs2Pop from "~/components/popup/Cs2";
import PopupView from "~/components/popup/PopupView";

export default {
  data() {
    return {
      popup: false,
    };
  },
  components: {
    InputBox,
    CheckBox,
    RadioBox,
    Select,
    SelectSearch,
    CreatePop,
    CsPop,
    Cs2Pop,
    PopupView,
  },
  mounted(){
  },
  methods: {
    fnOpenPop(num) {
      // this.$store.dispatch('ui/setMenuState', false);
      if(num == 1) {
        this.$store.dispatch('ui/setPopCreateState', true);
      }else if(num==2){
        let data = {
          state:true,
          type: "alert",
          title: "Alert",
          msg: "<p>텍스트를  <br/>입력해주세요.</p>",
          btn:{
            name:"확인",
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      }else if(num==3){
        let data  = {
          state:true,
          type: 'confirm',
          title: "Confirm",
          msg: "<p>텍스트를  <br/>입력해주세요.</p>",
          btn:{
            name:"save"
          }
        };
        this.$store.dispatch('ui/setPopAlertData', data);
      }else if(num==4){
        this.$store.dispatch('ui/setPopCsState', true);
      }else if(num==5){
        this.$store.dispatch('ui/setPopCs2State', true);
      }
    },
  },
};
</script>

