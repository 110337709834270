<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="item_ipt">
          <h4>상품번호</h4>
          <div class="i_wrap mt10">{{ orderDetail.prdc }}</div>
        </li>
        <li>
          <h4>이름</h4>
          <div class="i_wrap">
            <span>{{ orderDetail.prdc_nm }}</span>
          </div>
        </li>
        <li>
          <h4>상품의 형태</h4>
          <div class="i_wrap">
            <span>{{ fnGetCodeName('common', 'PT00', orderDetail.prdc_type) }}</span>
          </div>
        </li>
        <li>
          <h4>구성된 특허</h4>
          <div class="list_patent ml5">
            <ItemPatent v-for="el in patns" :key="el.patn" :data="{ id: el.patn, tit: el.patnTitle, disabled: true }" />
          </div>
        </li>
        <li class="item_ipt">
          <h4>이미지</h4>
          <div class="i_wrap">
            <div class="bx_img">
              <img :src="orderDetail.img_url" class="img" alt="첨부한 이미지" />
            </div>
          </div>
        </li>
        <li>
          <h4>가격</h4>
          <div class="i_wrap">
            <span>{{ orderDetail.prdc_price }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import ItemPatent from '~/components/common/ItemPatent.vue';

export default {
  props: ['orderDetail'],
  components: {
    ItemPatent,
  },
  data() {
    return {
      patns: [],
    };
  },

  methods: {
    fnGetCodeName(codeGrp1, codeGrp2, code) {
      const list = this.$store.state.openIp[codeGrp1][codeGrp2];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },

    async fnGetPatns() {
      if (this.orderDetail.patn_no) {
        let patnList = [];
        let patnNos = this.orderDetail.patn_no.split(', ');
        for (const el of patnNos) {
          const { result, data } = await this.$store.dispatch('openIp/patnStore/fetchPatnDetail', el);
          if (result) {
            patnList.push({ patn: data.contents.patnDetail.patn, patnTitle: data.contents.patnDetail.patnTitle });
          }
        }
        this.patns = patnList;
      }
    },
  },
  watch: {
    orderDetail(value) {
      this.fnGetPatns();
    },
  },
};
</script>

<style></style>
