<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <ul class="form_list type2">
        <li class="half">
          <h4>회사명</h4>
          <div class="i_wrap"><span>{{customer.compNm}}</span></div>
        </li>
        <li class="half">
          <h4>대표자명</h4>
          <div class="i_wrap"><span>{{customer.prstNm}}</span></div>
        </li>
        <li class="half">
          <h4>이메일</h4>
          <div class="i_wrap"><span>{{customer.clientEmail}}</span></div>
        </li>
        <li class="half">
          <h4>연락처</h4>
          <div class="i_wrap"><span>{{customer.clientTel}}</span></div>
        </li>
        <li class="half">
          <h4>설립일</h4>
          <div class="i_wrap"><span>{{customer.fndDt}}</span></div>
        </li>
        <li class="half">
          <h4>업종</h4>
          <div class="i_wrap"><span>{{customer.sector}}</span></div>
        </li>
        <li>
          <h4>소재지</h4>
          <div class="i_wrap"><span>{{customer.korAddr}}</span></div>
        </li>
        <li class="item_radio">
          <h4>인증보유현황</h4>
          <div class="i_wrap">
            <RadioBox
              label='연구소'
              id='lab_yn_1'
              name='lab_yn'
              :value.sync="labYn"
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='연구전단부서'
              id='dept_yn_2'
              name='dept_yn'
              :value.sync="deptYn"
              :defaultValue="1"
              disabled
              />
            <RadioBox
              label='벤처인증'
              id='dept_yn_3'
              name='dept_yn'
              :value.sync="ventureYn"
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='이노비즈'
              id='innobiz_yn_4'
              name='innobiz_yn'
              :value.sync="innobizYn"
              :defaultValue="1"
              disabled
            />
            <RadioBox
              label='미보유'
              id='no_5'
              name='no'
              :value.sync="allYn"
              :defaultValue="1"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>대표자 연령</h4>
          <div class="i_wrap">
            <RadioBox
              label='만 39세 미만'
              id='prst_age_cd_1'
              name='prst_age_cd'
              :value.sync="customer.prstAgeCd"
              :defaultValue="'ETC301'"
              disabled
            />
            <RadioBox
              label='만 39세 이상'
              id='prst_age_cd_2'
              name='prst_age_cd'
              :value.sync="customer.prstAgeCd"
              :defaultValue="'ETC302'"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>직원수</h4>
          <div class="i_wrap">
            <RadioBox
              label='5인미만'
              id='emp_num_cd_1'
              name='emp_num_cd'
              :value.sync="customer.empNumCd"
              :defaultValue="'ETC101'"
              disabled
            />
            <RadioBox
              label='5인 이상 ~ 10인 미만'
              id='emp_num_cd_2'
              name='emp_num_cd'
              :value.sync="customer.empNumCd"
              :defaultValue="'ETC102'"
              disabled
            />
            <RadioBox
              label='10인 이상'
              id='emp_num_cd_3'
              name='emp_num_cd'
              :value.sync="customer.empNumCd"
              :defaultValue="'ETC103'"
              disabled
            />
            <RadioBox
              label='잘 모르겠어요'
              id='emp_num_cd_4'
              name='emp_num_cd'
              :value.sync="customer.empNumCd"
              :defaultValue="'ETC104'"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>고용계획</h4>
          <div class="i_wrap">
            <RadioBox
              label='네, 있어요'
              id='hire_cd_1'
              name='hire_cd'
              :value.sync="customer.hireCd"
              :defaultValue="'ETC201'"
              disabled
            />
            <RadioBox
              label='잘 모르겠어요'
              id='hire_cd_2'
              name='hire_cd'
              :value.sync="customer.hireCd"
              :defaultValue="'ETC202'"
              disabled
            />
          </div>
        </li>
        <li class="item_radio">
          <h4>특허추천</h4>
          <div class="i_wrap">
            <RadioBox
              label='네, 추천받고 싶어요'
              id='tech_rcm_cd_1'
              name='tech_rcm_cd'
              :value.sync="customer.techRcmCd"
              :defaultValue="'ETC401'"
              disabled
            />
            <RadioBox
              label='잘 모르겠어요'
              id='tech_rcm_cd_2'
              name='tech_rcm_cd'
              :value.sync="customer.techRcmCd"
              :defaultValue="'ETC402'"
              disabled
            />
          </div>
        </li>
         <li>
          <h4>기업 신용등급</h4>
          <div class="i_wrap"><span>{{codeCheck1(customer.ccrCd)}}</span></div>
        </li>
        <li>
          <h4>대표자<br/>신용등급</h4>
          <div class="i_wrap" style="margin-top: 8px;"><span>{{codeCheck2(customer.rcrCd)}}</span></div>
        </li>
        <li class="item_skill">
          <h4>기술보유현황</h4>
          <div class="i_wrap">
            <div class="bx_line">
              <h5>보유특허수</h5>
              <strong class="txt_org">{{customer.patnCnt}}개</strong>
            </div>
            <div class="bx_line">
              <h5>특허가치</h5>
              <strong class="txt_red">{{fnPoint(customer.patnValue)}}</strong>
            </div>
            <div class="bx_line">
              <h5>기술등급</h5>
              <strong class="txt_red">{{customer.techRate}}</strong>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="right bg_gray">
      <div class="tabs2">
        <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">정책자금</button>
        <button :class="{ tab: true, on: on == 'tab2' }" @click="() => (this.on = 'tab2')">금융상품</button>
        <button :class="{ tab: true, on: on == 'tab3' }" @click="() => (this.on = 'tab3')">특허분석</button>
        <button :class="{ tab: true, on: on == 'tab4' }" @click="() => (this.on = 'tab4')">특허추천</button>
      </div>
      <CustomerPrdcTab1 v-if="this.on=='tab1'" :customer="customer"/>
      <CustomerPrdcTab2 v-if="this.on=='tab2'" :customer="customer"/>
      <CustomerPrdcTab3 v-if="this.on=='tab3'" :customer="customer"/>
      <CustomerPrdcTab4 v-if="this.on=='tab4'" :customer="customer"/>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import RadioBox from '~/components/common/RadioBox';
import SelectSearch from "~/components/common/SelectSearch";
import CustomerPrdcTab1 from "./CustomerPrdcTab1.vue";
import CustomerPrdcTab2 from "./CustomerPrdcTab2.vue";
import CustomerPrdcTab3 from "./CustomerPrdcTab3.vue";
import CustomerPrdcTab4 from "./CustomerPrdcTab4.vue";

import customersService from "~/service/allOk/customersService";

export default {
  name: 'CustomerTab1',
  data() {
    return {
      customer: {},
      consultant_id: null,
      llResult:[],
      lookup_id: '',
      files:{},
      uploadCode: null,
      docsGroup : [
        { title: '정책자금 검토 필수서류(필수)', codeGrp: 'FA0100'},
        { title: 'TCB 가평가 필요서류 (필수)', codeGrp: 'FA0200'},
        { title: '정책자금 검토 부가서류 (선택)', codeGrp: 'FA0300'}
      ],
      form: {
        technology: '',
      },
      on: 'tab1'
    };
  },
  props: ['id'],
  components: {
    InputBox,
    ItemPatent,
    Select,
    RadioBox,
    SelectSearch,
    CustomerPrdcTab1,
    CustomerPrdcTab2,
    CustomerPrdcTab3,
    CustomerPrdcTab4,
  },
  computed: {
    labYn(){
      if(this.customer.labYn){
        return 1
      }
      return 0
    },
    deptYn(){
      if(this.customer.deptYn){
        return 1
      }
      return 0
    },
    ventureYn(){
      if(this.customer.ventureYn){
        return 1
      }
      return 0
    },
    innobizYn(){
      if(this.customer.innobizYn){
        return 1
      }
      return 0
    },
    allYn() {
      return (!this.customer.labYn&&!this.customer.innobizYn&&!this.customer.deptYn&&!this.customer.ventureYn)? 1 : 0
    },
    hasFiles(){
      let count=0;
      for(let f in this.files){
        count ++;
      }
      return count>0 ? true : false;
    },
    fileStatus(){
      if( this.customer.docs_submit_dt ){
        return '[제출 완료]';
      } else if ( this.files ){
        let fileCnt = 0;
        for( let f in this.files){
          fileCnt++;
        }
        if(fileCnt>0)
          return '[임시저장]';
      }
      return null
    },
  },
  async beforeMount() {
    const result = await customersService.fetchCustomerDetail(this.$axios, this.id)
    const customer = result.data.data.contents;
    this.$store.commit('allOk/customersStore/UPDATE_COMPANYINFO', customer);
    if(customer){
      this.customer = customer;
    } else {
      this.customer = {}
    }
  },
  methods: {
    fnPoint(payload){
      if(payload)
        return payload.toFixed(2);
      return payload
    },
    fnClosePop() {
      this.$store.dispatch('ui/setPopCsState', false);
    },

    fnValueChange({name,value}){
      this.customer[name]=value;
    },
    fnInputFiles(grpCd){
      return this.$store.state.allOk.allOkStore.codeGroup[grpCd];
    },
    type(){
      return this.$store.getters["allOk/customersStore/productType"];
    },
    _alert(title, msg){
      let data = {
        state:true,
        type: "alert",
        title: title,
        msg: msg,
        btn:{
          name:"확인",
        }
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _confirm(title, msg, btn){
      let data  = {
        state:true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
    _closePop(){
      this.$store.dispatch('ui/setPopAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    },
    codeCheck1(payload){
      const codeList = this.$store.state.allOk.allOkStore.codeGroup['ETC500']
      return this.checkFor(payload, codeList)
    },
    codeCheck2(payload){
      const codeList = this.$store.state.allOk.allOkStore.codeGroup['ETC600']
      return this.checkFor(payload, codeList)
    },
    checkFor(payload, codeList) {
      if(payload){
        for(let i=0; i<codeList.length; i++){
          if(codeList[i].cd===payload){
            return codeList[i].nm
          }
        }
      }
      return '-'
    }
  },
};
</script>
