<template>
  <section class="container guide">
    <h1>Grid Test</h1>
    <!-- 그리드는 무조건 클라이언트 사이드에서 작동되어야 함 -->
    <client-only>
      <grid
        ref="tuiGrid"
        :data="gridProps.data"
        :columns="gridProps.columns"
        :useClientSort="gridProps.useClientSort"
        :pageOptions="pageOptions"
        @click="onClick"
        @afterFilter="onAfterFilter"
      />
    </client-only>
  </section>
</template>

<script>
import GridFilter from "~/util/toastUi/GridFilter";
export default {
  name: 'sample-grid',
  layout:'none',
  data(){
    return {
      gridProps:{
      },
      pageOptions:{
        perPage: 5, // 한 페이지에 보여줄 rows의 갯징수
      },
      customGridFilter:null,
    }
  },
  methods:{
    // DEFAULT
    onClick(e){
      const {targetType, rowKey} = e;
      if(targetType === 'cell'){
        this.fnRowClickEvent(rowKey)
      } else if ( targetType === 'columnHeader' ){
        this.customGridFilter.filterOpenBtnBinder(e);
      } else {
        this.customGridFilter.filterApplyBtnBinder(e);
      }
    },
    // DEFAULT
    onAfterFilter(e){
      this.customGridFilter.onAfterFilter(e);
    },
    fnRowClickEvent(rowKey){
      const currentRowData = this.$refs.tuiGrid.invoke('getRow', rowKey)
      // TODO - 로우 선택시 처리 이벤트
    }
  },
  created() {
    this.gridProps = {
      // 그리드 API 연동
      // 페이지 네이션은 자동으로 처리
      // Request Sample
      //    page : number
      //    perPage : number
      //    name : value
      // Response Sample
      //    /dummy/grindResponseSample.json 을 참조할 것
      // https://github.com/nhn/tui.grid/blob/master/packages/toast-ui.grid/docs/en/data-source.md
      data: {
        api: {
          readData: { url: '/dummy/gridResponseSample.json', method: 'GET' }
        }
      },
      columns: [
        {
          header: 'Name',
          name: 'name',
          filter: {
            type:'text',
            showApplyBtn: true
          }
        },
        {
          header: 'Artist',
          name: 'artist',
          filter: {
            type:'text',
            showApplyBtn: true,
          }
        },
        {
          header: 'Sequence',
          name: 'seq',
          sortable: true,
          sortingType: 'asc'
        },
        {
          header: 'Sequence2',
          name: 'seq2',
          sortable: true,
          sortingType: 'asc'
        }
      ],
      useClientSort: false
    };
  },
  // DEFAULT
  mounted() {
    this.customGridFilter = new GridFilter(this.$refs, 'tuiGrid');
  }
}

</script>
