<template>
  <div class="popup confirm" v-if="data !== null">
    <div class="p_wrap">
      <div class="p_top">
        <h3 v-if="data.title !== undefined" v-html="data.title"></h3>
        <button class="btn_close" @click="_cancel">닫기</button>
      </div>
      <div class="p_container">
        <div class="msg" v-html="data.msg"></div>
      </div>
      <div class="p_bottom">
        <button @click="_confirm" class="btn">
          <b>{{ data.btn.name }}</b>
        </button>
        <button @click="_cancel" class="btn dark"><b>닫기</b></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },

  mounted() {
    this.data = this.$store.state.ui.popConfirmData;
    //라우터 전환될 때 이벤트
    $nuxt.$router.beforeEach((to, from, next) => {
      this.fnClosePop();
      next();
    });
  },
  methods: {
    _confirm() {
      this.$emit('confirm', this.data.id);
      this.fnClosePop();
    },

    _cancel() {
      this.$emit('cancel', this.data.id);
      this.fnClosePop();
    },

    fnClosePop() {
      this.$store.dispatch('ui/setPopConfirmData', {
        state: false,
        id: null,
        title: null,
        msg: null,
        btn: {
          name: null,
        },
      });
    },
  },
};
</script>
