<template>
  <div class="search_date" :class="{on:onOff}">
    <div class="s_cont">
      <div class="item">
        <div class="bx">
          <Select defaultName="년" :itemList="fromYearList" classNm="ipt_year" :value.sync="from.year" />
          <Select defaultName="월" :itemList="fromMonthList" classNm="ipt_month" :value.sync="from.month" />
          <Select defaultName="일" :itemList="fromDateList" classNm="ipt_day" :value.sync="from.date" />
          <Select defaultName="시" :itemList="fromHourList" classNm="ipt_day" :value.sync="from.hour" />
          <Select defaultName="분" :itemList="fromMinList" classNm="ipt_day" :value.sync="from.min" />
        </div>
      </div>
    </div>
    <div class="colb mr5 mb5">
      <div class="col">
      </div>
      <div class="col">
        <button class="btn" @click="(e)=>{
          e.stopPropagation();
          this.fnPropagationDate();
        }">
          확인
        </button>
        <button class="btn l_gray" @click="(e)=>{
          e.stopPropagation();
          //this.fnRestore();
          this.$emit('cancel');
        }">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "~/components/common/Select";

export default {
  name:"datePicTooltipSingle",
  props:{
    onOff: {
      type: Boolean,
      default: false
    },
    defaultDate: {
      type: String,
    }
  },
  components: {
    Select,
  },
  data(){
    return {
      minYear: 2021,
      minMonth: 1,
      maxMonth: 12,
      minDate: 1,
      today: {
        year:null,
        month:null,
        date:null
      },
      from:{
        year:null,
        month:null,
        date:null,
        hour:null,
        min:null,
      },
    }
  },
  computed: {
    fromYearList(){
      const min = this.minYear;
      const max = this.today.year;
      const current = this.from.year;
      return this.fnSelectList(min, max, current, '년');
    },
    fromMonthList(){
      const min = this.minMonth;
      const max = this.fnFromMaxMonth();
      const current = this.from.month;
      return this.fnSelectList(min, max, current, '월');
    },
    fromDateList(){
      const min = this.minDate;
      const max = this.fnFromMaxDate();
      const current = this.from.date;
      return this.fnSelectList(min, max, current, '일');
    },
    fromHourList(){
      return this.fnSelectList(0, 23, this.from.hour, '시');
    },
    fromMinList(){
      return this.fnSelectList(0, 59, this.from.min, '분');
    }
  },
  mounted() {
    this.fnResetSetDate();
    this.fnPropagationDate();
  },
  methods: {
    fnResetSetDate() {
      const today = new Date();
      this.today.year = today.getFullYear();
      this.today.month = today.getMonth() + 1;
      this.today.date = today.getDate();

      if (this.defaultDate && this.defaultDate.length>10) {
        const data = this.fnParseDefaultDate();
        this.from.year = data.year;
        this.from.month = data.month;
        this.from.date = data.date;
        this.from.hour = data.hour;
        this.from.min = data.min;
      } else {
        this.from.year = this.today.year;
        this.from.month = this.today.month;
        this.from.date = this.today.date;
        this.from.hour = today.getHours();
        this.from.min = today.getMinutes();
      }

    },
    fnParseDefaultDate(){
      let date_time = this.defaultDate.split(" ");
      let _date = date_time[0].split("-");
      let _time = date_time[1].split(":");
      return {
        year : _date[0],
        month : _date[1],
        date : _date[2],
        hour : _time[0],
        min : _time[1],
      }
    },
    fnPropagationDate(){
      if(this.from.year){
        this.$emit('date', {
          fromDate: `${this.from.year}-${this.$fill0(this.from.month)}-${this.$fill0(this.from.date)} ${this.$fill0(this.from.hour)}:${this.$fill0(this.from.min)}`,
        });
      }
    },
    fnFromMaxMonth(){
      if(this.from){
        return this.from.year < this.today.year ? 12 : this.today.month;
      }
      return 12;
    },
    fnFromMaxDate(){
      if(this.from)
        return (this.from.year === this.today.year && this.from.month === this.today.month)
          ? this.today.date
          : new Date(this.from.year, this.from.month, 0).getDate();
      return 31;
    },
    fnSelectList(min, max, current, unit){
      let list = [];
      for(let a = min; a <= max; a++){
        list.push({
          name:a+' '+unit,
          value:a,
          select:a===current
        })
      }
      return list;
    },
  },
  watch:{
    onOff(nv,ov){
      if(nv === true){
        this.fnResetSetDate();
      }
    }
  }
};
</script>
