<template>
  <div class="c_contents contents_col2">
    <div class="left">
      <FileList :id="id" :customerId="customerId" />
    </div>
    <div class="right">
      <Tech :id="id"/>
    </div>
  </div>
</template>

<script>
import FileList from "./CustomerTab/FileList";
import Tech from "./CustomerTab/Tech";
export default {
  name: 'CustomerTab5',
  props: ['id', 'customerId'],
  components: {
    FileList, Tech,
  },
};
</script>
