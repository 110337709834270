<template>
  <div>
    <div class="covera" style="page-break-before: always">
      <div class="container">
        <div class="inner">
          <div class="logo">(주)유니콘랜치</div>
          <h2>기업기술금융 및 정책자금 분석 레포트</h2>
          <h1>{{ customer.comp_nm }}</h1>
          <ul>
            <li><strong>REPORT 1</strong> 정책자금 및 금융권 진단결과</li>
            <li><strong>REPORT 2</strong> TCB기술금융 진단결과</li>
            <li><strong>REPORT 3</strong> IP금융 및 특허가치 진단결과</li>
          </ul>
          <!-- <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/main.png" alt="메인이미지"> -->
          <div class="img"></div>
        </div>
        <div class="footer">
          <div class="top"></div>
          <p>유의사항</p>
          <ul>
            <li>1. 본 리포트에 포함된 모든 자료에 대한 저작권은 (주)유니콘랜치에게 있습니다.</li>
            <li>2. 당사의 사전 승인없이 리포트 전체 또는 일부를 복사, 복제, 배포, 재출판, 변형, 표시, 게시하거나 전송할 수 없습니다.</li>
            <li>3. 문의사항: <strong>(주)유니콘랜치</strong> 02.2088.1271</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="report" style="page-break-before: always">
      <div class="header">
        <div class="h_inner">
          <h1>기업 기술금융 분석 및 정책자금 분석레포트</h1>
          <div class="h_right">
            <p>UNICORNRANCH.CO.KR</p>
            <span><em>1</em></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="inner">
          <article class="area">
            <h1 class="tit"><em>REPORT 1.</em> 정책자금 및 금융권 진단결과</h1>
            <h3>기업개요</h3>
            <table>
              <tbody>
              <tr>
                <td class="tit">기업명</td>
                <td><strong>{{customer.comp_nm}}</strong></td>
                <td class="tit">대표자명</td>
                <td>{{customer.prst_nm}}</td>
                <td class="tit">설립일</td>
                <td>{{customer.fnd_dt}}</td>
              </tr>
              <tr>
                <td class="tit">업종</td>
                <td colspan="5">{{customer.sector}}</td>
              </tr>
              <tr>
                <td class="tit">주소</td>
                <td colspan="5">{{customer.kor_addr}}</td>
              </tr>
              <tr>
                <td class="tit">인증보유현황</td>
                <td colspan="5">{{yn}}</td>
              </tr>
              <tr>
                <td class="tit">특허기술<br />보유현황</td>
                <td class="boxs" colspan="5">
                  <div class="box"><span>보유특허수</span>{{customer.patn_cnt}}개</div>
                  <div class="box"><span>특허자산가치</span>{{customer.patn_value? fnPoint(customer.patn_value) : '0'}}</div>
                  <div class="box"><span>기술등급</span>{{customer.tech_rate}}</div>
                </td>
              </tr>
              </tbody>
            </table>
          </article>
          <article class="area a2">
            <h3>정책자금 진단결과</h3>
            <p><strong>{{customer.comp_nm}}</strong>에서 신청 가능한 정책자금 분석결과입니다.<br/>
              (해당 정책자금은 기업조회를 통한 단순 결과이며 재무상태에 따라 변동될 수 있습니다)</p>
            <div class="a_contant" v-for="(item, i) in llResult" :key="i">
              <div class="a_inner">
                <div class="list">
                  <img :src="`/IMG/${item.loan_prdc_no}.png`" alt="진단결과 이미지">
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="report r1_2" style="page-break-before: always">
      <div class="header">
        <div class="h_inner">
          <h1>기업 기술금융 분석 및 정책자금 분석레포트</h1>
          <div class="h_right">
            <p>UNICORNRANCH.CO.KR</p>
            <span><em>2</em></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="inner">
          <article class="area a2">
            <h3>금융상품 진단결과</h3>
            <p>{{customer.comp_nm}}에서 신청 가능한 정책자금 분석결과입니다.<br/>(해당 정책자금은 기업조회를 통한 단순 결과이며 재무상태에 따라 변동될 수 있습니다)</p>
            <div class="a_contant">
              <h4>제 1금융권</h4>
              <div class="a_inner">
                <div class="list">
                  <div class="top">
                    <div class="t_tit">
                      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/KB.png"
                        alt="국민은행">
                      <h3>국민은행- 다드림 기업신용대출</h3>
                    </div>
                  </div>
                  <div class="cont">
                    <dl>
                      <dt>금리</dt>
                      <dd>3.81%~</dd>
                      <dt>한도</dt>
                      <dd>최대 5,000만원</dd>
                    </dl>
                  </div>
                </div>
                <div class="list">
                  <div class="top">
                    <div class="t_tit">
                      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/IBK.png"
                        alt="기업은행">
                      <h3>기업은행 - 기업저금리 안심대출</h3>
                    </div>
                  </div>
                  <div class="cont">
                    <dl>
                      <dt>금리</dt>
                      <dd>4.71%~</dd>
                      <dt>한도</dt>
                      <dd>최대 1억원</dd>
                    </dl>
                  </div>
                </div>
                <div class="list">
                  <div class="top">
                    <div class="t_tit">
                      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/Woori.png"
                        alt="우리은행">
                      <h3>우리은행 - 사업자 단기 대출</h3>
                    </div>
                  </div>
                  <div class="cont">
                    <dl>
                      <dt>금리</dt>
                      <dd>5.81%~</dd>
                      <dt>한도</dt>
                      <dd>최대 2억원</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div class="a_contant">
              <h4>제 2금융권</h4>
              <div class="a_inner">
                <div class="list">
                  <div class="top">
                    <div class="t_tit">
                      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/Sh.png"
                        alt="수협은행">
                      <h3>수협은행 - Sh더드림 기업신용대출</h3>
                    </div>
                  </div>
                  <div class="cont">
                    <dl>
                      <dt>금리</dt>
                      <dd>4.81%~</dd>
                      <dt>한도</dt>
                      <dd>최대 3,000만원</dd>
                    </dl>
                  </div>
                </div>
                <div class="list">
                  <div class="top">
                    <div class="t_tit">
                      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/Shinhan.png"
                        alt="신한저축은행">
                      <h3>신한저축은행 - 신한 사업자전용 대출</h3>
                    </div>
                  </div>
                  <div class="cont">
                    <dl>
                      <dt>금리</dt>
                      <dd>6.81%~</dd>
                      <dt>한도</dt>
                      <dd>최대 1억원</dd>
                    </dl>
                  </div>
                </div>
                <div class="list">
                  <div class="top">
                    <div class="t_tit">
                      <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/OK.png"
                        alt="OK 저축은행">
                      <h3>OK 저축은행 - 사업자 파이팅 대출</h3>
                    </div>
                  </div>
                  <div class="cont">
                    <dl>
                      <dt>금리</dt>
                      <dd>5.81%~</dd>
                      <dt>한도</dt>
                      <dd>최대 2억원</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="report r2"  style="page-break-before: always">
      <div class="header">
        <div class="h_inner">
          <h1>기업 기술금융 분석 및 정책자금 분석레포트</h1>
          <div class="h_right">
            <p>UNICORNRANCH.CO.KR</p>
            <span><em>3</em></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="inner">
          <article class="area">
            <h1 class="tit"><em>REPORT 2.</em> TCB기술금융 진단결과</h1>
            <div class="info">
              <ul>
                <li>
                  <h4>TCB 기술금융이란?</h4>
                  <p>매출, 신용, 담보권이 부족한 기업에게 기술력을 담보로 <strong class="txt_red">금리와 한도 우대</strong>를 주는 제도입니다.</p>
                </li>
                <li>
                  <h4>TCB 기술신용평가란?</h4>
                  <p>기술신용평가란 뛰어난 기술력을 가진 기업에게 보다 좋은 조건으로 금융서비스를 지원하기 위해 도입된 제도입니다.<br />
                    기술력을 중심으로 기업의 기술성, 권리성, 시장성, 사업성 등을 종합적으로 분석하고 평가한 것으로서 기술사업화를 통하여 발생할 수 있는<br />
                    기술의 경제적 가치를 가액, 등급, 점수 등으로 나타낸 지표입니다. TCB 대출을 위해서는 기술신용평가를 선행해야 합니다.</p>
                </li>
                <li>
                  <h4>TCB 대출이란?</h4>
                  <p>은행이 대출을 결정할 때 <strong class="txt_red">기업의 재무정보 외 기술신용평가를 통한 기술정보를 참고해 대출을 허가하는 것</strong>을 TCB 대출이라
                    합니다.<br />
                    정책금융 신청 시 기술신용평가는 의무화 되어있으며 평가 결과에 따라 최종적으로 대출이 이루어질지 여부는 은행이 자율적으로 결정합니다.</p>
                </li>
                <li>
                  <h4>TCB 대출 평가절차</h4>
                  <div class="steps">
                    <div class="step">
                      <div class="sign">STEP1.</div>
                      <p>자금을 지원하는<br />금융기관에서 TCB 기관으로<br />기술신용평가 신청</p>
                    </div>
                    <div class="step">
                      <div class="sign">STEP2.</div>
                      <p>금융기관과<br />TCB간의<br />상호협의</p>
                    </div>
                    <div class="step">
                      <div class="sign">STEP3.</div>
                      <p>TCB 시행 기관이<br />기업에게 평가안내<br />및 서류 요청</p>
                    </div>
                    <div class="step">
                      <div class="sign">STEP4.</div>
                      <p>제출서류 확인,<br />현장실사 완료</p>
                    </div>
                    <div class="step">
                      <div class="sign">STEP5.</div>
                      <p>기업에<br />기술신용평가<br />결과 제공</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <h3>기술평가 개요&#60;{{customer.comp_nm}}&#62;</h3>
            <table>
              <colgroup>
                <col width="180px"/>
                <col />
                <col width="180px"/>
                <col />
              </colgroup>
              <thead>
              <tr>
                <th>구분</th>
                <th colspan="3">세부내용</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="tit">기술인력 보유현황</td>
                <td>{{dataCheck(customerReport.engineer)}}명</td>
                <td class="tit">생산인력 보유현황</td>
                <td>{{dataCheck(customerReport.producer)}}명</td>
              </tr>
              </tbody>
            </table>
            <table class="t_type t1">
              <colgroup>
                <col width="180px"/>
              </colgroup>
              <tbody>
              <tr>
                <th rowspan="2">주요 매출정보<br />(직진 3개년, 백만 원)</th>
                <th>구분</th>
                <th>{{year-1}}년</th>
                <th>{{year-2}}년</th>
                <th colspan="4">{{year-3}}년</th>
              </tr>
              <tr>
                <td><strong>매출액</strong></td>
                <td>{{dataCheck(customerReport.lastYear)}} 백만 원</td>
                <td>{{dataCheck(customerReport.lastYear2)}} 백만 원</td>
                <td colspan="4">{{dataCheck(customerReport.lastYear3)}} 백만 원</td>
              </tr>
              </tbody>
            </table>
            <table class="t_type">
              <colgroup>
                <col width="180px"/>
              </colgroup>
              <tbody>
              <tr>
                <th rowspan="2">지식재산권<br/>보유현황</th>
                <th>특허등록</th>
                <th>실용신안등록</th>
                <th>디자인등록</th>
                <th>상표권등록</th>
                <th>프로그램등록</th>
                <th>저작권</th>
              </tr>
              <tr>
                <td>{{dataCheck(customerReport.patent)}}건</td>
                <td>{{dataCheck(customerReport.utilityModel)}}건</td>
                <td>{{dataCheck(customerReport.design)}}건</td>
                <td>{{dataCheck(customerReport.trademark)}}건</td>
                <td>{{dataCheck(customerReport.program)}}건</td>
                <td>{{dataCheck(customerReport.copyright)}}건</td>
              </tr>
              </tbody>
            </table>
            <table class="t_type">
              <colgroup>
                <col width="180px"/>
              </colgroup>
              <tbody>
              <tr>
                <th rowspan="2">인증보유현황</th>
                <th>연구소</th>
                <th>연구전담부서</th>
                <th>벤처인증</th>
                <th>이노비즈</th>
              </tr>
              <tr>
                <td>{{certificationYn(customer.lab_yn)}}</td>
                <td>{{certificationYn(customer.dept_yn)}}</td>
                <td>{{certificationYn(customer.venture_yn)}}</td>
                <td>{{certificationYn(customer.innobiz_yn)}}</td>
              </tr>
              </tbody>
            </table>
          </article>
          <article class="area a2">
            <div class="top">
              <h4>기술평가 예비등급&#60;{{customer.comp_nm}}&#62;</h4>
              <!-- <dl>
                <dt>발급일</dt>

                22.12.23 발급일, 재무기준일 제거
                <dd class="mr10">{{customerReport.issDate}}</dd>

                <dd class="mr10">&nbsp;</dd>
                <dt>재무기준일</dt>

                <dd>{{customerReport.financeDate}}</dd>

                <dd class="mr10">&nbsp;</dd>
              </dl> -->
            </div>
            <div class="a_contant">
              <p>{{dataCheck(customerReport.technology)}}</p>
            </div>
          </article>
          <article class="area a2">
            <h4>기술평가 예비등급 정의</h4>
            <!-- <table class="t_type two">
              <colgroup>
                <col width="180px"/>
                <col width="890px"/>
              </colgroup>
              <thead>
              <tr>
                <th>등급</th>
                <th colspan="5">정의</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="tit">T-H</td>
                <td>기술 및 아이디어 경쟁력과 기술사업화 능력이 양호하며 미래 수익창출 가능성이 다소 높은 기업</td>
              </tr>
              <tr>
                <td class="tit">T-M</td>
                <td>기술 및 아이디어 경쟁력과 기술사업화 능력이 보통이며 미래 수익창출 가능성이 다소 높은 기업</td>
              </tr>
              <tr>
                <td class="tit">T-L</td>
                <td>기술 및 아이디어 경쟁력과 기술사업화 능력이 미흡하며 미래 수익창출 가능성이 낮은 기업</td>
              </tr>
              </tbody>
            </table> -->
            <!-- <ul>
              <li>본 보고서는 [신용정보의 이용 및 보호에 관한 법률]에 의거 작성된 것으로 기술평가 예비등급 유효기간은 발급일로부터 1년이며, 발급일 이후 기업의 변동 내용은반영되지 않을 수도 있습니다.</li>
              <li>본 보고서 내의 등급은 신용조회 업무에 의한 등급으로 홈페이지, 보도자료 등을 통해 외부에 제공할 수 없습니다.</li>
            </ul> -->
            <table class="t_type t2">
            <colgroup>
              <col width="150px" />
            </colgroup>
            <thead>
              <tr>
                <th class="tit">등급</th>
                <th>T-H</th>
                <th>T-M</th>
                <th>T-L</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tit">정의</td>
                <td>기술 및 아이디어 경쟁력과 기술사업화 능력이 양호하며 미래 수익창출<br />가능성이 다소 높은 기업</td>
                <td>기술 및 아이디어 경쟁력과 기술사업화 능력이 보통이며 미래 수익창출<br />가능성이 다소 높은 기업</td>
                <td>기술 및 아이디어 경쟁력과 기술사업화 능력이 미흡하며 미래 수익창출<br />가능성이 낮은 기업</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>본 보고서 내의 등급은 신용조회 업무에 의한 등급으로 홈페이지, 보도자료 등을 통해 외부에 제공할 수 없습니다.</li>
          </ul>
          </article>
        </div>
      </div>
    </div>
    <div class="report r3" style="page-break-before: always">
      <div class="header">
        <div class="h_inner">
          <h1>기업 기술금융 분석 및 정책자금 분석레포트</h1>
          <div class="h_right">
            <p>UNICORNRANCH.CO.KR</p>
            <span><em>4</em></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="inner">
          <article class="area">
            <h1 class="tit"><em>REPORT 3.</em> IP금융 및 특허가치 진단결과</h1>
            <h4>지식재산금융 (IP금융)</h4>
            <p>지식재산금융 (IP Financing), 기업이 보유한 특허권, 실용신안권, 디자인권, 상표권 등 지식재산(IP: Intellectual Property)을 이용해 수익을<br />
              발생시키거나 향후 사업화와 시장 점유가 가능하도록 자금을 지원하는 금융 활동입니다. 지식재산(IP)에 대한 가액·등급을 평가하고, 금융기관이<br />
              지식재산(IP)을 기반으로 보증 · 대출 · 투자를 실행합니다.</p>
            <table>
              <colgroup>
                <col width="200px" />
                <col />
                <col width="270px" />
              </colgroup>
              <thead>
                <tr>
                  <th>종류</th>
                  <th>정의</th>
                  <th>진행기관</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IP담보대출</td>
                  <td>기업이 보유한 지식재산에 대한 가치평가를 거쳐 지식재산을 담보로<br />취급은행을 통해 대출을 받을 수 있습니다.</td>
                  <td>시중은행</td>
                </tr>
                <tr>
                  <td>IP보증대출</td>
                  <td>기업이 보유한 지식재산권을 기반으로 보증기관이 발급한 보증서를 활용하여<br />보증기관에서 협약을 맺은 은행에서 대출을 받을 수 있습니다.</td>
                  <td>신용보증기금, 기술보증기금,<br />서울신용보증재단</td>
                </tr>
                <tr>
                  <td>IP투자</td>
                  <td>우수 지식재산을 보유한 기업에 대해 지분투자를 하거나 특허매입 등 직접투자<br />실시합니다.</td>
                  <td>성장 사다리 펀드,<br />모태펀드 특허계정</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article class="area a2">
            <h4>2021년 IP담보대출 기업의 신용등급</h4>
            <table class="t_type">
              <colgroup>
                <col width="180px" />
              </colgroup>
              <tr class="bord">
                <th rowspan="2">신용등급</th>
                <th>CCC+ 이하</th>
                <th>B- ~ B+</th>
                <th>BB- ~ BB+</th>
                <th>BBB- ~ BBB+</th>
                <th>A- ~ A+</th>
              </tr>
              <tr>
                <td colspan="3">비우량등급</td>
                <td colspan="2">우량등급</td>
              </tr>
            </table>
            <table class="t_type t2">
              <colgroup>
                <col width="180px" />
              </colgroup>
              <tr>
                <th rowspan="2">IP담보대출</th>
                <th colspan="3">77.7%</th>
                <th colspan="2">22.3%</th>
              </tr>
              <tr>
                <td>51개사<br />(3.67%)</td>
                <td>411개사<br />(29.57%)</td>
                <td>616개사<br />(44.46%)</td>
                <td>255개사<br />(18.35%)</td>
                <td>55개사<br />(3.96%)</td>
              </tr>
            </table>
            <ul>
              <li>비우량 등급일지라도, 보유한 IP에 따라 IP담보대출이 가능합니다.</li>
            </ul>
          </article>
          <article class="area a3">
            <h4>IP담보대출 자격요건</h4>
            <p>등록된 특허의 권리자인 중소기업 또는 초기 중견기업</p>
            <ul>
              <li>중소기업: 중소기업기본법 제2조에 해당</li>
              <li>초기 중견기업: 중견기업성장촉진법 제2조에 따른 중견기업으로서, 중소기업기본법 제2조 제3항에서 정한 기간이 종료된 연도의 다음 연도부터<br />3년 이내의 기업(단, 전년도 매출액
                3,000억원 미만)</li>
            </ul>
          </article>
          <article class="area a3">
            <h4>IP담보대출 신청절차</h4>
            <ul>
              <li>1단계 : IP담보대출 상품 상담 및 예비평가</li>
              <li>2단계 : 예비평가를 통과한 경우, 은행이 의뢰한 평가기관으로부터 가치평가 진행 시 구비서류를 IP금융관리시스템으로 신청</li>
            </ul>
            <p>※ 대출가능 여부, 대출한도 및 금리 등 상품운영에 관한 내용은 은행 별 내부 규정에 따르며, 개별기업에 대한 평가에 따라 차등적용 됩니다.</p>
          </article>
        </div>
      </div>
    </div>
    <div class="report r4" style="page-break-before: always">
      <div class="header">
        <div class="h_inner">
          <h1>기업 기술금융 분석 및 정책자금 분석레포트</h1>
          <div class="h_right">
            <p>UNICORNRANCH.CO.KR</p>
            <span><em>5</em></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="inner">
          <article class="area">
            <h3>특허기술 보유 현황 분석&#60;{{customer.comp_nm}}&#62;</h3>
            <div class="unit">
              <h4>기술경쟁력 등급</h4>
              <div class="u_cont">
                <div class="left">
                  <p>{{customer.tech_rate}}</p>
                  <span>{{date}} 현재</span>
                </div>
                <div class="right">
                  <div class="top">
                    <strong>{{customer.comp_nm}}</strong> 기술경쟁력은 <em>상위 {{percent(customer.tech_rate)}}</em>로 평가됩니다.
                  </div>
                  <div class="contant">
                    <dl :class="{focus: customer.tech_rate=='AAA' ? true:false}">
                      <dt>AAA</dt>
                      <dd>상위 0.1%<br />이내</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='AA+' ? true:false}">
                      <dt>AA+</dt>
                      <dd>~<br/>0.3%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='AA' ? true:false}">
                      <dt>AA</dt>
                      <dd>~<br/>0.5%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='AA-' ? true:false}">
                      <dt>AA-</dt>
                      <dd>~<br/>1%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='A+' ? true:false}">
                      <dt>A+</dt>
                      <dd>~<br/>3%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='A' ? true:false}">
                      <dt>A</dt>
                      <dd>~<br/>5%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='A-' ? true:false}">
                      <dt>A-</dt>
                      <dd>~<br/>10%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='B+' ? true:false}">
                      <dt>B+</dt>
                      <dd>~<br/>20%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='B' ? true:false}">
                      <dt>B</dt>
                      <dd>~<br/>30%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='B-' ? true:false}">
                      <dt>B-</dt>
                      <dd>~<br/>40%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='C+' ? true:false}">
                      <dt>C+</dt>
                      <dd>~<br/>50%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='C' ? true:false}">
                      <dt>C</dt>
                      <dd>~<br/>60%</dd>
                    </dl>
                    <dl :class="{focus: customer.tech_rate=='C-' ? true:false}">
                      <dt>C-</dt>
                      <dd>그 외</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div class="unit">
              <h4>특허자산가치</h4>
              <div class="u_cont">
                <div class="left">
                  <p>{{customer.patn_value? fnPoint(customer.patn_value) : '0'}}</p>
                  <span>억원</span>
                </div>
                <div class="right">
                  <h5>국내특허와 해외특허의 가치를 종합하여 평가한 금액입니다.</h5>
                  <p>특허자산가치는 기업의 보유특허를 대상으로 (주)위즈도메인의 특허가치추정엔진 (Patent Valuation Engine)을 이용하여 산정됩니다.<br/>특허가치추정엔진은 특허 빅데이터를 기반으로 관련시장규모, 이익 기여도 및 기술의유효기간 등을 고려하여 특허자산의 현재가치를 자동으로 산출하는 시스텝입니다.</p>
                </div>
              </div>
            </div>
            <!--
            22.12.23 요구사항에 의해 제거
            <div class="unit">
              <h4>기업가치 추정</h4>
              <div class="u_cont">
                <div class="left">
                  <p>{{companyInfo.companyValue? companyInfo.companyValue.estimatedValue: '0'}}</p>
                  <span>억원</span>
                </div>
                <div class="right">
                  <p><strong>{{customer.comp_nm}}</strong>가 보유한 기술력과 동종업계 상장사의 평균적인 PTR을 비교하여 추정한 현재기업가치입니다.<br/>유사기술을 보유한 상장사들의 PTR지수를 벤치마킹하여 산출됩니다.</p>
                  <ul>
                    <li>PTR(Price Technology Ratio)=시가총액/특허의 가치</li>
                    <li>기업의 단위 기술당 주가를 의미합니다. 수치가 높을 수록 단위기술이 높게 평가되고 있음을 의미합니다.</li>
                    <li>벤치마크 기업: 솔트룩스, 줌인터넷, 대원미디어, NAVER...</li>
                  </ul>
                </div>
              </div>
            </div>
            -->
          </article>
          <article class="area">
            <h4>주요특허</h4>
            <p>{{customer.comp_nm}}의 등록특허를 대상으로 평가 점수가 가장 높은 상위 10개 특허입니다.</p>
            <table>
              <colgroup>
                <col width="200px"/>
                <col width="100px"/>
                <col/>
                <col width="150px"/>
              </colgroup>
              <thead>
              <tr>
                <th>특허번호</th>
                <th>등급</th>
                <th>발명의 명칭</th>
                <th>출원일</th>
              </tr>
              </thead>
              <tbody v-if="companyInfo && companyInfo.companyValue && companyInfo.companyValue.patents">
              <!--tr v-for="i in 10" :key="i"-->
              <tr v-for="(item,idx) in companyInfo.companyValue.patents" :key="`patent_${idx}`">
                <td>{{item.patnv? item.patnv : ''}}</td>
                <td>{{item.grade? item.grade : ''}}</td>
                <td>{{cutString(item.ttl? item.ttl : '')}}</td>
                <td>{{item.apd? item.apd : ''}}</td>
              </tr>
              </tbody>
              <!-- 데이터가없을때 -->
              <tbody v-else>
                <td class="nodata" colspan="4">보유한 특허가 없습니다</td>
              </tbody>
            </table>
          </article>
          <article class="area">
            <div class="bottom">
              <p>"다된다" 에서는 상단 "주요특허"에 기재된 {{customer.comp_nm}}의 개별 특허가치 가평가금액 및 레포트를 별도로 제공합니다.</p>
              <p>"다된다"에 특허가치 가평가 금액 문의하기 : <strong>02-2088-1271</strong></p>
            </div>
          </article>
        </div>
        <div style="padding-top: 50px; text-align: center;">
          <button v-if="displayDownloadBtn"
                  :class="['btn', {dark:pdfDownloading}]"
                  style="font-size: 36px;"
                  :disabled="pdfDownloading"
                  @click="downloadPdf">
            {{ !pdfDownloading ? 'PDF 다운로드' : 'PDF 생성 중' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customersService from "~/service/allOk/customersService";

export default {
  layout: 'blank',
  data() {
    return {
      customer: {},
      llResult: [],
      yn:'미보유',
      customerReport: null,
      year:'',
      date:'',
      companyInfo: null,
      displayDownloadBtn: false,
      pdfDownloading: false,
    }
  },
  async asyncData(context) {
    console.log(context.route.query.id)
    const result = await customersService.fetchCustomerDetailReport(context.$axios, context.route.query.id)
    //console.log(result)
    const customer = result.data.data.contents;
    console.log(encodeURI(customer.comp_nm))

    let companyInfo= null
    if(customer){
      const llResult = customer.lookupResultList
      const result3 = await context.store.dispatch('allOk/customersStore/fetchCompanyInfo', customer.biz_no)
      companyInfo=result3

      let yn = '미보유'
      if(customer.lab_yn&&!customer.dept_yn&&!customer.venture_yn&&!customer.innobiz_yn){
        yn = '연구소'
      } else if(customer.lab_yn&&customer.dept_yn&&!customer.venture_yn&&!customer.innobiz_yn) {
        yn = '연구소, 연구전단부서'
      } else if(customer.lab_yn&&!customer.dept_yn&&!customer.venture_yn&&customer.innobiz_yn){
        yn = '연구소, 이노비즈'
      } else if(customer.lab_yn&&!customer.dept_yn&&!customer.venture_yn&&!customer.innobiz_yn){
        yn = '연구소, 벤처인증'
      } else if(customer.lab_yn&&customer.dept_yn&&customer.venture_yn&&!customer.innobiz_yn){
        yn = '연구소, 연구전단부서, 벤처인증'
      } else if(customer.lab_yn&&customer.dept_yn&&!customer.venture_yn&&customer.innobiz_yn){
        yn = '연구소, 연구전단부서, 이노비즈'
      } else if(customer.lab_yn&&!customer.dept_yn&&customer.venture_yn&&customer.innobiz_yn){
        yn = '연구소, 벤처인증, 이노비즈'
      } else if(customer.lab_yn&&customer.dept_yn&&customer.venture_yn&&customer.innobiz_yn){
        yn = '연구소, 연구전단부서, 벤처인증, 이노비즈'
      } else if(!customer.lab_yn&&customer.dept_yn&&customer.venture_yn&&customer.innobiz_yn){
        yn = '연구전단부서, 벤처인증, 이노비즈'
      } else if(!customer.lab_yn&&customer.dept_yn&&!customer.venture_yn&&customer.innobiz_yn){
        yn = '연구전단부서, 이노비즈'
      } else if(!customer.lab_yn&&customer.dept_yn&&customer.venture_yn&&!customer.innobiz_yn){
        yn = '연구전단부서, 벤처인증'
      } else if(!customer.lab_yn&&customer.dept_yn&&!customer.venture_yn&&!customer.innobiz_yn){
        yn = '연구전단부서'
      } else if(!customer.lab_yn&&!customer.dept_yn&&customer.venture_yn&&!customer.innobiz_yn){
        yn = '벤처인증'
      } else if(!customer.lab_yn&&!customer.dept_yn&&customer.venture_yn&&customer.innobiz_yn){
        yn = '벤처인증, 이노비즈'
      } else if(!customer.lab_yn&&!customer.dept_yn&&!customer.venture_yn&&customer.innobiz_yn){
        yn = '이노비즈'
      }

      const result2 = await customersService.getReport(context.$axios, context.route.query.id)
      let customerReport = null
      if(result2.success){
        customerReport = result2.data
      } else {
        customerReport = {}
      }

      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth()+1;
      if((today.getMonth()+1)<10){
        month = '0'+(today.getMonth()+1)
      }
      let day = today.getDate();
      if((today.getDate())<10){
        day = '0'+(today.getDate())
      }
      let date = year+'-'+month+'-'+day

      // PDF 다운로드 버튼 활성화
      let displayDownloadBtn = (context.route.query.dp && context.route.query.dp === 'pdf') ? true : false

      return {
        customer,
        llResult,
        yn,
        customerReport,
        year,
        date,
        companyInfo,
        displayDownloadBtn
      }
    }
  },
  methods: {
    dataCheck(payload){
      if(payload)
        return payload
      return '-'
    },
    fnPoint(payload){
      if(payload)
        return payload.toFixed(2);
      return payload
    },
    cutString(payload){
      const result = payload.split('{')
      return result[0]
    },
    percent(payload){
      let result = null
      if(payload=='AAA'){
        result = '0.1% 이내'
      } else if(payload=='AA+'){
        result = '0.3%'
      } else if(payload=='AA'){
        result = '0.5%'
      } else if(payload=='AA-'){
        result = '1%'
      } else if(payload=='A+'){
        result = '3%'
      } else if(payload=='A'){
        result = '5%'
      } else if(payload=='A-'){
        result = '10%'
      } else if(payload=='B+'){
        result = '20%'
      } else if(payload=='B'){
        result = '30%'
      } else if(payload=='B-'){
        result = '40%'
      } else if(payload=='C+'){
        result = '50%'
      } else if(payload=='C'){
        result = '60%'
      } else if(payload=='C-'){
        result = '그 외'
      }
      return result
    },
    certificationYn(payload){
      if(payload){
        return '있음'
      } else{
        return '없음'
      }
    },
    downloadPdf(){
      if(!this.disabledDownloadBtn){
        this.pdfDownloading = true;
        location.href=`/api/allOk/customers/${this.customer.id}/reportDown?name=${encodeURI(this.customer.comp_nm)}`
        setTimeout(()=> {
          this.pdfDownloading = false;
        }, 1000 * 6);
      }
    }
  },
}
</script>

<style scoped>
@import 'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/css/style2.css?v=2';
</style>
