<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="searchPrdcType">상품타입</label>
          <Select id="searchPrdcType" :itemList="prdcTypes" :value.sync="search.searchPrdcType" name="search.searchPrdcType" />
        </div>
        <div class="unit">
          <label class="tit" for="searchAplc">주요출원인</label>
          <Select id="searchAplc" :itemList="mainAplcs" :value.sync="search.searchAplc" name="search.searchAplc" />
        </div>
        <div class="unit">
          <label class="tit" for="searchAplc">카테고리1</label>
          <SelectSearch
            id="category1"
            :itemList="catg1"
            :value.sync="search.category1"
            name="search.category1"
            @change="
              () => {
                this.search.category2 = null;
              }
            "
          />
        </div>
        <div class="unit">
          <label class="tit" for="searchAplc">카테고리2</label>
          <SelectSearch id="category2" :itemList="catg2" :value.sync="search.category2" name="search.category2" />
        </div>
        <!-- <div class="unit">
          <label class="tit" for="searchTrdTrms">판매조건</label>
          <Select id="searchTrdTrms" :itemList="trdTrms" :value.sync="search.searchTrdTrms" name="search.searchTrdTrms" />
        </div> -->
        <div class="unit">
          <label class="tit" for="searchTrdTrms">평가등급</label>
          <Select id="searchGrade" :itemList="grades" :value.sync="search.searchGrade" name="search.searchGrade" />
        </div>
        <div class="unit">
          <label class="tit" for="searchTrdTrms">추천사유</label>
          <Select id="searchRcmdType" :itemList="rcmdTypes" :value.sync="search.searchRcmdType" name="search.searchRcmdType" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnOpenPop('popStateCreate')">새로운 상품</button>
          <button class="btn" @click="fnClickOpen">열기</button>
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <ProductCreatePop
              v-if="popStateCreate == true"
              :key="popStateCreate"
              @reloadData="fnFetchPrdc"
              @closePop="
                () => {
                  this.fnChangePopState('popStateCreate', false);
                }
              "
            />
            <ProductDetailPop
              v-if="popStateDetail == true"
              :key="popStateDetail"
              :prdc="selectedPrdc"
              @reloadData="fnFetchPrdc"
              @closePop="
                () => {
                  this.fnChangePopState('popStateDetail', false);
                }
              "
            />
          </transition>
        </div>
        <div class="col unit_page">
          <LoadingSpinner class="mr5" v-if="loading" />
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select
            id="pageSize"
            title="20개"
            :itemList="pageSizeList"
            :value="form.pageSize"
            @change="
              item => {
                if (!this.loading) {
                  this.form.pageNo = 1;
                  this.form.pageSize = item.value;
                  this.fnFetchPrdc();
                }
              }
            "
          />
          <button class="btn" @click="fnClickSearch">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="20%" />
              <col width="5%" />
              <col width="20%" />
              <col width="15%" />
              <col width="5%" />
              <col width="5%" />
            </colgroup>
            <thead>
              <tr>
                <th><b>상품번호</b></th>
                <th><b>상품타입</b></th>
                <th><b>명칭</b></th>
                <th><b>등급</b></th>
                <th><b>출원인</b></th>
                <th><b>카테고리</b></th>
                <th><b>추천사유</b></th>
                <th><b>특허수</b></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.prdc" :class="{ on: item.prdc === selectedPrdc }" @click="fnClickPrdc(item.prdc)" @dblclick="fnClickOpen">
                <td>{{ item.prdc }}</td>
                <td>{{ fnGetCodeName('PT00', item.prdcType) }}</td>
                <td>{{ item.prdcNm }}</td>
                <td>{{ item.evGrade }}</td>
                <td>{{ item.aplc }}</td>
                <td>{{ item.catgNm }}</td>
                <td>{{ item.rcmd }}</td>
                <td>{{ item.licenseCnt }}</td>
              </tr>
              <tr v-if="pagination.totalCount == 0">
                <td colspan="8">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              if (!this.loading) {
                this.form.pageNo = p;
                this.fnFetchPrdc();
              }
            }
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import ProductCreatePop from '~/components/pages/openip/product/ProductCreate';
import ProductDetailPop from '~/components/pages/openip/product/ProductDetail';
import Pagination from '~/components/common/Pagination';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  components: {
    Location,
    ProductCreatePop,
    ProductDetailPop,
    Pagination,
    Select,
    SelectSearch,
    LoadingSpinner,
  },

  data() {
    return {
      roles: ['OPENIP'],
      form: {
        pageNo: 1,
        pageSize: 10, // 한 페이지에 보여줄 rows의 갯수
        containSubCatg: 'Y',
        searchPrdcType: null,
        searchAplc: null,
        searchCatgNo: null,
        searchGrade: null,
        // searchTrdTrms: null,
        searchRcmdType: null,
      },
      search: {
        searchPrdcType: null,
        searchAplc: null,
        category1: null,
        category2: null,
        searchGrade: null,
        // searchTrdTrms: null,
        searchRcmdType: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      selectedPrdc: null,
      popStateCreate: false,
      popStateDetail: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
      ],
      loading: false,
    };
  },

  computed: {
    catg1() {
      let category = this.$store.state.openIp.catgStore.category;
      let industry = category.industry.map(el => ({ name: el.catgNm, value: el.catgNo }));
      let theme = category.theme.map(el => ({ name: el.catgNm, value: el.catgNo }));
      let catgs = [{ name: '전체', value: null }, ...industry, ...theme];
      return catgs;
    },

    catg2() {
      let category = this.$store.state.openIp.catgStore.category;
      for (const catgGrp in category) {
        const foundGrp = category[catgGrp].find(el => {
          return el.catgNo === this.search.category1;
        });
        if (foundGrp != null) return foundGrp.sub.map(el => ({ name: el.catgNm, value: el.catgNo }));
      }
      return [];
    },

    prdcTypes() {
      let prdcTypes = this.$store.state.openIp.common.PT00 ? this.$store.state.openIp.common.PT00.map(el => ({ name: el.codeName, value: el.code })) : [];
      prdcTypes.unshift({ name: '전체', value: null });
      return prdcTypes;
    },

    mainAplcs() {
      let aplcs = this.$store.state.openIp.etc.aplc ? this.$store.state.openIp.etc.aplc.map(el => ({ name: el.codeName, value: el.code })) : [];
      aplcs.unshift({ name: '전체', value: null });
      return aplcs;
    },

    // trdTrms() {
    //   let trdTrms = this.$store.state.openIp.common.TT00 ? this.$store.state.openIp.common.TT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    //   trdTrms.unshift({ name: '전체', value: null });
    //   return trdTrms;
    // },

    grades() {
      let grades = this.$store.state.openIp.etc.grade ? this.$store.state.openIp.etc.grade.map(el => ({ name: el.codeName, value: el.code })) : [];
      grades.unshift({ name: '전체', value: null });
      return grades;
    },

    rcmdTypes() {
      let rcmdTypes = this.$store.state.openIp.common.RT00 ? this.$store.state.openIp.common.RT00.map(el => ({ name: el.codeName, value: el.code })) : [];
      rcmdTypes.unshift({ name: '전체', value: null });
      return rcmdTypes;
    },
  },

  methods: {
    fnClickPrdc(prdc) {
      this.selectedPrdc = prdc;
    },

    fnClickSearch() {
      if (!this.loading) {
        let searchForm = {};
        searchForm.searchPrdcType = this.search.searchPrdcType;
        searchForm.searchAplc = this.search.searchAplc;
        searchForm.searchCatgNo = this.search.category1 && this.search.category2 ? this.search.category2 : this.search.category1;
        searchForm.searchGrade = this.search.searchGrade;
        // searchForm.searchTrdTrms = this.search.searchTrdTrms;
        searchForm.searchRcmdType = this.search.searchRcmdType;

        this.form.pageNo = 1;
        Object.keys(searchForm).forEach(key => {
          this.form[key] = searchForm[key];
        });
        this.fnFetchPrdc();
      }
    },

    async fnFetchPrdc() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('openIp/prdcStore/fetchPrdc', this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
        this.selectedPrdc = null;
      } else {
        this.$popupAlert({
          msg: `상품리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnClickOpen() {
      if (!this.selectedPrdc) {
        this.$popupAlert({
          msg: '상품을 선택해주세요',
        });
      } else {
        this.fnOpenPop('popStateDetail');
      }
    },

    fnOpenPop(key) {
      // this.$store.dispatch('ui/setMenuState', false);
      this.fnChangePopState(key, true);
    },

    fnChangePopState(key, pop) {
      this[key] = pop;
    },

    fnGetCodeName(codeGroup, code) {
      const list = this.$store.state.openIp.common[codeGroup];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },
  },

  async mounted() {
    await this.$store.dispatch('openIp/fetchCodes');
    await this.$store.dispatch('openIp/catgStore/fetchCatg');
    this.fnFetchPrdc();
  },
};
</script>
