<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>뉴스 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner class="mt5 mr5" v-if="loading" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="$emit('closePop')">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>제목</h4>
                  <div class="i_wrap">
                    <Input class="mt5" :value.sync="body.Title" name="body.Title" type="text" placeholder="제목을 입력해주세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>대표이미지</h4>
                  <div class="i_wrap">
                    <InputFile id="file" name="file" placeholder="파일을 선택하세요" :value="file" @change="fnUploadImage" />
                    <div class="bx_img">
                      <img :src="body.ThumbnailUrl" class="img" alt="첨부한 이미지" />
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>태그</h4>
                  <div class="i_wrap">
                    <Input class="mt5" :value.sync="body.Tag" name="body.Tag" type="text" placeholder="태그를 입력해주세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>내용</h4>
                  <div class="i_wrap">
                    <client-only placeholder="loading...">
                      <ckeditor-nuxt v-model="body.Content" :config="editorConfig" />
                    </client-only>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import urnewsService from '~/service/Cms/urnewsService';
import Input from '~/components/common/Input';
import InputFile from '~/components/common/InputFile';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  props: ['selectedNews'],
  components: {
    Input,
    InputFile,
    LoadingSpinner,
    'ckeditor-nuxt': () => {
      if (process.client) return import('@blowstack/ckeditor-nuxt');
    },
  },

  data() {
    return {
      newsNo: this.selectedNews,
      editorConfig: {
        removePlugins: ['Title', 'MediaEmbedToolbar'],
        simpleUpload: {
          uploadUrl: '/api/cms/upload/ur/editor',
        },
      },
      body: {
        Title: null,
        Content: '',
        Tag: null,
        ThumbnailUrl: null,
      },
      file: null,
      loading: false,
    };
  },

  methods: {
    async fnFetchNews() {
      this.loading = true;
      const { result, data } = await urnewsService.getNewsDetail(this.$axios, this.newsNo);
      this.loading = false;
      if (result) {
        this.body.Title = data.contents.attributes.Title;
        this.body.Content = data.contents.attributes.Content || '';
        this.body.Tag = data.contents.attributes.Tag;
        this.body.ThumbnailUrl = data.contents.attributes.ThumbnailUrl;
      } else {
        this.$popupAlert({
          msg: `뉴스 정보를 가져오는데 실패했습니다(${data.error.msg})`,
        });
        this.$emit('closePop');
      }
    },
    async fnClickSave(skipClose) {
      if (this.loading) return;
      if (!this.body.Title) {
        this.$popupAlert({
          msg: '제목을 입력하세요',
        });
        return;
      }
      if (!this.body.Content) {
        this.$popupAlert({
          msg: '내용을 입력하세요',
        });
        return;
      }

      this.loading = true;
      const { result, data } = await urnewsService.updateNews(this.$axios, this.newsNo, this.body);
      this.loading = false;
      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '뉴스가 수정되었습니다',
        });

        this.body.Title = data.contents.attributes.Title;
        this.body.Content = data.contents.attributes.Content || '';
        this.body.Tag = data.contents.attributes.Tag;
        this.body.ThumbnailUrl = data.contents.attributes.ThumbnailUrl;

        this.$emit('reloadData');
      } else {
        this.$popupAlert({
          msg: `뉴스를 수정하는데 실패했습니다(${data.error.msg})`,
        });
      }

      if (!skipClose) this.$emit('closePop');
    },
    async fnUploadImage(file) {
      const formData = new FormData();
      formData.append('upload', file);
      const { result, data } = await this.$axios.$post('/api/cms/upload/ur', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (result) {
        this.body.ThumbnailUrl = data.contents;
        this.file = file;
      } else {
        this.$popupAlert({
          msg: `파일 업로드를 실패했습니다(${data.error.msg})`,
        });
      }
    },
  },

  created() {
    this.fnFetchNews();
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
