var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container"},[_c('Location'),_vm._v(" "),_c('div',{staticClass:"inner contents_col2 bg_white"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"form_list type2"},[_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("이슈 섹터")]),_vm._v(" "),_c('div',{staticClass:"i_wrap mt5"},[_c('SelectSearch',{attrs:{"name":"issueSector","itemList":_vm.sectorList,"placeholder":"섹터명을 입력해주세요.","value":_vm.issueSector},on:{"update:value":function($event){_vm.issueSector=$event},"change":function($event){return _vm.$popupConfirm({
                  id: 'issueSector',
                  title: '이슈섹터 설정',
                  msg: '이슈섹터를 설정 하시겠습니까?',
                  btn: '설정',
                })}}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("이슈 테마")]),_vm._v(" "),_c('div',{staticClass:"i_wrap mt5"},[_c('SelectSearch',{attrs:{"name":"issueTheme","itemList":_vm.themeList,"placeholder":"테마명을 입력해 주세요.","value":_vm.issueTheme},on:{"update:value":function($event){_vm.issueTheme=$event},"change":function($event){return _vm.$popupConfirm({
                  id: 'issueTheme',
                  title: '이슈테마 설정',
                  msg: '이슈테마를 설정 하시겠습니까?',
                  btn: '설정',
                })}}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"right"})]),_vm._v(" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.$store.state.ui.popConfirmData.state == true)?_c('Confirm',{key:_vm.$store.state.ui.popConfirmData.state,on:{"confirm":id => {
          _vm.updateIssue(id, true);
        },"cancel":id => {
          _vm.updateIssue(id, false);
        }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }