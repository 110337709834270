<template>
  <div class="bx_chk">
    <input
      type="checkbox"
      class="chk"
      :id="id"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      @click="fnCheck"
    />
    <label :for="id" :class="classNm" v-html="label"></label>
  </div>
</template>
<script>
export default {
  props: {
    // 클래스
    classNm: {
      type: String
    },
    // 라벨
    label: {
      type:String,
      required: true,
    },
    // 필드 명
    name:{
      type:String,
      required:true,
    },
    // 필드 값
    value:{
      type:Boolean,
      default:false,
    },
    // 사용여부
    disabled:{
      type:Boolean,
      default: false
    },
  },
  data() {
    return {
      id:null,
    }
  },
  computed:{
    checked(){
      return this.value;
    }
  },
  methods: {
    fnCheck(e){
      if(!this.disabled)
        this.$emit('update:value', e.target.checked);
        this.$emit('change', e.target.checked);
    },
  },
  beforeMount() {
    // 아이디 생성
    this.id = 'chk_'+this.name+'_'+Math.round(Math.random()*10000);
  }
};
</script>
