<template>
  <section class="container">
    <Location />
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <ul class="form_list type2">
          <li class="item_ipt">
            <h4>이슈 섹터</h4>
            <div class="i_wrap mt5">
              <SelectSearch
                name="issueSector"
                :itemList="sectorList"
                placeholder="섹터명을 입력해주세요."
                :value.sync="issueSector"
                @change="
                  $popupConfirm({
                    id: 'issueSector',
                    title: '이슈섹터 설정',
                    msg: '이슈섹터를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                " />
            </div>
          </li>
          <!-- <li class="item_ipt">
            <div class="i_wrap">
              <MultiStockSearch
                :stkList.sync="issueSectorStk"
                @updateStk="$popupConfirm({ id: 'issueSector', title: '이슈섹터 설정', msg: '이슈섹터를 설정 하시겠습니까?', btn: '설정' })"
                desc="선택된 이슈 섹터의 종목 구성 (필수 5개) "
              />
            </div>
          </li> -->
          <li class="item_ipt">
            <h4>이슈 테마</h4>
            <div class="i_wrap mt5">
              <SelectSearch
                name="issueTheme"
                :itemList="themeList"
                placeholder="테마명을 입력해 주세요."
                :value.sync="issueTheme"
                @change="
                  $popupConfirm({
                    id: 'issueTheme',
                    title: '이슈테마 설정',
                    msg: '이슈테마를 설정 하시겠습니까?',
                    btn: '설정',
                  })
                " />
            </div>
          </li>
          <!-- <li class="item_ipt">
            <h4>이슈 선정일</h4>
            <div class="i_wrap mt5">
              <DatePicker
                :value.sync="issueDt"
                @change="
                  $popupConfirm({
                    id: 'issueDt',
                    title: '이슈 선정일 설정',
                    msg: '이슈 선정일을 설정 하시겠습니까?',
                    btn: '설정',
                  })
                " />
            </div>
          </li> -->
        </ul>
      </div>
      <div class="right"></div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <Confirm
        v-if="$store.state.ui.popConfirmData.state == true"
        :key="$store.state.ui.popConfirmData.state"
        @confirm="
          id => {
            updateIssue(id, true);
          }
        "
        @cancel="
          id => {
            updateIssue(id, false);
          }
        " />
    </transition>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
// import MultiStockSearch from '~/components/pages/tenbagger/MultiStockSearch.vue';
import InputBox from '~/components/common/Input.vue';
// import DatePicker from '~/components/pages/tenbagger/DatePicker2';
import SelectSearch from '~/components/common/SelectSearch';
import Confirm from '~/components/popup/Confirm';

export default {
  components: {
    Location,
    SelectSearch,
    // MultiStockSearch,
    InputBox,
    // DatePicker,
    Confirm,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_SKMTS'],
      issueSector: null,
      issueTheme: null,
      issueDt: null,
      issueSectorStk: [],
    };
  },

  computed: {
    sectorList() {
      return this.$store.state.tenbagger.skmts.sectorList;
    },
    themeList() {
      return this.$store.state.tenbagger.skmts.themeList;
    },
  },
  methods: {
    setDefault(issueType) {
      let mtsIssue = this.$store.state.tenbagger.skmts.mtsIssue;
      if (issueType == 'issueSector') {
        this.issueSector = mtsIssue.sectorNo;
        // this.getIssueSectorStk(mtsIssue.sectorNo);
      } else if (issueType == 'issueTheme') {
        this.issueTheme = mtsIssue.themeNo;
      } else if (issueType == 'issueDt') {
        let date = mtsIssue.mtsIssueDt ? '20' + mtsIssue.mtsIssueDt.substr(7).replace(/\./g, '-') : null;
        this.issueDt = date;
      }
    },

    async updateIssue(issueType, isConfirm) {
      if (isConfirm) {
        if (issueType === 'issueSector') {
          // if (this.issueSectorStk.length < 1) {
          //   this.$popupAlert({
          //     msg: '종목을 설정하세요',
          //   });
          //   return;
          // }

          const res1 = await this.$store.dispatch('tenbagger/skmts/updateIssueSector', { securityType: 'SK', sectorNo: this.issueSector });
          if (res1.result) {
            const res2 = await this.$store.dispatch('tenbagger/skmts/updateIssueDt', this.fnToday());
            if (res2.result) {
              this.$popupAlert({
                title: '알림',
                msg: '설정이 완료되었습니다',
              });
              this.$store.dispatch('tenbagger/skmts/fetchMtsIssue');
            } else {
              this.$popupAlert({
                msg: `설정을 실패했습니다(${res2.data.error.msg})`,
              });
            }
            // let isuSrtCds = this.issueSectorStk.map(el => el.isuSrtCd);
            // let payload = {
            //   sectorNo: this.issueSector,
            //   securityType: 'SK',
            //   isuSrtCds: isuSrtCds,
            // };
            // const res2 = await this.$store.dispatch('tenbagger/skmts/addIssueSectorStk', payload);
            // if (res2.result) {
            //   this.$popupAlert({
            //     title: '알림',
            //     msg: '설정이 완료되었습니다',
            //   });
            //   this.$store.dispatch('tenbagger/skmts/fetchMtsIssue');
            // } else {
            //   this.$popupAlert({
            //     msg: `설정을 실패했습니다(${res2.data.error.msg})`,
            //   });
            // }
          } else {
            this.$popupAlert({
              msg: `설정을 실패했습니다(${res1.data.error.msg})`,
            });
          }
        } else if (issueType === 'issueTheme') {
          const { result, data } = await this.$store.dispatch('tenbagger/skmts/updateIssueTheme', { securityType: 'SK', themeNo: this.issueTheme });
          if (result) {
            this.$popupAlert({
              title: '알림',
              msg: '설정이 완료되었습니다',
            });
            this.$store.dispatch('tenbagger/skmts/fetchMtsIssue');
          } else {
            this.$popupAlert({
              msg: `설정을 실패했습니다(${data.error.msg})`,
            });
          }
        } else if (issueType === 'issueDt') {
          const { result, data } = await this.$store.dispatch('tenbagger/skmts/updateIssueDt', this.issueDt);
          if (result) {
            this.$popupAlert({
              title: '알림',
              msg: '설정이 완료되었습니다',
            });
            this.$store.dispatch('tenbagger/skmts/fetchMtsIssue');
          } else {
            this.$popupAlert({
              msg: `설정을 실패했습니다(${data.error.msg})`,
            });
          }
        }
      } else {
        this.setDefault(issueType);
      }
    },

    async getIssueSectorStk(sectorNo) {
      if (!sectorNo) {
        return;
      }

      let payload = {
        sectorNo: sectorNo,
        form: {
          pageNo: 1,
          pageSize: 5,
          securityType: 'SK',
        },
      };
      let { result, data } = await this.$store.dispatch('tenbagger/skmts/fetchIssueSectorStk', payload);
      if (result) {
        this.issueSectorStk = data.contents.stks.map(el => ({
          isuSrtCd: el.isuSrtCd,
          isuKorNm: el.isuKorNm,
        }));
      } else {
        this.$popupAlert({
          msg: `이슈섹터 종목을 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnToday() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
  },

  async mounted() {
    let result1 = await this.$store.dispatch('tenbagger/skmts/fetchSectorList');
    if (!result1.result) {
      this.$popupAlert({
        msg: `섹터정보를 가져오는데 실패했습니다(${result1.data.error.msg})`,
      });
      return;
    }

    let result2 = await this.$store.dispatch('tenbagger/skmts/fetchThemeList');
    if (!result2.result) {
      this.$popupAlert({
        msg: `테마정보를 가져오는데 실패했습니다(${result2.data.error.msg})`,
      });
      return;
    }

    let result3 = await this.$store.dispatch('tenbagger/skmts/fetchMtsIssue');
    if (result3.result) {
      this.setDefault('issueSector');
      this.setDefault('issueTheme');
      this.setDefault('issueDt');
    } else {
      this.$popupAlert({
        msg: `MTS이슈정보를 가져오는데 실패했습니다(${result3.data.error.msg})`,
      });
      return;
    }
  },
};
</script>
