<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>종목 선택</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner class="mt5 mr5" v-if="loading" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickAdd">설정</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <h3>선택한 종목</h3>
              <ul class="form_list type2 mt5">
                <li class="item_ipt">
                  <div class="i_wrap">
                    <div class="bx_txt">
                      {{ selectedStk.isuSrtCd ? `${selectedStk.isuSrtCd}(${selectedStk.isuKorNm})` : null }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right">
              <h3>대상 종목</h3>
              <div class="area_search mt5">
                <div class="unit">
                  <label class="tit" for="cnsltNm">종목명,<br />종목코드</label>
                  <Input id="searchWrd" :value.sync="search.searchWrd" name="search.searchWrd" type="text" shape="search" placeholder="종목명 또는 종목코드를 입력하세요." @enter="fnClickSearch" />
                </div>
              </div>
              <div class="contents">
                <div class="tbl_area" style="min-width: auto">
                  <table class="tbl">
                    <colgroup>
                      <col width="30%" />
                      <col width="55%" />
                      <col width="15%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <b>종목코드</b>
                          <div class="bx_srot">
                            <button
                              class="btn_up"
                              :class="{
                                on: form.sortColumn !== 'ISU_SRT_CD' || form.sortOrdr === 'ASC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_SRT_CD', sortOrdr: 'ASC' })"
                            >
                              오름차순정렬
                            </button>
                            <button
                              class="btn_down"
                              :class="{
                                on: form.sortColumn !== 'ISU_SRT_CD' || form.sortOrdr === 'DESC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_SRT_CD', sortOrdr: 'DESC' })"
                            >
                              내림차순정렬
                            </button>
                          </div>
                        </th>
                        <th>
                          <b>종목명</b>
                          <div class="bx_srot">
                            <button
                              class="btn_up"
                              :class="{
                                on: form.sortColumn !== 'ISU_KOR_NM' || form.sortOrdr === 'ASC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_KOR_NM', sortOrdr: 'ASC' })"
                            >
                              오름차순정렬
                            </button>
                            <button
                              class="btn_down"
                              :class="{
                                on: form.sortColumn !== 'ISU_KOR_NM' || form.sortOrdr === 'DESC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_KOR_NM', sortOrdr: 'DESC' })"
                            >
                              내림차순정렬
                            </button>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in contents" :key="item.isuSrtCd">
                        <td>{{ item.isuSrtCd }}</td>
                        <td>
                          {{ item.isuKorNm }}
                        </td>
                        <td>
                          <button class="btn b_add" @click="fnClickStk(item)">추가</button>
                        </td>
                      </tr>
                      <tr v-if="pagination.totalCount == 0">
                        <td colspan="7">데이터가 없습니다.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Pagination
                  :page="form.pageNo"
                  :perPage="form.pageSize"
                  :totalCount="pagination.totalCount"
                  @change="
                    p => {
                      if (!this.loading) {
                        this.form.pageNo = p;
                        this.fnFetchStk();
                      }
                    }
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import Pagination from '~/components/common/Pagination';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  props: ['stk'],
  data() {
    return {
      form: {
        pageNo: 1,
        pageSize: 10,
        searchWrd: null,
        arrantrdYn: 'N',
        sortColumn: 'ISU_SRT_CD',
        sortOrdr: 'ASC',
      },
      search: {
        searchWrd: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      selectedStk: this.stk,
      loading: false,
    };
  },

  components: {
    Input,
    Pagination,
    LoadingSpinner,
  },

  methods: {
    fnClosePop() {
      this.$emit('closePop');
    },

    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchStk();
      }
    },

    fnClickStk(stk) {
      this.selectedStk = { isuSrtCd: stk.isuSrtCd, isuKorNm: stk.isuKorNm };
    },
    fnClickAdd() {
      this.$emit('selectStk', this.selectedStk);
    },

    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchStk();
      }
    },

    async fnFetchStk() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('tenbagger/fetchStk', this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `종목리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },
  },

  mounted() {
    this.fnFetchStk();
  },
};
</script>
