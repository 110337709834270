<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>섹터 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li>
                  <h4>섹터번호</h4>
                  <div class="i_wrap">
                    <span>{{ this.selectedSector }}</span>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>섹터명</h4>
                  <div class="i_wrap">
                    <Input id="sectorNm" :value.sync="updateSectorForm.sectorName" name="updateSectorForm.sectorName" type="text" placeholder="섹터명을 입력해주세요" />
                  </div>
                </li>
                <li>
                  <h4>보고서 개요</h4>
                  <div class="i_wrap">
                    <textarea id="outline" class="textarea" style="height: 200px" placeholder="보고서 개요를 입력해주세요" v-model="updateSectorForm.rptOutline"></textarea>
                  </div>
                </li>
                <li>
                  <h4>보고서 이슈/<br />트렌드</h4>
                  <div class="i_wrap">
                    <textarea id="issue" class="textarea" style="height: 200px" placeholder="보고서 이슈/트렌드를 입력해주세요" v-model="updateSectorForm.rptIssue"></textarea>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>보고 일자</h4>
                  <div class="i_wrap mt5">
                    <DatePicker id="rptDt" :value.sync="updateSectorForm.rptDt" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>정렬순서</h4>
                  <div class="i_wrap">
                    <Input id="orderNo" :value.sync="updateSectorForm.orderNo" name="updateSectorForm.orderNo" type="text" placeholder="정렬순서를 입력해주세요." />
                    <span class="txt_red mt5" v-if="isNaN(updateSectorForm.orderNo)">숫자를 입력하세요</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import DatePicker from '~/components/pages/tenbagger/DatePicker2';

export default {
  props: ['selectedSector'],
  components: {
    Input,
    DatePicker,
  },

  data() {
    return {
      updateSectorForm: {
        sectorName: null,
        sectorDesc: null,
        orderNo: null,
        rptOutline: null,
        rptIssue: null,
        rptDt: null,
      },
    };
  },

  created() {
    this.fetchSector();
  },

  methods: {
    async fetchSector() {
      const { result, data } = await this.$store.dispatch('tenbagger/sector/fetchSectorDetail', this.selectedSector);
      if (result) {
        this.updateSectorForm.sectorName = data.contents.sectorName;
        this.updateSectorForm.sectorDesc = data.contents.sectorDesc;
        this.updateSectorForm.orderNo = data.contents.orderNo;
        this.updateSectorForm.rptOutline = data.contents.rptOutline;
        this.updateSectorForm.rptIssue = data.contents.rptIssue;
        this.updateSectorForm.rptDt = data.contents.rptDt ? data.contents.rptDt.replace(/\./g, '-') : null;
      } else {
        this.$popupAlert({
          msg: `섹터정보를 가져오는데 실패했습니다(${data.error.msg})`,
        });
        this.fnClosePop('popSectorDetailState');
      }
    },

    fnClosePop() {
      this.$emit('closePop');
    },

    async fnClickSave(skipClose) {
      if (!this.updateSectorForm.sectorName) {
        this.$popupAlert({
          msg: '섹터명을 입력하세요',
        });
        return;
      }

      if (isNaN(this.updateSectorForm.orderNo)) {
        this.$popupAlert({
          msg: '정렬순서는 숫자를 입력하세요',
        });
        return;
      }

      this.updateSectorForm.rptDt = this.updateSectorForm.rptDt ? this.updateSectorForm.rptDt.replace(/\-/g, '.') : null;

      const payload = {
        sectorNo: this.selectedSector,
        form: this.updateSectorForm,
      };
      const { result, data } = await this.$store.dispatch('tenbagger/sector/updateSector', payload);

      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '섹터정보가 수정되었습니다',
        });
        this.$emit('reloadData');
      } else {
        this.$popupAlert({
          msg: `섹터를 수정하는데 실패했습니다(${data.error.msg})`,
        });
      }

      if (skipClose) {
        this.fetchSector();
      } else {
        this.fnClosePop('popSectorDetailState');
      }
    },
  },
};
</script>
