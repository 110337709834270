<template>
  <div class="join">
    <div class="inner">
      <h2>회원가입</h2>
      <div class="form">
        <div class="item">
          <label for="id">아이디</label>
          <InputBox
            id="id"
            type='text'
            name="id"
            placeholder='이메일형식의 아이디를 입력해 주세요.'
            :disabled="check"
            :value.sync="email"
          />
        </div>
        <div class="btns">
          <button class="btn full" @click="fnRegist" :disabled="check">회원가입</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';

export default {
  layout: 'none',
  components: {
    InputBox,
  },
  data() {
    return {
      email: '',
      check: false,
    };
  },
  methods: {
    async fnRegist() {
      if (!this.$validate('email', this.email)) {
        this.$popupAlert({
          title: '경고 : 이메일',
          msg: '이메일형식의 아이디를 입력해 주세요.',
        });
        return;
      } else {
        await this.$store.dispatch('backoffice/signUpStore/sendMail', { email: this.email });
        this.$popupAlert({
          title: '회원가입',
          msg: '회원가입을 위한 이메일이 전송되었습니다.',
        });
        this.check = true;
      }
    },
  },
};
</script>
