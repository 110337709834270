<template>
  <div class="app no_layout">
    <div class="wrap" style="overflow: auto">
      <nuxt />
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <Alert v-if="$store.state.ui.popAlertData.state == true" :key="$store.state.ui.popAlertData.state" />
    </transition>
  </div>
</template>

<script>
import Setting from '~/mixins/Setting';
import Alert from '~/components/popup/Alert';

export default {
  mixins: [Setting],
  components: {
    Alert,
  },
};
</script>
