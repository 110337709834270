<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="searchPatn">특허번호</label>
          <Input id="searchPatn" :value.sync="search.searchPatn" name="search.searchPatn" type="text" placeholder="특허번호를 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchCd">IPC코드</label>
          <Input id="searchCd" :value.sync="search.searchCd" name="search.searchCd" type="text" placeholder="IPC코드를 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchEvGrade">등급</label>
          <Select id="searchEvGrade" :itemList="grades" :value.sync="search.searchEvGrade" name="search.searchEvGrade" type="text" />
        </div>
        <div class="unit">
          <label class="tit" for="searchStatus">상태</label>
          <Select id="searchStatus" :itemList="status" :value.sync="search.searchStatus" name="search.searchStatus" type="text" />
        </div>
        <div class="unit">
          <label class="tit" for="searchHldr">권리자</label>
          <Input id="searchHldr" :value.sync="search.searchHldr" name="search.searchHldr" type="text" placeholder="권리자를 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchAplc">출원인</label>
          <Input id="searchAplc" :value.sync="search.searchAplc" name="search.searchAplc" type="text" placeholder="출원인을 입력하세요." @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchWrd">명칭</label>
          <Input id="searchWrd" :value.sync="search.searchWrd" name="search.searchWrd" type="text" placeholder="단어를 입력하세요." @enter="fnClickSearch" />
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnClickOpen">열기</button>
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <PatentDetailPop
              v-if="popStateDetail == true"
              :key="popStateDetail"
              :selectedPatnNo="selectedPatnNo"
              @closePop="
                () => {
                  this.fnChangePopState('popStateDetail', false);
                }
              "
            />
          </transition>
        </div>
        <div class="col unit_page">
          <LoadingSpinner class="mr5" v-if="loading" />
          <h4 class="l_tit">페이지당 개수 :</h4>
          <Select
            id="pageSize"
            title="20개"
            :itemList="pageSizeList"
            :value="form.pageSize"
            @change="
              item => {
                if (!this.loading) {
                  this.form.pageNo = 1;
                  this.form.pageSize = item.value;
                  this.fnFetchPatn();
                }
              }
            "
          />
          <button class="btn" @click="fnClickSearch">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col width="10%" />
              <col width="30%" />
              <col width="10%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="5%" />
            </colgroup>
            <thead>
              <tr>
                <th><b>특허번호</b></th>
                <th><b>명칭</b></th>
                <th>
                  <b>등급</b>
                  <!-- <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'GRADE' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'GRADE', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'GRADE' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'GRADE', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div> -->
                </th>
                <th>
                  <b>IPC</b>
                  <!-- <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'IPC' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'IPC', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'IPC' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'IPC', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div> -->
                </th>
                <th>
                  <b>권리자</b>
                  <!-- <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'HLDR' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'HLDR', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'HLDR' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'HLDR', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div> -->
                </th>
                <th>
                  <b>출원인</b>
                  <!-- <div class="bx_srot">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== 'APLC' || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'APLC', sortOrdr: 'ASC' })"
                    >
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== 'APLC' || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: 'APLC', sortOrdr: 'DESC' })"
                    >
                      내림차순정렬
                    </button>
                  </div> -->
                </th>
                <th><b>상태</b></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.patnNo" :class="{ on: item.patnNo === selectedPatnNo }" @click="fnClickPatn(item.patnNo)" @dblclick="fnClickOpen">
                <td>{{ item.patn }}</td>
                <td>{{ item.patnTitle }}</td>
                <td>{{ item.evGrade }}</td>
                <td>{{ item.ipcCd }}</td>
                <td>{{ item.hldr }}</td>
                <td>{{ item.aplc }}</td>
                <td>{{ fnGetCodeName('PAS00', item.patnStatus) }}</td>
              </tr>
              <tr v-if="pagination.totalCount == 0">
                <td colspan="7">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              if (!this.loading) {
                this.form.pageNo = p;
                this.fnFetchPatn();
              }
            }
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import PatentDetailPop from '~/components/pages/openip/patent/PatentDetail';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  components: {
    Location,
    PatentDetailPop,
    Pagination,
    Input,
    Select,
    LoadingSpinner,
  },

  data() {
    return {
      roles: ['OPENIP'],
      form: {
        pageNo: 1,
        pageSize: 10, // 한 페이지에 보여줄 rows의 갯수
        searchPatn: null,
        searchCd: null,
        searchEvGrade: null,
        searchStatus: null,
        searchHldr: null,
        searchAplc: null,
        searchWrd: null,
        // sortColumn: null,
        // sortOrdr: null,
      },
      search: {
        searchPatn: null,
        searchCd: null,
        searchEvGrade: null,
        searchStatus: null,
        searchHldr: null,
        searchAplc: null,
        searchWrd: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      selectedPatnNo: null,
      popStateDetail: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
      ],
      loading: false,
    };
  },

  computed: {
    grades() {
      let grades = this.$store.state.openIp.etc.grade ? this.$store.state.openIp.etc.grade.map(el => ({ name: el.codeName, value: el.code })) : [];
      grades.unshift({ name: '전체', value: null });
      return grades;
    },
    status() {
      let status = this.$store.state.openIp.common.PAS00 ? this.$store.state.openIp.common.PAS00.map(el => ({ name: el.codeDesc, value: el.code })) : [];
      status.unshift({ name: '전체', value: null });
      return status;
    },
  },

  methods: {
    fnClickPatn(patnNo) {
      this.selectedPatnNo = patnNo;
    },

    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchPatn();
      }
    },

    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchPatn();
      }
    },

    async fnFetchPatn() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('openIp/patnStore/fetchPatn', this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
        this.selectedPatnNo = null;
      } else {
        this.$popupAlert({
          msg: `특허리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnClickOpen() {
      if (!this.selectedPatnNo) {
        this.$popupAlert({
          msg: '특허를 선택해주세요',
        });
      } else {
        this.fnOpenPop('popStateDetail');
      }
    },

    fnOpenPop(key) {
      // this.$store.dispatch('ui/setMenuState', false);
      this.fnChangePopState(key, true);
    },

    fnChangePopState(key, pop) {
      this[key] = pop;
    },

    fnGetCodeName(codeGroup, code) {
      const list = this.$store.state.openIp.common[codeGroup];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeDesc : '';
      }
      return '';
    },
  },

  async mounted() {
    await this.$store.dispatch('openIp/fetchCodes');
    this.fnFetchPatn();
  },
};
</script>
