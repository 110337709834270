<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>고객상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button :class="{ tab: true, on: on == 'tab1' }" @click="() => (this.on = 'tab1')">고객상세</button>
<!--
              22.12.23 기능 제거 -->
              <button :class="{ tab: true, on: on == 'tab3' }" @click="() => (this.on = 'tab3')" v-if="$roleCheck(['ALL_OK_MNG'])">담당자배정</button>
              <!-- <button :class="{ tab: true, on: on == 'tab2' }" @click="() => (this.on = 'tab2')">상담내역</button> -->

              <!--
              22.12.23 TODO : B2C에서 기술신용조사서가 등록 기능이 완료 되면 '보고서 입력' 탭을 제거한다.
              -->
              <!-- <button :class="{ tab: true, on: on == 'tab4' }" @click="() => (this.on = 'tab4')">보고서 입력</button> -->
              <button :class="{ tab: true, on: on == 'tab5' }" @click="() => (this.on = 'tab5')">서류 및 기술평가</button>
              <button :class="{ tab: true, on: on == 'tab6' }" @click="() => (this.on = 'tab6')">진행단계</button>
            </div>
            <div class="btns" v-if="on == 'tab1'">
              <!--
              <button class="btn" @click="fnSave1">저장</button>
              <button class="btn" @click="fnSave1AndClose">저장 후 닫기</button>
              -->
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
            <div class="btns" v-if="on == 'tab3'">
              <!-- <button class="btn" @click="fnSave3" v-if="$roleCheck(['ALL_OK_MNG'])">저장</button>
              <button class="btn dark" @click="fnClosePop" >취소</button> -->
              <button class="btn dark" @click="fnClosePop">닫기</button>
            </div>
            <div class="btns" v-if="on == 'tab5'">
              <button class="btn dark" @click="fnClosePop" >닫기</button>
            </div>
            <div class="btns" v-if="on == 'tab6'">
              <button class="btn dark" @click="fnClosePop" >닫기</button>
            </div>
          </div>
          <div class="c_contents" v-if="this.on == 'tab1'">
            <CustomerTab1 :id="this.id" ref="save1" />
          </div>
          <div class="c_contents" v-if="this.on == 'tab3'" >
            <CustomerTab3 :id="this.id" ref="save3" />
          </div>
          <div class="c_contents" v-if="this.on == 'tab5'" >
            <CustomerTab5 :id="this.id" :customerId="this.customerId" ref="save5" />
          </div>
          <div class="c_contents" v-if="this.on == 'tab6'" >
            <CustomerTab6 :id="this.id" ref="save6" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import CustomerTab1 from './CustomerTab1.vue';
// import CustomerTab2 from './CustomerTab2.vue';
import CustomerTab3 from "./CustomerTab3.vue";
// import CustomerTab4 from './CustomerTab4.vue';
import CustomerTab5 from "./CustomerTab5.vue";
import CustomerTab6 from "./CustomerTab6.vue";

export default {
  name: 'CustomerDetail',
  data() {
    return {
      on: 'tab1',
    };
  },
  props: ['id', 'customerId'],
  components: {
    InputBox,
    ItemPatent,
    Select,
    // CustomerTab1, CustomerTab2, CustomerTab3, CustomerTab4
    CustomerTab1, CustomerTab3, CustomerTab5, CustomerTab6
  },
  methods: {
    async fnClosePop() {
      await this.$emit('reload');
      await this.$store.dispatch('ui/setPopCsState', false);
    },
    async fnSave1() {
      await this.$refs.save1.fnSave();
      await this.$emit('reload');
    },
    async fnSave1AndClose() {
      await this.$refs.save1.fnSave();
      await this.fnClosePop();
    },
    // async fnSave2() {
    //   await this.$refs.save2.fnSave();
    //   await this.$emit('reload');
    // // },
    // async fnSave3() {
    //   await this.$refs.save3.fnSave();
    //   await this.$emit('reload');
    // },
    // async fnSave4() {
    //   await this.$refs.save4.fnSave();
    //   await this.$emit('reload');
    // },
    // async fnSave4AndClose() {
    //   await this.$refs.save4.fnSave();
    //   await this.$emit('reload');
    //   this.fnClosePop();
    // },
  },
};
</script>
