<template>
  <header>
    <div class="left">
      <button class="btn_menu" @click="fnToggleMenu">menu button</button>
    </div>
    <!-- <Gnb/> -->
    <div class="right">
      <!--
      <button class="btn_my">사용자정보</button>
      -->
      <button class="btn_logout" @click="fnLogout">로그아웃</button>
    </div>
  </header>
</template>

<script>
import Gnb from "~/components/layouts/Gnb.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    Gnb,
  },
  mounted() {
  },
  methods: {
    fnToggleMenu(){
      this.$store.dispatch("ui/setMenuState", !this.$store.state.ui.menuState);
    },
    async fnLogout(){
      if(this.$store.state.ui.popCsState==true){
        this.$store.dispatch('ui/setPopCsState', false);
      }
      const success = await this.$store.dispatch('logout');
      if(success){
        await this.$router.push("/login");
      } else {
        this.$popupAlert({
          title:'로그아웃 실패',
          msg: '죄송합니다. <br/>로그아웃이 되지 않았습니다. <br/> 관리자에게 확인요청바랍니다.',
        });
      }
    }
  },
};
</script>
