<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>새로운 고객</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1">
              <button class="tab on">새로운 고객</button>
            </div>
            <div class="btns">
              <button class="btn" @click="fnSave('newCustomerState')">저장</button>
              <button class="btn dark" @click="fnClosePop('newCustomerState')">취소</button>
            </div>
          </div>
          <div class="c_contents">
            <div class="left">
              <ol>
                <li>
                  <label for="bizNo"><b>1.사업자 번호:</b></label>
                  <InputBox
                    id="bizNo"
                    type='text'
                    name="bizNo"
                    shape='search'
                    placeholder='사업자 번호를 입력하세요.'
                    :value.sync="bizNo"
                    @enter="fnSearch"
                  />
                </li>
                <li>
                  <label for="korentrnm"><b>2. 회사명</b></label>
                  <InputBox
                    id="korentrnm"
                    type='text'
                    name="korentrnm"
                    placeholder='회사명을 입력해주세요.'
                    :value.sync="form.korentrnm"
                  />
                </li>
                <li>
                  <label for="name"><b>3. 대표자명</b></label>
                  <InputBox
                    id='name'
                    type='text'
                    name="korreprnm"
                    placeholder='대표자명을 입력해주세요.'
                    :value.sync="form.korreprnm"
                  />
                </li>
                <li>
                  <label for="name"><b>4. 설립일</b></label>
                  <InputBox
                    id='name'
                    type='text'
                    name="etbDate"
                    placeholder='설립일을 입력해주세요.'
                    :value.sync="form.etbDate"
                  />
                </li>
                <li>
                  <label for="name"><b>5. 전화번호</b></label>
                  <InputBox
                    id='name'
                    type='text'
                    name="tel"
                    placeholder='전화번호를 입력해주세요.'
                    :value.sync="form.tel"
                  />
                </li>
                <li>
                  <label for="name"><b>6. 고객 연락처 (이메일):</b></label>
                  <InputBox
                    id='name'
                    type='text'
                    name="cnsltEmail"
                    placeholder='상담 가능한 이메일을 입력해 주세요.'
                    :value.sync="cnsltEmail"
                  />
                </li>
                <li>
                  <label for="name"><b>7. 고객 연락처 (전화번호):</b></label>
                  <InputBox
                    id:='name'
                    type='text'
                    name="cnsltTel"
                    placeholder='상담 가능한 전화번호를 입력해 주세요. (010-0000-0000)'
                    :value.sync="cnsltTel"
                  />
                </li>
              </ol>
            </div>
            <div class="right">
              <ol>
                <li>
                  <label for="name"><b>8. 특허보유:</b></label>
                  <RadioBox
                    label='예'
                    id=1
                    name='patnYn'
                    :value.sync="patnYn"
                    :defaultValue="true"
                  />
                  <RadioBox
                    label='아니오'
                    id=1
                    name='patnYn'
                    :value.sync="patnYn"
                    :defaultValue="false"
                  />
                </li>
                <li>
                  <label for="name"><b>9. 기업부설연구소(연구전담부서):</b></label>
                  <RadioBox
                    label='예'
                    id=3
                    name='labYn'
                    :value.sync="labYn"
                    :defaultValue="true"
                    @fnChange="fnChange"
                  />
                  <RadioBox
                    label='아니오'
                    id=4
                    name='labYn'
                    :value.sync="labYn"
                    :defaultValue="false"
                    @fnChange="fnChange"
                  />
                </li>
                <li>
                  <label for="name"><b>10. 인증보유(벤처인증, 이노비즈):</b></label>
                  <RadioBox
                    label='예'
                    id=5
                    name='certYn'
                    :value.sync="certYn"
                    :defaultValue="true"
                  />
                  <RadioBox
                    label='아니오'
                    id=6,
                    name='certYn'
                    :value.sync="certYn"
                    :defaultValue="false"
                  />
                </li>
                <li>
                  <label for="name"><b>11. 개인정보처리방침:</b></label>
                  <CheckBox
                    label='개인정보 수집 및 이용에 동의합니다.'
                    id='7'
                    name='agrTm01'
                    :value.sync="agrTm01"
                    :checked="agrTm01"
                  />
                </li>
                <li>
                  <CheckBox
                    label='개인정보 제 3자 제공에 동의합니다.'
                    id='8'
                    name='agrTm02'
                    :value.sync="agrTm02"
                    :checked="agrTm02"
                  />
                </li>
                <li>
                  <CheckBox
                    label='모두 확인 및 동의합니다.'
                    id='9'
                    name='agrTm'
                    :value.sync="agrTm"
                    :checked="agrTm"
                  />
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from '~/components/common/Input';
import ItemPatent from '~/components/common/ItemPatent';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import RadioBox from '~/components/common/RadioBox';
import CheckBox from '~/components/common/CheckBox';

export default {
  data() {
    return {
      bizNo: '',
      form: {
        korentrnm: '',
        korreprnm: '',
        etbDate: '',
        tel: '',
      },
      cnsltEmail: '',
      cnsltTel: '',
      patnYn: null,
      labYn: null,
      certYn: null,
      agrTm01: null,
      agrTm02: null,
      agrTm: null,
      radioB: {
        patnCh: false,
        labCh: false,
        certCh: false,
      },
    };
  },
  watch: {
    agrTm(value) {
      if(value){
        this.agrTm01 = true
        this.agrTm02 = true
      } else if(!value) {
        this.agrTm01 = false
        this.agrTm02 = false
      }
    }
  },
  components: {
    InputBox,
    ItemPatent,
    Select,
    SelectSearch,
    RadioBox,
    CheckBox,
  },
  mounted() {},
  methods: {
    fnClosePop(payload) {
      this.$store.dispatch('ui/setPopState', { key: payload, value: false });
    },
    async fnSearch() {
      if (!this.bizNo) {
        this.$popupAlert({
          title: '경고 : 사업자 번호',
          msg: '사업자 번호를 입력하여 주십시오.',
        });
        return;
      } else {
        const params = { bizNo: this.bizNo };
        const result = await this.$store.dispatch('allOk/allOkStore/fetchCorpList', params);
        if (result.result) {
          this.form = result.data.contents;
        } else {
          this.$popupAlert({
            title: '경고 : 사업자 번호',
            msg: '사업자 번호를 조회할 수 없습니다.',
          });
          return;
        }
      }
    },
    async fnSave(payload) {
      if (!this.bizNo) {
        this.$popupAlert({
          title: '경고 : 사업자 번호',
          msg: '사업자 번호를 입력하여 주십시오.',
        });
        return;
      } else if (!this.form.korentrnm) {
        this.$popupAlert({
          title: '경고 : 회사명',
          msg: '회사명을 입력하여 주십시오.',
        });
        return;
      } else if (!this.form.korreprnm) {
        this.$popupAlert({
          title: '경고 : 대표자명',
          msg: '대표자명을 입력하여 주십시오.',
        });
        return;
      } else if (!this.form.etbDate) {
        this.$popupAlert({
          title: '경고 : 설립일',
          msg: '설립일을 입력하여 주십시오.',
        });
        return;
      } else if (!this.form.tel) {
        this.$popupAlert({
          title: '경고 : 전화번호',
          msg: '전화번호를 입력하여 주십시오.',
        });
        return;
      } else if (!this.$validate('email', this.cnsltEmail)) {
        this.$popupAlert({
          title: '경고 : 고객 연락처',
          msg: '이메일형식의 연락처를 입력하여 주십시오.',
        });
        return;
      } else if (!this.$validate('phone', this.cnsltTel)) {
        this.$popupAlert({
          title: '경고 : 고객 연락처',
          msg: '전화번호형식의 연락처를 입력하여 주십시오.',
        });
        return;
      } else if (!this.radioB.patnCh) {
        this.$popupAlert({
          title: '경고 : 특허보유 유무',
          msg: '특허보유 유무를 확인하여 주십시오.',
        });
        return;
      } else if (!this.radioB.labCh) {
        this.$popupAlert({
          title: '경고 : 기업부설 연구소 보유 유무',
          msg: '기업부설 연구소 보유 유무를 확인하여 주십시오.',
        });
        return;
      } else if (!this.radioB.certCh) {
        this.$popupAlert({
          title: '경고 : 인증서 보유 유무',
          msg: '인증서 보유 유무를 확인해 주십시오..',
        });
        return;
      } else if (!this.agrTm01) {
        this.$popupAlert({
          title: '경고 : 개인정보처리방침',
          msg: '개인정보 수집 및 이용에 동의하여 주십시오.',
        });
        return;
      } else if (!this.agrTm02) {
        this.$popupAlert({
          title: '경고 : 개인정보처리방침',
          msg: '개인정보 제 3자 제공에 대해 동의하여 주십시오.',
        });
        return;
      } else {
        const params = {
          biz_no: this.bizNo,
          comp_nm: this.form.korentrnm,
          prst_nm: this.form.korreprnm,
          fnd_dt: this.form.etbDate,
          comp_tel: this.form.tel,
          client_email: this.cnsltEmail,
          client_tel: this.cnsltTel,
          patn_yn: this.patnYn,
          lab_yn: this.labYn,
          cert_yn: this.certYn,
          agr_tm_01: this.agrTm01,
          agr_tm_02: this.agrTm02,
          cmpgn_cd: null
        };
        const result = await this.$store.dispatch('allOk/allOkStore/fetchAddCustomer', params);
        if(result.result){
          await this.$emit('reload');
          await this.$store.dispatch('ui/setPopState', { key: payload, value: false });
        } else {
          this.$popupAlert({
            title: result.data.error.cd,
            msg: result.data.error.msg,
          });
          return;
        }
      }
    },
    fnChange(payload) {
      if (payload.name == 'patnYn') {
        this.patnYn = payload.value;
        this.radioB.patnCh = true;
      } else if (payload.name == 'labYn') {
        this.labYn = payload.value;
        this.radioB.labCh = true;
      } else if (payload.name == 'certYn') {
        this.certYn = payload.value;
        this.radioB.certCh = true;
      }
    },
    fnOnChange(payload) {
      if (payload.name == 'agrTm01') {
        this.agrTm01 = payload.value;
      } else if (payload.name == 'agrTm02') {
        this.agrTm02 = payload.value;
      } else if (payload.name == 'agrTm') {
        this.agrTm01 = payload.value;
        this.agrTm02 = payload.value;
      }
    },
  },
};
</script>
