var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup p_create"},[_c('div',{staticClass:"p_wrap"},[_c('div',{staticClass:"p_top"},[_c('h2',[_vm._v("새로운상품")]),_vm._v(" "),_c('button',{staticClass:"btn_close",on:{"click":_vm.fnClosePop}},[_vm._v("닫기")])]),_vm._v(" "),_c('div',{staticClass:"p_container"},[_c('div',{staticClass:"p_inner"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"c_contents contents_col2"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"form_list"},[_c('li',[_vm._m(1),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name', placeholder: '의료용 로봇 및 의료서비스 로봇 특허' }}})],1),_vm._v(" "),_c('li',[_vm._m(2),_vm._v(" "),_c('InputBox',{attrs:{"data":{ shape: 'file', placeholder: '파일을 선택하세요.' }}}),_vm._v(" "),_vm._m(3)],1),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('li',[_vm._m(5),_vm._v(" "),_c('Select',{attrs:{"title":"선택해주세요.","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(6),_vm._v(" "),_c('InputBox',{attrs:{"data":{ type: 'text', id: 'name', placeholder: '협의 후 결정' }}})],1),_vm._v(" "),_c('li',[_vm._m(7),_vm._v(" "),_c('Select',{attrs:{"title":"장기대여 (10년 이상)","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(8),_vm._v(" "),_c('Select',{attrs:{"title":"분기 1회 납부","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(9),_vm._v(" "),_c('Select',{attrs:{"title":"현금 지급","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(10),_vm._v(" "),_c('Select',{attrs:{"title":"로열티(월 단위)만 정산","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1)])]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('ul',{staticClass:"form_list"},[_c('li',[_vm._m(11),_vm._v(" "),_c('Select',{attrs:{"title":"단일특허","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(12),_vm._v(" "),_c('div',{staticClass:"list_patent"},[_c('ItemPatent'),_vm._v(" "),_c('ItemPatent',{attrs:{"data":{id:'KR0555358B1', tit:'동적 번역자원을 이용한 분야 적응형 휴대용 방송자막기계번역 장치 및 방법', disabled:true}}}),_vm._v(" "),_c('ItemPatent',{attrs:{"data":{id:'KR0837358B1', tit:'동적 번역자원을 이용한 분야 적응형 휴대용 방송자막기계번역 장치 및 방법', disabled:true}}})],1)]),_vm._v(" "),_c('li',[_vm._m(13),_vm._v(" "),_c('Select',{attrs:{"title":"1레벨 산업 분류","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}}),_vm._v(" "),_c('Select',{attrs:{"title":"2레벨 산업 분류","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(14),_vm._v(" "),_c('Select',{attrs:{"title":"1레벨 인기테마","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}}),_vm._v(" "),_c('Select',{attrs:{"title":"2레벨 인기테마","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(15),_vm._v(" "),_c('Select',{attrs:{"title":"경쟁력, 투자성","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(16),_vm._v(" "),_c('Select',{attrs:{"title":"경쟁력, 투자성","list":[
                  {name: '셀렉트1'},
                  {name: '셀렉트2'},
                  {name: '셀렉트3'},
                  {name: '셀렉트4'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(17),_vm._v(" "),_c('Select',{attrs:{"title":"경쟁력, 투자성","list":[
                  {name: 'Y', select:true},
                  {name: 'N'},
                ]}})],1),_vm._v(" "),_c('li',[_vm._m(18),_vm._v(" "),_c('Select',{attrs:{"title":"전기수소차 충전, 그린배터리","list":[
                  {name: 'Y', select:true},
                  {name: 'N'},
                ]}})],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_top"},[_c('div',{staticClass:"tabs1"},[_c('button',{staticClass:"tab on"},[_vm._v("상품상세")]),_vm._v(" "),_c('button',{staticClass:"tab"},[_vm._v("거래/협의내역목록")])]),_vm._v(" "),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn",attrs:{"to":""}},[_vm._v("저장 후 계속")]),_vm._v(" "),_c('button',{staticClass:"btn",attrs:{"to":""}},[_vm._v("저장")]),_vm._v(" "),_c('button',{staticClass:"btn dark",attrs:{"to":""}},[_vm._v("취소")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("1.이름")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("2.이미지")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bx_img"},[_c('img',{staticClass:"img",attrs:{"src":"","alt":"첨부한 이미지"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("3. 상품 설명")])]),_vm._v(" "),_c('textarea',{staticClass:"textarea",attrs:{"placeholder":"입력해주세요"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("4. 등급")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("5. 가격")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("6. 거래 방식")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("7. 거래 조건")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("8. 지금 조건")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("9. 라이선스 조건")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("10. 상품의 형태")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"colb"},[_c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("11. 상품에 구성된 특허")])]),_vm._v(" "),_c('div',{staticClass:"btns_ico"},[_c('button',{staticClass:"btn dark"},[_vm._v("-")]),_vm._v(" "),_c('button',{staticClass:"btn"},[_vm._v("+")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("12. 산업분류")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("13. 인기테마")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("14. 추천 형태")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("15. 시장성 타입")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("16. 메인 노출 여부")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('b',[_vm._v("17. 태그")])])
}]

export { render, staticRenderFns }