<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>공지사항 상세</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner class="mt5 mr5" v-if="loading" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="$emit('closePop')">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>제목</h4>
                  <div class="i_wrap">
                    <Input class="mt5" :value.sync="body.Title" name="body.Title" type="text" placeholder="제목을 입력해주세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>내용</h4>
                  <div class="i_wrap">
                    <client-only placeholder="loading...">
                      <ckeditor-nuxt v-model="body.Content" :config="editorConfig" />
                    </client-only>
                  </div>
                </li>
              </ul>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noticeService from '~/service/Cms/noticeService';
import Input from '~/components/common/Input';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  props: ['selectedNotice'],
  components: {
    Input,
    LoadingSpinner,
    'ckeditor-nuxt': () => {
      if (process.client) return import('@blowstack/ckeditor-nuxt');
    },
  },

  data() {
    return {
      noticeNo: this.selectedNotice,
      editorConfig: {
        removePlugins: ['Title', 'MediaEmbedToolbar'],
        simpleUpload: {
          uploadUrl: '/api/cms/upload/ur/editor',
        },
      },
      body: {
        Title: null,
        Content: '',
      },
      loading: false,
    };
  },

  methods: {
    async fnFetchNotice() {
      this.loading = true;
      const { result, data } = await noticeService.getNoticeDetail(this.$axios, this.noticeNo);
      this.loading = false;
      if (result) {
        this.body.Title = data.contents.attributes.Title;
        this.body.Content = data.contents.attributes.Content || '';
      } else {
        this.$popupAlert({
          msg: `공지사항 정보를 가져오는데 실패했습니다(${data.error.msg})`,
        });
        this.$emit('closePop');
      }
    },
    async fnClickSave(skipClose) {
      if (this.loading) return;
      if (!this.body.Title) {
        this.$popupAlert({
          msg: '제목을 입력하세요',
        });
        return;
      }
      if (!this.body.Content) {
        this.$popupAlert({
          msg: '내용을 입력하세요',
        });
        return;
      }

      this.loading = true;
      const { result, data } = await noticeService.updateNotice(this.$axios, this.noticeNo, this.body);
      this.loading = false;
      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '공지사항이 수정되었습니다',
        });

        this.body.Title = data.contents.attributes.Title;
        this.body.Content = data.contents.attributes.Content || '';

        this.$emit('reloadData');
      } else {
        this.$popupAlert({
          msg: `공지사항을 수정하는데 실패했습니다(${data.error.msg})`,
        });
      }

      if (!skipClose) this.$emit('closePop');
    },
  },

  created() {
    this.fnFetchNotice();
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
