<template>
  <div class="search_date" :class="{ on: onOff }">
    <div class="s_top">
      <span class="start">
        {{ selectedDate.year }}-{{ selectedDate.month ? $fill0(selectedDate.month) : null }}-{{
          selectedDate.date ? $fill0(selectedDate.date) : null
        }}
      </span>
    </div>
    <div class="s_cont">
      <div class="item">
        <div class="bx">
          <Select
            defaultName="년"
            :itemList="yearList"
            classNm="ipt_year"
            :value.sync="selectedDate.year"
            @change="
              () => {
                this.selectedDate.month = null;
                this.selectedDate.date = null;
              }
            "
          />
          <Select
            defaultName="월"
            :itemList="monthList"
            classNm="ipt_month"
            :value.sync="selectedDate.month"
            @change="
              () => {
                this.selectedDate.date = null;
              }
            "
          />
          <Select defaultName="일" :itemList="dateList" classNm="ipt_day" :value.sync="selectedDate.date" />
        </div>
      </div>
    </div>
    <div class="colb mr5 mb5">
      <div class="col"></div>
      <div class="col">
        <button
          class="btn"
          @click="
            e => {
              e.stopPropagation();
              this.fnPropagationDate();
            }
          "
        >
          확인
        </button>
        <button
          class="btn l_gray"
          @click="
            e => {
              e.stopPropagation();
              this.selectedDate = this.fnParseDate(this.value);
              this.$emit('cancel');
            }
          "
        >
          취소
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '~/components/common/Select';

export default {
  props: ['onOff', 'value'],
  components: {
    Select,
  },
  data() {
    return {
      minYear: 2022,
      maxYear: 2030,
      minMonth: 1,
      maxMonth: 12,
      minDate: 1,
      selectedDate: {
        year: null,
        month: null,
        date: null,
      },
    };
  },
  computed: {
    yearList() {
      const min = this.minYear;
      const max = this.maxYear;
      return this.fnSelectList(min, max, '년');
    },
    monthList() {
      const min = this.minMonth;
      const max = this.maxMonth;
      return this.fnSelectList(min, max, '월');
    },
    dateList() {
      const min = this.minDate;
      const max = this.fnMaxDate();
      return this.fnSelectList(min, max, '일');
    },
  },
  methods: {
    fnPropagationDate() {
      if (!this.selectedDate.year) {
        this.$popupAlert({
          msg: '년도를 선택하세요',
        });
        return null;
      }
      if (!this.selectedDate.month) {
        this.$popupAlert({
          msg: '월을 선택하세요',
        });
        return null;
      }
      if (!this.selectedDate.date) {
        this.$popupAlert({
          msg: '일을 선택하세요',
        });
        return null;
      }

      this.$emit(
        'date',
        `${this.selectedDate.year}-${this.$fill0(this.selectedDate.month)}-${this.$fill0(this.selectedDate.date)}`,
      );
    },

    fnParseDate(yyyyMMdd) {
      if (this.checkValidDate(yyyyMMdd)) {
        const splitDate = yyyyMMdd.split('-');
        return {
          year: parseInt(splitDate[0]),
          month: parseInt(splitDate[1]),
          date: parseInt(splitDate[2]),
        };
      }
      return {
        year: null,
        month: null,
        date: null,
      };
    },

    checkValidDate(value) {
      var result = true;
      try {
        var date = value.split('-');
        var y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        var dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + '-' + m + '-' + y);
      } catch (err) {
        result = false;
      }
      return result;
    },

    fnSelectList(min, max, unit) {
      let list = [];
      if (min && max) {
        for (let a = min; a <= max; a++) {
          list.push({
            name: a + ' ' + unit,
            value: a,
          });
        }
      }
      return list;
    },

    fnMaxDate() {
      if (this.selectedDate.year && this.selectedDate.month) {
        return new Date(this.selectedDate.year, this.selectedDate.month, 0).getDate();
      }
      return 31;
    },
  },

  watch: {
    value(nV) {
      this.selectedDate = this.fnParseDate(nV);
    },
  },
};
</script>
