<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="searchWrd">종목명<br />종목코드</label>
          <Input id="searchWrd" :value.sync="search.searchWrd" name="search.searchWrd" type="text" placeholder="종목명 또는 종목코드를 입력하세요" @enter="fnClickSearch" />
        </div>
        <div class="unit">
          <label class="tit" for="searchSectorNo">섹터</label>
          <SelectSearch id="searchSectorNo" :itemList="sectorList" :value.sync="search.searchSectorNo" name="search.searchSectorNo" />
        </div>
        <div class="unit">
          <label class="tit" for="searchThemeNo">대표테마</label>
          <SelectSearch id="searchThemeNo" :itemList="themeList" :value.sync="search.searchThemeNo" name="search.searchThemeNo" />
        </div>
        <div class="unit">
          <label class="tit" for="searchBelongThemeNo">소속테마</label>
          <SelectSearch id="searchBelongThemeNo" :itemList="themeList" :value.sync="search.searchBelongThemeNo" name="search.searchBelongThemeNo" />
        </div>
        <div class="unit">
          <label class="tit" for="techFincGrade">기술재무등급</label>
          <Select id="techFincGrade" :itemList="techFincGradeList" :value.sync="search.techFincGrade" name="search.techFincGrade" />
        </div>
        <div class="unit">
          <label class="tit" for="arrantrdYn">정리매매</label>
          <Select id="arrantrdYn" :itemList="arrantrdYnList" :value.sync="search.arrantrdYn" name="search.arrantrdYn" />
        </div>
        <div class="unit">
          <label class="tit" for="nonSectorYn">섹터 미보유</label>
          <CheckBox
            label="<br />"
            name="nonSectorYn"
            @change="
              checked => {
                this.search.nonSectorYn = checked ? 'Y' : null;
              }
            " />
        </div>
        <div class="unit">
          <label class="tit" for="nonThemeYn">대표테마<br />미보유</label>
          <CheckBox
            label="<br />"
            name="nonThemeYn"
            @change="
              checked => {
                this.search.nonThemeYn = checked ? 'Y' : null;
              }
            " />
        </div>
        <div class="unit"></div>
        <div class="unit colb">
          <div></div>
          <button class="btn" @click="fnClickSearch">검색</button>
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnClickOpen">열기</button>
          <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <StockDetailPop
              v-if="popStateDetail == true"
              :key="popStateDetail"
              :selectedIsuSrtCd="selectedIsuSrtCd"
              @reloadData="fnFetchStk"
              @closePop="
                () => {
                  this.fnChangePopState('popStateDetail', false);
                }
              " />
          </transition>
          <strong class="ml10 mt15">
            검색결과 : 현재페이지 [ {{ Math.min(form.pageSize * (form.pageNo - 1) + 1, pagination.totalCount) }} ~ {{ Math.min(form.pageSize * form.pageNo, pagination.totalCount) }} ] / 전체 : [
            {{ pagination.totalCount }} ]
          </strong>
        </div>
        <div class="col unit_page">
          <LoadingSpinner class="mr5" v-if="loading" />
          <h4 class="l_tit ml10">페이지당 개수 :</h4>
          <Select
            id="pageSize"
            title="20개"
            :itemList="pageSizeList"
            :value="form.pageSize"
            @change="
              item => {
                if (!this.loading) {
                  this.form.pageNo = 1;
                  this.form.pageSize = item.value;
                  this.fnFetchStk();
                }
              }
            " />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col v-for="(el, idx) in headers" :key="idx" :width="el.colWidth" />
            </colgroup>
            <thead>
              <tr>
                <th v-for="(el, idx) in headers" :key="idx">
                  <b>{{ el.colName }}</b>
                  <div class="bx_srot" v-if="el.colSortNm">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'ASC' })">
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'DESC' })">
                      내림차순정렬
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.isuSrtCd" :class="{ on: item.isuSrtCd === selectedIsuSrtCd }" @click="fnClickStk(item.isuSrtCd)" @dblclick="fnClickOpen">
                <td>{{ item.isuSrtCd }}</td>
                <td>{{ item.isuKorNm }}</td>
                <td>{{ item.listDd && fnFormatDate(item.listDd) }}</td>
                <td>{{ item.ofrPrc && fnFormatNumber(item.ofrPrc) }}</td>
                <td>{{ item.corp.tii && item.corp.tii.toFixed(2) }}</td>
                <td>{{ item.corp.tss && item.corp.tss.toFixed(2) }}</td>
                <td>{{ item.corp.tcg }}</td>
                <td>{{ item.corp.apes && item.corp.apes.toFixed(2) }}</td>
                <td>{{ item.corp.patentCount }}</td>
                <td>{{ item.corp.assdValue }}</td>
                <td>{{ item.corp.mktCap && fnFormatNumber(item.corp.mktCap) }}</td>
                <td>{{ item.corp.rprSectorNm }}</td>
                <td>{{ item.corp.rprThemeNm }}</td>
              </tr>
              <tr v-if="pagination.totalCount == 0">
                <td colspan="11">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              if (!this.loading) {
                this.form.pageNo = p;
                this.fnFetchStk();
              }
            }
          " />
      </div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import StockDetailPop from '~/components/pages/tenbagger/stock/StockDetail';
import Pagination from '~/components/common/Pagination';
import Input from '~/components/common/Input.vue';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';
import CheckBox from '~/components/common/CheckBox';

export default {
  components: {
    Location,
    StockDetailPop,
    Pagination,
    Input,
    Select,
    SelectSearch,
    LoadingSpinner,
    CheckBox,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_STOCK'],
      headers: [
        { colName: '종목코드', colSortNm: 'ISU_SRT_CD', colWidth: '7%' },
        { colName: '종목명', colSortNm: 'ISU_KOR_NM', colWidth: '11%' },
        { colName: '상장일자', colSortNm: 'LIST_DD', colWidth: '7%' },
        { colName: '공모가(원)', colSortNm: null, colWidth: '6%' },
        { colName: '기술투자지수(PTSR)', colSortNm: 'TII', colWidth: '11%' },
        { colName: 'TSS', colSortNm: 'TSS', colWidth: '6%' },
        { colName: 'TCG', colSortNm: 'TCG', colWidth: '6%' },
        { colName: 'APES', colSortNm: 'APES', colWidth: '6%' },
        { colName: '특허보유(건)', colSortNm: 'PATENT', colWidth: '9%' },
        { colName: '기업가치추정', colSortNm: null, colWidth: '7%' },
        { colName: '시가총액(억원)', colSortNm: 'MKT_CAP', colWidth: '10%' },
        { colName: '섹터', colSortNm: null, colWidth: '8%' },
        { colName: '대표테마', colSortNm: null, colWidth: '14%' },
      ],
      form: {
        pageNo: 1,
        pageSize: 10, // 한 페이지에 보여줄 rows의 갯수
        searchWrd: null,
        searchSectorNo: null,
        searchThemeNo: null,
        searchBelongThemeNo: null,
        techFincGrade: null,
        arrantrdYn: null,
        nonSectorYn: null,
        nonThemeYn: null,
        sortColumn: null,
        sortOrdr: null,
      },
      search: {
        searchWrd: null,
        searchSectorNo: null,
        searchThemeNo: null,
        searchBelongThemeNo: null,
        techFincGrade: null,
        arrantrdYn: null,
        nonSectorYn: null,
        nonThemeYn: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      selectedIsuSrtCd: null,
      popStateDetail: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '50개', value: 50 },
        { name: '100개', value: 100 },
      ],
      arrantrdYnList: [
        { name: '전체', value: null },
        { name: 'Y', value: 'Y' },
        { name: 'N', value: 'N' },
      ],
      nonYnList: [
        { name: '--', value: null },
        { name: 'Y', value: 'Y' },
      ],
      techFincGradeList: [
        { name: '전체', value: null },
        { name: '1', value: '1' },
        { name: '2', value: '2' },
        { name: '3', value: '3' },
        { name: '4', value: '4' },
        { name: '5', value: '5' },
      ],
      loading: false,
    };
  },

  computed: {
    sectorList() {
      return [{ name: '전체', value: null }, ...this.$store.state.tenbagger.stock.sectorList];
    },
    themeList() {
      return [{ name: '전체', value: null }, ...this.$store.state.tenbagger.stock.themeList];
    },
  },

  methods: {
    fnClickStk(isuSrtCd) {
      this.selectedIsuSrtCd = isuSrtCd;
    },
    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchStk();
      }
    },
    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchStk();
      }
    },
    async fnFetchStk() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('tenbagger/stock/fetchStk', this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
        this.selectedIsuSrtCd = null;
      } else {
        this.$popupAlert({
          msg: `종목리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },
    fnClickOpen() {
      if (!this.selectedIsuSrtCd) {
        this.$popupAlert({
          msg: '종목을 선택해주세요',
        });
      } else {
        this.fnOpenPop('popStateDetail');
      }
    },
    fnOpenPop(key) {
      // this.$store.dispatch('ui/setMenuState', false);
      this.fnChangePopState(key, true);
    },
    fnChangePopState(key, pop) {
      this[key] = pop;
    },
    fnFormatDate(date) {
      return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3');
    },
    fnFormatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

  mounted() {
    this.$store.dispatch('tenbagger/stock/fetchSectorList');
    this.$store.dispatch('tenbagger/stock/fetchThemeList');
    this.fnFetchStk();
  },
};
</script>
