<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>새로운 상품</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner v-if="loading" class="mt5 mr5" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>이름</h4>
                  <div class="i_wrap">
                    <Input class="mt5" id="prtfNm" :value.sync="form.prtfNm" name="form.prtfNm" type="text" placeholder="상품명을 입력하세요." />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>상품의 형태</h4>
                  <div class="i_wrap">
                    <Select
                      :itemList="prdcTypes"
                      :value.sync="form.prdcType"
                      name="form.prdcType"
                      @change="
                        () => {
                          this.selectedPatn = [];
                          this.selectedGrade = null;
                        }
                      "
                    />
                  </div>
                </li>
                <li>
                  <h4>구성된 특허<button class="btn_search type2 ml10" @click="fnChangePopState(true)">검색</button></h4>
                  <div class="list_patent ml5">
                    <ItemPatent v-for="(el, idx) in selectedPatn" :key="el.patn" :data="{ id: el.patn, tit: el.patnTitle }" @delete="fnRemovePatn(idx)" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>이미지</h4>
                  <div class="i_wrap">
                    <InputFile id="file" name="form.file" placeholder="파일을 선택하세요" :value.sync="form.file" />
                  </div>
                </li>
                <li>
                  <h4>설명</h4>
                  <div class="i_wrap">
                    <textarea id="issue" class="textarea" placeholder="설명을 입력해주세요" style="height: 200px" v-model="form.prdcDesc"></textarea>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>등급</h4>
                  <div class="i_wrap">
                    <Input v-if="form.prdcType == 'PT01'" :value="selectedPatn[0] ? selectedPatn[0].evGrade : null" name="evGrade" type="text" placeholder="-" :disabled="true" />
                    <Select v-if="form.prdcType == 'PT02'" :itemList="grades" :value.sync="selectedGrade" name="selectedGrade" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>가격</h4>
                  <div class="i_wrap">
                    <Input class="mt5" id="prdcPrice" :value.sync="form.prdcPrice" name="form.prdcPrice" type="text" placeholder="가격을 입력하세요" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>거래방식</h4>
                  <div class="i_wrap">
                    <Select :itemList="trdMthd" :value.sync="form.trdMthd" name="form.trdMthd" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>거래조건</h4>
                  <div class="i_wrap">
                    <Select :itemList="trdTrms" :value.sync="form.trdTrms" name="form.trdTrms" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>지급조건</h4>
                  <div class="i_wrap">
                    <Select :itemList="pymnTrms" :value.sync="form.pymnTrms" name="form.pymnTrms" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>라이선스 조건</h4>
                  <div class="i_wrap">
                    <Select :itemList="lcnsType" :value.sync="form.lcnsType" name="form.lcnsType" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>추천 형태</h4>
                  <div class="i_wrap">
                    <Select :itemList="rcmdTypes" name="rcmdTypes" @change="fnAddRcmdTypes" />
                    <div v-for="(el, idx) in form.rcmdTypes" :key="idx" class="btns_ico mr5 mt5">
                      <span class="f13">{{ fnGetCodeName('RT00', el) }}</span>
                      <button class="btn dark" @click="fnRemoveRcmdTypes(idx)">-</button>
                    </div>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>시장성 타입</h4>
                  <div class="i_wrap">
                    <Select :itemList="mktType" :value.sync="form.mktType" name="form.mktType" />
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>메인 노출 여부</h4>
                  <div class="i_wrap">
                    <Select
                      :itemList="[
                        { name: 'N', value: 'N' },
                        { name: 'Y', value: 'Y' },
                      ]"
                      :value.sync="form.mainDispYN"
                      name="form.mainDispYN"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="right">
              <ul class="form_list type2">
                <li class="item_ipt">
                  <h4>산업분류<br />(N개 선택)</h4>
                  <div class="i_wrap mt5">
                    <div class="cole">
                      <SelectSearch
                        classNm="half"
                        :itemList="industry1"
                        :value.sync="tempIndustry1"
                        name="tempIndustry1"
                        @change="
                          () => {
                            this.tempIndustry2 = null;
                          }
                        "
                      />
                      <SelectSearch classNm="half" :itemList="industry2" :value.sync="tempIndustry2" name="tempIndustry2" />
                      <button class="btn" @click="fnAddIndustry">+</button>
                    </div>
                    <ul style="width: 100%">
                      <li v-for="(el, idx) in selectedIndustry" :key="idx">
                        <div class="i_wrap colb">
                          <span class="f14 mr5">{{ fnGetCatgName('industry', el) }}</span>
                          <button class="btn dark" @click="fnRemoveIndustry(idx)">-</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="item_ipt">
                  <h4>인기테마<br />(N개 선택)</h4>
                  <div class="i_wrap mt5">
                    <div class="cole">
                      <SelectSearch
                        classNm="half"
                        :itemList="theme1"
                        :value.sync="tempTheme1"
                        name="tempTheme1"
                        @change="
                          () => {
                            this.tempTheme2 = null;
                          }
                        "
                      />
                      <SelectSearch classNm="half" :itemList="theme2" :value.sync="tempTheme2" name="tempTheme2" />
                      <button class="btn" @click="fnAddTheme">+</button>
                    </div>
                    <ul style="width: 100%">
                      <li v-for="(el, idx) in selectedTheme" :key="idx">
                        <div class="i_wrap colb">
                          <span class="f14 mr5">{{ fnGetCatgName('theme', el) }}</span>
                          <button class="btn dark" @click="fnRemoveTheme(idx)">-</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <PatentSelect v-if="popState" :selectedPatn.sync="selectedPatn" :selectType="form.prdcType" @closePop="fnChangePopState(false)" />
    </transition>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import InputFile from '~/components/common/InputFile';
import Select from '~/components/common/Select';
import SelectSearch from '~/components/common/SelectSearch';
import PatentSelect from '~/components/pages/openip/product/PatentSelect';
import ItemPatent from '~/components/common/ItemPatent.vue';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  components: {
    Input,
    InputFile,
    Select,
    SelectSearch,
    PatentSelect,
    ItemPatent,
    LoadingSpinner,
  },

  data() {
    return {
      loading: false,
      popState: false,
      form: {
        prtfNm: null,
        prdcType: 'PT01',
        file: null,
        prdcDesc: null,
        prdcPrice: null,
        trdMthd: null,
        trdTrms: null,
        pymnTrms: null,
        lcnsType: null,
        rcmdTypes: [],
        mktType: null,
        mainDispYN: 'N',
        patnNo: null,
        prtfNo: null,
      },
      selectedGrade: null,
      selectedPatn: [],
      selectedIndustry: [],
      selectedTheme: [],

      tempIndustry1: null,
      tempIndustry2: null,
      tempTheme1: null,
      tempTheme2: null,
    };
  },

  computed: {
    prdcTypes() {
      return this.$store.state.openIp.common.PT00 ? this.$store.state.openIp.common.PT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },
    grades() {
      return this.$store.state.openIp.etc.grade ? this.$store.state.openIp.etc.grade.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    trdMthd() {
      return this.$store.state.openIp.common.TM00 ? this.$store.state.openIp.common.TM00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    trdTrms() {
      return this.$store.state.openIp.common.TT00 ? this.$store.state.openIp.common.TT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    pymnTrms() {
      return this.$store.state.openIp.common.PTR00 ? this.$store.state.openIp.common.PTR00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    lcnsType() {
      return this.$store.state.openIp.common.LT00 ? this.$store.state.openIp.common.LT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    rcmdTypes() {
      return this.$store.state.openIp.common.RT00 ? this.$store.state.openIp.common.RT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    mktType() {
      return this.$store.state.openIp.common.MT00 ? this.$store.state.openIp.common.MT00.map(el => ({ name: el.codeName, value: el.code })) : [];
    },

    industry1() {
      let category = this.$store.state.openIp.catgStore.category;
      let industry = category.industry.map(el => ({ name: el.catgNm, value: el.catgNo }));
      return industry;
    },

    industry2() {
      let category = this.$store.state.openIp.catgStore.category;
      const foundGrp = category.industry.find(el => {
        return el.catgNo === this.tempIndustry1;
      });
      if (foundGrp != null) return foundGrp.sub.map(el => ({ name: el.catgNm, value: el.catgNo }));
      return [];
    },

    theme1() {
      let category = this.$store.state.openIp.catgStore.category;
      let theme = category.theme.map(el => ({ name: el.catgNm, value: el.catgNo }));
      return theme;
    },

    theme2() {
      let category = this.$store.state.openIp.catgStore.category;
      const foundGrp = category.theme.find(el => {
        return el.catgNo === this.tempTheme1;
      });
      if (foundGrp != null) return foundGrp.sub.map(el => ({ name: el.catgNm, value: el.catgNo }));
      return [];
    },
  },

  methods: {
    fnChangePopState(value) {
      this.popState = value;
    },

    fnClosePop() {
      this.$emit('closePop');
    },

    async fnClickSave(skipClose) {
      if (this.loading) return;

      if (!this.form.prtfNm) {
        this.$popupAlert({
          msg: '상품명을 입력하세요',
        });
        return;
      }

      if (this.selectedPatn.length < 1) {
        this.$popupAlert({
          msg: '특허를 선택하세요',
        });
        return;
      }

      this.loading = true;

      if (this.form.prdcType == 'PT01') {
        this.form.patnNo = this.selectedPatn[0].patnNo;
        const result = await this.fnAddProduct();
        if (result) {
          this.$popupAlert({
            msg: `상품등록이 완료되었습니다`,
          });
        } else {
          this.loading = false;
          return;
        }
      }

      if (this.form.prdcType == 'PT02') {
        for (const item of this.selectedPatn) {
          let form = {
            patnNo: item.patnNo,
            mainDispYN: 'N',
            prdcType: 'PT01',
            prtfNm: item.patnTitle,
            prdcDesc: item.patnDesc,
          };
          const res = await this.$store.dispatch('openIp/prdcStore/addPrdc', form);
          if (!res.result) {
            if (res.data.error.cd != '4001') {
              this.$popupAlert({
                msg: `포트폴리오 특허 상품등록에 실패하였습니다(${res.data.error.msg})`,
              });
              this.loading = false;
              return;
            }
          }
        }

        let patns = this.selectedPatn.map(el => el.patnNo);
        let prtfForm = {
          patns: patns,
          prtfEvGrade: this.selectedGrade,
        };
        const res = await this.$store.dispatch('openIp/prdcStore/addPrtf', prtfForm);
        if (res.result) {
          let prtfNo = res.data.contents;
          this.form.prtfNo = prtfNo;
          let result = await this.fnAddProduct();
          if (result) {
            this.$popupAlert({
              msg: `상품등록이 완료되었습니다`,
            });
          } else {
            this.loading = false;
            return;
          }
        } else {
          this.$popupAlert({
            msg: `포트폴리오 등록에 실패하였습니다(${res.data.error.msg})`,
          });
          this.loading = false;
          return;
        }
      }
      this.$emit('reloadData');

      if (skipClose) {
        this.resetAll();
        this.loading = false;
      } else {
        this.fnClosePop();
      }
    },

    async fnAddProduct() {
      const { result, data } = await this.$store.dispatch('openIp/prdcStore/addPrdc', this.form);
      if (result) {
        let prdcNo = data.contents;
        let catgList = [...this.selectedIndustry, ...this.selectedTheme];
        for (const item of catgList) {
          let form = {
            prdcs: prdcNo,
            catgNo: item,
          };
          const res = await this.$store.dispatch('openIp/catgStore/addCatgPrdc', form);
          if (!res.result) {
            this.$popupAlert({
              msg: `상품 카테고리등록에 실패했습니다`,
            });
            return false;
          }
        }
        return true;
      } else {
        if (data.error.cd == '4001') {
          this.$popupAlert({
            msg: `이미 등록된 상품입니다`,
          });
        } else {
          this.$popupAlert({
            msg: `상품을 추가하는데 실패했습니다(${data.error.msg})`,
          });
        }
        return false;
      }
    },

    resetAll() {
      this.form = {
        prtfNm: null,
        prdcType: 'PT01',
        file: null,
        prdcDesc: null,
        prdcPrice: null,
        trdMthd: null,
        trdTrms: null,
        pymnTrms: null,
        lcnsType: null,
        rcmdTypes: [],
        mktType: null,
        mainDispYN: 'N',
        patnNo: null,
        prtfNo: null,
      };

      this.selectedGrade = null;
      this.selectedPatn = [];
      this.selectedIndustry = [];
      this.selectedTheme = [];

      this.tempIndustry1 = null;
      this.tempIndustry2 = null;
      this.tempTheme1 = null;
      this.tempTheme2 = null;
    },

    fnRemovePatn(index) {
      this.selectedPatn.splice(index, 1);
    },

    fnAddRcmdTypes({ value }) {
      if (this.form.rcmdTypes.indexOf(value) != -1) {
        this.$popupAlert({
          msg: '이미 추가된 타입입니다',
        });
        return;
      }
      this.form.rcmdTypes.push(value);
    },

    fnRemoveRcmdTypes(idx) {
      this.form.rcmdTypes.splice(idx, 1);
    },

    fnAddIndustry() {
      if (this.tempIndustry2) {
        if (this.selectedIndustry.indexOf(this.tempIndustry2) != -1) {
          this.$popupAlert({
            msg: '이미 선택한 카테고리입니다',
          });
          return;
        }
        this.selectedIndustry.push(this.tempIndustry2);
      } else if (this.tempIndustry1) {
        if (this.selectedIndustry.indexOf(this.tempIndustry1) != -1) {
          this.$popupAlert({
            msg: '이미 선택한 카테고리입니다',
          });
          return;
        }
        this.selectedIndustry.push(this.tempIndustry1);
      } else {
        this.$popupAlert({
          msg: '카테고리를 선택하세요',
        });
        return;
      }
    },

    fnRemoveIndustry(idx) {
      this.selectedIndustry.splice(idx, 1);
    },

    fnAddTheme() {
      if (this.tempTheme2) {
        if (this.selectedTheme.indexOf(this.tempTheme2) != -1) {
          this.$popupAlert({
            msg: '이미 선택한 카테고리입니다',
          });
          return;
        }
        this.selectedTheme.push(this.tempTheme2);
      } else if (this.tempTheme1) {
        if (this.selectedTheme.indexOf(this.tempTheme1) != -1) {
          this.$popupAlert({
            msg: '이미 선택한 카테고리입니다',
          });
          return;
        }
        this.selectedTheme.push(this.tempTheme1);
      } else {
        this.$popupAlert({
          msg: '카테고리를 선택하세요',
        });
        return;
      }
    },

    fnRemoveTheme(idx) {
      this.selectedTheme.splice(idx, 1);
    },

    fnGetCodeName(codeGroup, code) {
      const list = this.$store.state.openIp.common[codeGroup];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeName : '';
      }
      return '';
    },

    fnGetCatgName(grp, catgNo) {
      let catgGrp = this.$store.state.openIp.catgStore.category[grp];
      for (let i = 0; i < catgGrp.length; i++) {
        if (catgGrp[i].catgNo == catgNo) {
          return catgGrp[i].catgNm;
        }
        let subCatgGrp = catgGrp[i].sub;
        for (let k = 0; k < subCatgGrp.length; k++) {
          if (subCatgGrp[k].catgNo == catgNo) {
            return `${catgGrp[i].catgNm} > ${subCatgGrp[k].catgNm}`;
          }
        }
      }
      return '';
    },
  },
};
</script>
