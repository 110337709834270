<template>
  <div>
    <div class="colb area_tap">
      <div class="taps_btn"></div>
      <div class="c_right">
        <button class="btn_arr" :class="{on:sortAplcUp}" @click="()=>fnSortChange('APLC_LMT')">한도 높은순</button>
        <button class="btn_arr" :class="{on:sortIntrUp}" @click="()=>fnSortChange('LOAN_INTR_RATE')">금리 낮은순</button>
      </div>
    </div>
    <section class="list_goods">
      <article class="unit" v-for="(prdc,idx) in productList" :key="'prdc_'+idx">
        <div class="i_top">
          <h3>{{ prdc.loanFincCompanyCodeNm }} - {{ prdc.loanFincPrdcNm }}</h3>
          <div class="i_right">
            <img
              v-if="bank[prdc.loanFincCompanyCode]&&bank[prdc.loanFincCompanyCode].img"
              class="logo_bank"
              :src="'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/report/img/'+bank[prdc.loanFincCompanyCode].img+'.png'"
              :alt="prdc.loanFincCompanyCodeNm">
          </div>
        </div>
        <dl class="col">
          <dt>금리</dt>
          <dd>{{ prdc.loanIntrRate }}</dd>
        </dl>
        <dl class="col">
          <dt>한도</dt>
          <dd><b>최대 {{ prdc.aplcLmt}}</b></dd>
        </dl>
      </article>
    </section>
  </div>
</template>
<script>
export default {
  name: "tab2",
  props:['customer'],
  data(){
    // TODO : 은행별 이미지가 없음
    return {
      bank:{
        FC01 : {name: '하나은행', img: 'HANA'},
        FC02 : {name: '기업은행', img: 'IBK'},
        FC03 : {name: '우리은행', img: 'Woori'},
        FC04 : {name: '국민은행', img: 'KB'},
        FC05 : {name: '신한은행', img: 'Shinhan'},
        FC06 : {name: 'BNK경남은행', img: null},
        FC07 : {name: 'SC제일은행', img: 'SC_JEIL'},
        FC20 : {name: 'MS저축은행', img: null},
        FC21 : {name: '웰컴저축은행', img: null},
        FC22 : {name: '우리금융저축은행', img: null},
        FC23 : {name: 'SBI저축은행', img: null},
        FC24 : {name: '키움저축은행', img: null},
        FC25 : {name: 'KB저축은행', img: null},
        FC26 : {name: '한국투자저축은행', img: null},
        FC27 : {name: '페퍼저축은행', img: 'PEPPER'},
        FC28 : {name: '다올저축은행', img: 'DAOL'},
        FC29 : {name: '상상인저축은행', img: 'SANGSANGIN'},
      },
      financeParamData:{
        sortColumn: { 한도:'APLC_LMT', 금리:'LOAN_INTR_RATE'}
      },
      financeParam:{sortColumn:'APLC_LMT',sortOrdr:'ASC'},
      financeResult:[],
      bankEx:{}
    }
  },
  computed:{
    codeMap(){
      return this.$store.state.allOk.allOkStore.codeGroup;
    },
    sortData(){
      return this.financeParam
    },
    sortAplcUp(){
      return this.sortData.sortColumn == 'APLC_LMT';
    },
    sortIntrUp(){
      return this.sortData.sortColumn == 'LOAN_INTR_RATE';
    },
    productList() {
      const productList = this.financeResult;
      return productList ? productList : []
    }
  },
  methods:{
    async fetchAction(sortColumn, sortOrdr) {
      const params = {
        ccr : this.findOriginalValue(this.codeMap.ETC500, this.customer.ccrCd),
        rcr : this.findOriginalValue(this.codeMap.ETC600, this.customer.rcrCd),
        sortColumn,
        sortOrdr,
      }
      const result = await this.$store.dispatch('allOk/allOkStore/fetchFinance',params);
      if(result.result)
        this.financeResult = result.data.contents
    },
    fetchFinance() {
      const sortColumn = this.financeParamData.sortColumn.한도;
      const sortOrdr = 'ASC';
      this.fetchAction(sortColumn, sortOrdr)
    },
    fnSortChange( _sortColumn ) {
      const currentSortColumn = this.sortData.sortColumn;
      if ( currentSortColumn !== _sortColumn ) {
        this.sortData.sortColumn = _sortColumn
        const sortColumn = _sortColumn;
        const sortOrdr = this._fnSortOrder(_sortColumn);
        this.fetchAction(sortColumn, sortOrdr)
      }
    },
    _fnSortOrder(sortColumn){
      return ( sortColumn === 'APLC_LMT' ) ? 'ASC' : 'ASC';
    },
    findOriginalValue(codeGrp, value){
      for(let i = 0; i < codeGrp.length ; i++ ){
        if(codeGrp[i].cd === value){
          return codeGrp[i].value01;
        }
      }
      return null;
    }
  },
  async mounted () {
    this.fetchFinance();
    // this.$axios.get('https://c-api.10bagger.co.kr/ao/loan-prdc/code?pCode=FC00').then(res=>{
    //   console.log(res.data)
    //   console.log(res.data.reduce((a, b) => {
    //     a[b.code]={name: b.codeName, img: null}
    //     return a
    //   }, {}))
    // })
  },
}
</script>
