<template>
  <section class="container">
    <div class="tabs1">
      <button v-for="el in tabList" :key="el.id" class="tab" :class="{ on: id == el.id }" v-html="el.name" @click="fnClickTab(el.id)"></button>
    </div>
    <RbpRateTrem v-if="id == 1" />
    <RbpStock v-if="id == 2" />
    <RbpTradingBuy v-if="id == 3" />
    <RbpTradingSell v-if="id == 4" />
    <RbpTradingTotal v-if="id == 5" />
  </section>
</template>

<script>
import RbpRateTrem from '~/components/pages/RbpRateTrem.vue';
import RbpStock from '~/components/pages/RbpStock.vue';
import RbpTradingBuy from '~/components/pages/RbpTradingBuy.vue';
import RbpTradingSell from '~/components/pages/RbpTradingSell.vue';
import RbpTradingTotal from '~/components/pages/RbpTradingTotal.vue';

export default {
  layout: 'none2',
  components: {
    RbpRateTrem,
    RbpStock,
    RbpTradingBuy,
    RbpTradingSell,
    RbpTradingTotal,
  },

  data() {
    return {
      tabList: [
        { id: 1, name: '기간별 수익률' },
        { id: 2, name: '보유종목' },
        { id: 3, name: '매수종목 신호' },
        { id: 4, name: '매도종목 신호' },
        { id: 5, name: '매수매도 종합' },
      ],
      id: 1,
    };
  },

  methods: {
    fnClickTab(id) {
      this.id = id;
    },
  },
};
</script>
