var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_contents contents_col2"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"form_list type2"},[_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("신청일")]),_vm._v(" "),_c('div',{staticClass:"i_wrap mt10"},[_c('span',[_vm._v(_vm._s(_vm.orderDetail.reg_dtm))])])]),_vm._v(" "),_c('li',[_c('h4',[_vm._v("기업명")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v(_vm._s(_vm.orderDetail.co_name))])])]),_vm._v(" "),_c('li',[_c('h4',[_vm._v("담당자")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v(_vm._s(_vm.orderDetail.applicant))])])]),_vm._v(" "),_c('li',[_c('h4',[_vm._v("거래방식")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v(_vm._s(_vm.fnGetCodeName('common', 'TM00', _vm.orderDetail.trd_mthd)))])])]),_vm._v(" "),_c('li',[_c('h4',[_vm._v("거래조건")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v(_vm._s(_vm.fnGetCodeName('common', 'TT00', _vm.orderDetail.trd_trms)))])])]),_vm._v(" "),_c('li',[_c('h4',[_vm._v("지급조건")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('span',[_vm._v(_vm._s(_vm.fnGetCodeName('common', 'PTR00', _vm.orderDetail.pymn_trms)))])])]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("진행상태")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('Select',{attrs:{"itemList":_vm.stateCd,"value":_vm.selectedStateCd,"name":"selectedStateCd"},on:{"update:value":function($event){_vm.selectedStateCd=$event}}})],1)]),_vm._v(" "),_c('li')]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('h3',[_vm._v("새로운 상담 내용")]),_vm._v(" "),_c('ul',{staticClass:"form_list type2"},[_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("상담일시")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('DateRangeTabsSingle',{ref:"dateRangeTabs",on:{"change":({ fromDate }) => {
                this.form.meetDate = fromDate;
              }}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("미팅 고객명")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('Input',{attrs:{"value":_vm.form.meetName,"name":"form.meetName","type":"text","placeholder":"이름을 입력하세요"},on:{"update:value":function($event){return _vm.$set(_vm.form, "meetName", $event)}}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"i_wrap mt5"},[_c('Input',{attrs:{"value":_vm.form.meetPhone,"name":"form.meetPhone","type":"text","placeholder":"전화번호를 입력하세요"},on:{"update:value":function($event){return _vm.$set(_vm.form, "meetPhone", $event)}}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"i_wrap mt5"},[_c('Input',{attrs:{"value":_vm.form.meetEmail,"name":"form.meetEmail","type":"text","placeholder":"이메일을 입력하세요"},on:{"update:value":function($event){return _vm.$set(_vm.form, "meetEmail", $event)}}})],1)]),_vm._v(" "),_c('li',{staticClass:"item_ipt"},[_c('h4',[_vm._v("상담 유형")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('Select',{attrs:{"itemList":[
              { name: '미팅', value: 1 },
              { name: '전화', value: 2 },
              { name: '이메일', value: 3 },
              { name: '기타', value: 4 },
            ],"defaultName":"상담유형을 선택해주세요","value":_vm.form.meetType,"name":"form.meetType"},on:{"update:value":function($event){return _vm.$set(_vm.form, "meetType", $event)}}})],1)]),_vm._v(" "),_c('li',[_c('h4',[_vm._v("상담내용")]),_vm._v(" "),_c('div',{staticClass:"i_wrap"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.meetDesc),expression:"form.meetDesc"}],staticClass:"textarea",staticStyle:{"height":"200px"},attrs:{"placeholder":"상담내용을 입력해주세요"},domProps:{"value":(_vm.form.meetDesc)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "meetDesc", $event.target.value)}}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs_top"},[_c('div',{staticClass:"col"},[_c('span',[_vm._v("2020-01-01")]),_vm._v(" "),_c('span',[_vm._v("(주)위메프")])]),_vm._v(" "),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn b_add"},[_c('b',[_vm._v("새로운 상담 내용")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline"},[_c('div',{staticClass:"unit on"},[_c('div',{staticClass:"u_top colb"},[_c('div',{staticClass:"col"},[_c('strong',[_vm._v("아무나")]),_vm._v(" "),_c('span',[_vm._v("유니콘랜치 삼성전자 담당자")]),_vm._v(" "),_c('button',{staticClass:"btn_phone"},[_vm._v("전화")]),_vm._v(" "),_c('button',{staticClass:"btn_mail"},[_vm._v("메일")]),_vm._v(" "),_c('em',{staticClass:"new"},[_vm._v("new")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_vm._v("2022.01.23")])])]),_vm._v(" "),_c('div',{staticClass:"u_cont"},[_vm._v("대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.")]),_vm._v(" "),_c('div',{staticClass:"u_bottom"})]),_vm._v(" "),_c('div',{staticClass:"unit"},[_c('div',{staticClass:"u_top colb"},[_c('div',{staticClass:"col"},[_c('strong',[_vm._v("아무나")]),_vm._v(" "),_c('span',[_vm._v("유니콘랜치 삼성전자 담당자")]),_vm._v(" "),_c('button',{staticClass:"btn_phone"},[_vm._v("전화")]),_vm._v(" "),_c('button',{staticClass:"btn_mail"},[_vm._v("메일")]),_vm._v(" "),_c('em',{staticClass:"new"},[_vm._v("new")])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('span',[_vm._v("2022.01.23")])])]),_vm._v(" "),_c('div',{staticClass:"u_cont"},[_vm._v("대출 원금 및 이자 연체시, 연체이자가 발생되고 신용등급 또는 개인신용평점이 하락될 수 있으며, 채무/ 신용 상의 불이익이 발생할 수 있으니 주의하시기 바랍니다.")]),_vm._v(" "),_c('div',{staticClass:"u_bottom"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("미팅 고객 "),_c('br'),_vm._v("전화")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("미팅 고객 "),_c('br'),_vm._v("이메일")])
}]

export { render, staticRenderFns }