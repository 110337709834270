<template>
  <section class="container">
    <Location />
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <ol>
          <li>
            <div class="colb">
              <label for="top5"><b>1.Top5:</b></label>
              <div>
                <button class="btn b_refresh" @click="getTop5Stk"><b>새로고침</b></button>
                <button class="btn b_checked" @click="updateValue('top5')"><b>설정</b></button>
              </div>
            </div>
            <MultiStockSearch class="mt10" :stkList.sync="StkListTop5" desc="종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <label for=""><b>2.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[0]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 0)"
              @getKeyword="v => getKeyword('issueSector', v, 0)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[0]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <label for=""><b>3.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[1]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 1)"
              @getKeyword="v => getKeyword('issueSector', v, 1)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[1]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <label for=""><b>4.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[2]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 2)"
              @getKeyword="v => getKeyword('issueSector', v, 2)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[2]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
        </ol>
      </div>
      <div class="right">
        <ol>
          <li>
            <label for=""><b>5.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[3]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 3)"
              @getKeyword="v => getKeyword('issueSector', v, 3)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[3]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <label for=""><b>6.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[4]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 4)"
              @getKeyword="v => getKeyword('issueSector', v, 4)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[4]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <label for=""><b>7.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[5]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 5)"
              @getKeyword="v => getKeyword('issueSector', v, 5)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[5]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <label for=""><b>8.이슈 섹터:</b></label>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="sectorList[6]"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('issueSector', v, 6)"
              @getKeyword="v => getKeyword('issueSector', v, 6)"
            />
            <MultiStockSearch class="mt10" :stkList.sync="issueSectorStk[6]" desc="섹터내 종목 구성 (필수 5개): " />
          </li>
          <li class="mt20">
            <div class="colb">
              <div></div>
              <div>
                <button class="btn b_refresh" @click="setDefault('issueSector')"><b>새로고침</b></button>
                <button class="btn b_checked" @click="updateValue('issueSector')"><b>설정</b></button>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
    <hr />
    <div class="inner contents_col2 bg_white">
      <div class="left">
        <ol>
          <li>
            <div class="colb">
              <label for=""><b>섹터랭킹 기본 선택 섹터:</b></label>
              <div>
                <button class="btn b_refresh" @click="setDefault('defaultSector')"><b>새로고침</b></button>
                <button class="btn b_checked" @click="updateValue('defaultSector')"><b>설정</b></button>
              </div>
            </div>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="defaultSectorList"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('defaultSector', v)"
              @getKeyword="v => getKeyword('defaultSector', v)"
            />
          </li>
          <li class="mt20">
            <div class="colb">
              <label for=""><b>테마랭킹 기본 선택 테마:</b></label>
              <div>
                <button class="btn b_refresh" @click="setDefault('defaultTheme')"><b>새로고침</b></button>
                <button class="btn b_checked" @click="updateValue('defaultTheme')"><b>설정</b></button>
              </div>
            </div>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="defaultThemeList"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('defaultTheme', v)"
              @getKeyword="v => getKeyword('defaultTheme', v)"
            />
          </li>
          <li class="mt20">
            <div class="colb">
              <label for="top5"><b>테마랭킹:</b></label>
              <div>
                <button class="btn b_refresh" @click="setDefault('dispTheme')"><b>새로고침</b></button>
                <button class="btn b_checked" @click="updateValue('dispTheme')"><b>설정</b></button>
              </div>
            </div>
            <SelectSearch
              class="mt10"
              title="선택해주세요."
              :list="dispThemeList"
              :data="{ placeholder: '검색어를 입력해 주세요.' }"
              @change="v => setValue('dispTheme', v)"
              @getKeyword="v => getKeyword('dispTheme', v)"
            />
            <div class="mt10">선택된 {{ dispTheme.length }} 테마 목록</div>
            <ol>
              <li class="mt5" v-for="(el, index) in dispTheme" :key="index">
                <div class="col2">
                  <div class="col">
                    <Input :value="getThemeName(el)" :name="getThemeName(el)" type="text" :disabled="true" />
                  </div>
                  <div class="col">
                    <button class="btn b_sub ml10" @click="removeTheme(index)"><b>제거</b></button>
                  </div>
                </div>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="right"></div>
    </div>
  </section>
</template>

<script>
import Location from '~/components/layouts/LocationDataWrapper';
import SelectSearch from '~/components/pages/tenbagger/SelectSearch';
import MultiStockSearch from '~/components/pages/tenbagger/MultiStockSearch.vue';
import Input from '~/components/common/Input.vue';

export default {
  components: {
    Location,
    SelectSearch,
    MultiStockSearch,
    Input,
  },

  data() {
    return {
      roles: ['TEN_BAG', 'TEN_BAG_NHMTS'],
      StkListTop5: [],
      issueSector: [],
      issueSectorStk: [[], [], [], [], [], [], []],
      sectorList: [[], [], [], [], [], [], []],
      defaultSector: null,
      defaultSectorList: [],
      defaultTheme: null,
      defaultThemeList: [],
      dispTheme: [],
      dispThemeList: [],
    };
  },

  methods: {
    setValue(type, value, idx) {
      if (type === 'issueSector') {
        this.issueSector[idx] = value;
        this.getIssueSectorStk(value, idx);
      } else if (type === 'defaultSector') {
        this.defaultSector = value;
      } else if (type === 'defaultTheme') {
        this.defaultTheme = value;
      } else if (type === 'dispTheme') {
        if (this.dispTheme.indexOf(value) == -1) {
          this.dispTheme.unshift(value);
        } else {
          this.$popupAlert({
            msg: '이미 선택한 테마입니다',
          });
        }
      }
    },

    getKeyword(type, value, idx) {
      if (type === 'issueSector') {
        let filterList = this.$store.state.tenbagger.nhmts.sectorList.filter(el => el.name.includes(value));
        this.sectorList.splice(idx, 1, JSON.parse(JSON.stringify(filterList)));
        this.setValue(type, null, idx);
      } else if (type === 'defaultSector') {
        let filterList = this.$store.state.tenbagger.nhmts.sectorList.filter(el => el.name.includes(value));
        this.defaultSectorList = JSON.parse(JSON.stringify(filterList));
        this.setValue(type, null);
      } else if (type === 'defaultTheme') {
        let filterList = this.$store.state.tenbagger.nhmts.themeList.filter(el => el.name.includes(value));
        this.defaultThemeList = JSON.parse(JSON.stringify(filterList));
        this.setValue(type, null);
      } else if (type === 'dispTheme') {
        let filterList = this.$store.state.tenbagger.nhmts.themeList.filter(el => el.name.includes(value));
        this.dispThemeList = JSON.parse(JSON.stringify(filterList));
      }
    },

    setDefault(type) {
      if (type == 'issueSector') {
        let issueSectorList = this.$store.state.tenbagger.nhmts.issueSectorList;
        for (let i = 0; i < 7; i++) {
          let sectorNo = issueSectorList[i];
          let filterList = this.$store.state.tenbagger.nhmts.sectorList.map(el => {
            if (el.value == sectorNo) {
              return {
                ...el,
                select: true,
              };
            } else {
              return el;
            }
          });
          this.sectorList.splice(i, 1, JSON.parse(JSON.stringify(filterList)));
          this.setValue('issueSector', sectorNo, i);
        }
      } else if (type == 'defaultSector') {
        let defaultSector = this.$store.state.tenbagger.nhmts.defaultSector;
        let filterList = this.$store.state.tenbagger.nhmts.sectorList.map(el => {
          if (el.value == defaultSector) {
            return {
              ...el,
              select: true,
            };
          } else {
            return el;
          }
        });
        this.defaultSectorList = JSON.parse(JSON.stringify(filterList));
        this.setValue('defaultSector', defaultSector);
      } else if (type == 'defaultTheme') {
        let defaultTheme = this.$store.state.tenbagger.nhmts.defaultTheme;
        let filterList = this.$store.state.tenbagger.nhmts.themeList.map(el => {
          if (el.value == defaultTheme) {
            return {
              ...el,
              select: true,
            };
          } else {
            return el;
          }
        });
        this.defaultThemeList = JSON.parse(JSON.stringify(filterList));
        this.setValue('defaultTheme', defaultTheme);
      } else if (type == 'dispTheme') {
        let dispTheme = this.$store.state.tenbagger.nhmts.dispThemeList;
        this.dispTheme = JSON.parse(JSON.stringify(dispTheme));

        let themeList = this.$store.state.tenbagger.nhmts.themeList;
        this.dispThemeList = JSON.parse(JSON.stringify(themeList));
      }
    },

    async updateValue(type) {
      if (type === 'top5') {
        if (this.StkListTop5.length < 1) {
          this.$popupAlert({
            msg: 'TOP5 종목을 설정하세요',
          });
          return;
        }
        let isuSrtCds = this.StkListTop5.map(el => el.isuSrtCd);
        let payload = {
          sectorNo: 0,
          securityType: 'NH',
          isuSrtCds: isuSrtCds,
        };
        const { result, data } = await this.$store.dispatch('tenbagger/nhmts/addIssueSectorStk', payload);
        if (result) {
          this.$popupAlert({
            msg: 'top5종목 설정이 완료되었습니다',
          });
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (type === 'issueSector') {
        if (this.issueSector.indexOf(null) != -1) {
          this.$popupAlert({
            msg: '이슈섹터를 모두 선택하세요',
          });
          return;
        }

        let isDup = this.issueSector.some(x => {
          return this.issueSector.indexOf(x) !== this.issueSector.lastIndexOf(x);
        });
        if (isDup) {
          this.$popupAlert({
            msg: '섹터가 중복으로 선택되었습니다',
          });
          return;
        }

        let isEmpty = this.issueSectorStk.some(el => {
          return el.length < 1;
        });
        if (isEmpty) {
          this.$popupAlert({
            msg: '이슈섹터 종목을 설정하세요',
          });
          return;
        }

        const res1 = await this.$store.dispatch('tenbagger/nhmts/updateIssueSector', { sectorNos: this.issueSector });
        if (res1.result) {
          this.$store.dispatch('tenbagger/nhmts/fetchSectorList');
          for (let i = 0; i < 7; i++) {
            let isuSrtCds = this.issueSectorStk[i].map(el => el.isuSrtCd);
            let payload = {
              sectorNo: this.issueSector[i],
              securityType: 'NH',
              isuSrtCds: isuSrtCds,
            };
            const res2 = await this.$store.dispatch('tenbagger/nhmts/addIssueSectorStk', payload);
            if (!res2.result) {
              this.$popupAlert({
                msg: `이슈섹터 종목설정을 실패했습니다(${res2.data.error.msg})`,
              });
              return;
            }
          }
          this.$popupAlert({
            msg: `설정이 완료되었습니다`,
          });
        } else {
          this.$popupAlert({
            msg: `이슈섹터 설정을 실패했습니다(${res1.data.error.msg})`,
          });
        }
      } else if (type === 'defaultSector') {
        if (!this.defaultSector) {
          this.$popupAlert({
            msg: `값을 설정하세요`,
          });
          return;
        }

        let { result, data } = await this.$store.dispatch('tenbagger/nhmts/updateDefaultSector', this.defaultSector);
        if (result) {
          this.$popupAlert({
            msg: `설정이 완료되었습니다`,
          });
          this.$store.dispatch('tenbagger/nhmts/fetchSectorList');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (type === 'defaultTheme') {
        if (!this.defaultTheme) {
          this.$popupAlert({
            msg: `값을 설정하세요`,
          });
          return;
        }

        let { result, data } = await this.$store.dispatch('tenbagger/nhmts/updateDefaultTheme', this.defaultTheme);
        if (result) {
          this.$popupAlert({
            msg: `설정이 완료되었습니다`,
          });
          this.$store.dispatch('tenbagger/nhmts/fetchThemeList');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      } else if (type === 'dispTheme') {
        if (this.dispTheme.length < 1) {
          this.$popupAlert({
            msg: `테마를 선택해주세요`,
          });
        }

        let { result, data } = await this.$store.dispatch('tenbagger/nhmts/updateDispTheme', {
          themeNos: this.dispTheme,
        });
        if (result) {
          this.$popupAlert({
            msg: `설정이 완료되었습니다`,
          });
          this.$store.dispatch('tenbagger/nhmts/fetchThemeList');
        } else {
          this.$popupAlert({
            msg: `설정을 실패했습니다(${data.error.msg})`,
          });
        }
      }
    },

    async getIssueSectorStk(sectorNo, idx) {
      if (!sectorNo) {
        return;
      }

      let payload = {
        sectorNo: sectorNo,
        form: {
          pageNo: 1,
          pageSize: 5,
          securityType: 'NH',
        },
      };
      let { result, data } = await this.$store.dispatch('tenbagger/skmts/fetchIssueSectorStk', payload);
      if (result) {
        let stks = data.contents.stks.map(el => ({
          isuSrtCd: el.isuSrtCd,
          isuKorNm: el.isuKorNm,
        }));
        this.issueSectorStk.splice(idx, 1, stks);
      } else {
        this.$popupAlert({
          msg: `이슈섹터 종목을 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    async getTop5Stk() {
      let payload = {
        sectorNo: 0,
        form: {
          pageNo: 1,
          pageSize: 5,
          securityType: 'NH',
        },
      };
      let { result, data } = await this.$store.dispatch('tenbagger/skmts/fetchIssueSectorStk', payload);
      if (result) {
        this.StkListTop5 = data.contents.stks.map(el => ({
          isuSrtCd: el.isuSrtCd,
          isuKorNm: el.isuKorNm,
        }));
      } else {
        this.$popupAlert({
          msg: `top5 종목을 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    getThemeName(themeNo) {
      return this.$store.state.tenbagger.nhmts.themeList.find(el => el.value == themeNo).name;
    },

    removeTheme(index) {
      this.dispTheme.splice(index, 1);
    },
  },

  async mounted() {
    let result1 = await this.$store.dispatch('tenbagger/nhmts/fetchSectorList');
    if (result1.result) {
      this.getTop5Stk();
      this.setDefault('issueSector');
      this.setDefault('defaultSector');
    } else {
      this.$popupAlert({
        msg: `섹터정보를 가져오는데 실패했습니다(${result1.data.error.msg})`,
      });
      return;
    }

    let result2 = await this.$store.dispatch('tenbagger/nhmts/fetchThemeList');
    if (result2.result) {
      this.setDefault('defaultTheme');
      this.setDefault('dispTheme');
    } else {
      this.$popupAlert({
        msg: `테마정보를 가져오는데 실패했습니다(${result1.data.error.msg})`,
      });
      return;
    }
  },
};
</script>
