<template>
  <div class="popup p_cs">
    <div class="p_wrap">
      <div class="p_top">
        <h2>소속종목</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <LoadingSpinner class="mt5 mr5" v-if="loading1 || loading2" />
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickSave(true)">저장 후 계속</button>
              <button to="" class="btn" @click="fnClickSave(false)">저장</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents contents_col2">
            <div class="left">
              <div class="colb">
                <h3>선택한 종목({{ selectedStk.length }}개)</h3>
                <button class="btn b_refresh" @click="fnClickRefresh">새로고침</button>
              </div>
              <div class="contents mt5">
                <div class="tbl_area" style="min-width: auto">
                  <table class="tbl">
                    <colgroup>
                      <col width="30%" />
                      <col width="60%" />
                      <col width="10%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <b>종목코드</b>
                        </th>
                        <th>
                          <b>종목명</b>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in selectedStk" :key="item.isuSrtCd">
                        <td>{{ item.isuSrtCd }}</td>
                        <td>
                          {{ item.isuKorNm }}
                        </td>
                        <td>
                          <button class="btn dark" @click="fnRemoveStk(idx)">-</button>
                        </td>
                      </tr>
                      <!-- <tr v-if="sectorStks.length === 0">
                        <td colspan="7">데이터가 없습니다.</td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="right no_scroll">
              <h3>대상 종목</h3>
              <div class="area_search mt5">
                <div class="unit">
                  <label class="tit" for="searchWrd">종목명,<br />종목코드</label>
                  <Input id="searchWrd" :value.sync="search.searchWrd" name="search.searchWrd" type="text" shape="search" placeholder="종목명 또는 종목코드를 입력하세요." @enter="fnClickSearch" />
                </div>
              </div>
              <div class="contents">
                <div class="tbl_area" style="min-width: auto">
                  <table class="tbl">
                    <colgroup>
                      <col width="30%" />
                      <col width="60%" />
                      <col width="10%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <b>종목코드</b>
                          <div class="bx_srot">
                            <button
                              class="btn_up"
                              :class="{
                                on: form.sortColumn !== 'ISU_SRT_CD' || form.sortOrdr === 'ASC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_SRT_CD', sortOrdr: 'ASC' })"
                            >
                              오름차순정렬
                            </button>
                            <button
                              class="btn_down"
                              :class="{
                                on: form.sortColumn !== 'ISU_SRT_CD' || form.sortOrdr === 'DESC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_SRT_CD', sortOrdr: 'DESC' })"
                            >
                              내림차순정렬
                            </button>
                          </div>
                        </th>
                        <th>
                          <b>종목명</b>
                          <div class="bx_srot">
                            <button
                              class="btn_up"
                              :class="{
                                on: form.sortColumn !== 'ISU_KOR_NM' || form.sortOrdr === 'ASC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_KOR_NM', sortOrdr: 'ASC' })"
                            >
                              오름차순정렬
                            </button>
                            <button
                              class="btn_down"
                              :class="{
                                on: form.sortColumn !== 'ISU_KOR_NM' || form.sortOrdr === 'DESC',
                              }"
                              @click="() => fnClickSort({ sortColumn: 'ISU_KOR_NM', sortOrdr: 'DESC' })"
                            >
                              내림차순정렬
                            </button>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in contents" :key="item.isuSrtCd">
                        <td>{{ item.isuSrtCd }}</td>
                        <td>
                          {{ item.isuKorNm }}
                        </td>
                        <td>
                          <button class="btn" @click="fnClickStk(item)">+</button>
                        </td>
                      </tr>
                      <tr v-if="pagination.totalCount == 0">
                        <td colspan="7">데이터가 없습니다.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Pagination
                  :page="form.pageNo"
                  :perPage="form.pageSize"
                  :totalCount="pagination.totalCount"
                  @change="
                    p => {
                      if (!this.loading1) {
                        this.form.pageNo = p;
                        this.fnFetchStk();
                      }
                    }
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import Pagination from '~/components/common/Pagination';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';

export default {
  props: ['selectedTheme'],
  data() {
    return {
      themeNo: this.selectedTheme,
      form: {
        pageNo: 1,
        pageSize: 10,
        searchWrd: null,
        arrantrdYn: 'N',
        sortColumn: 'ISU_SRT_CD',
        sortOrdr: 'ASC',
      },
      search: {
        searchWrd: null,
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      themeStk: [],
      selectedStk: [],
      loading1: false,
      loading2: false,
    };
  },

  components: {
    Input,
    Pagination,
    LoadingSpinner,
  },

  methods: {
    fnClosePop() {
      this.$emit('closePop');
    },

    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading1) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchStk();
      }
    },

    fnClickStk(stk) {
      const canAdd = this.selectedStk.find(el => el.isuSrtCd === stk.isuSrtCd);
      if (!canAdd) {
        this.selectedStk.unshift({ bizNo: stk.corp.bizno, isuSrtCd: stk.isuSrtCd, isuKorNm: stk.isuKorNm });
      } else {
        this.$popupAlert({
          msg: '이미 선택한 종목입니다',
        });
      }
    },

    async fnClickSave(skipClose) {
      if (this.loading2) return;

      if (this.selectedStk.length < 1) {
        this.$popupAlert({
          msg: '1개 이상 선택하세요',
        });
        return;
      }

      this.loading2 = true;

      let payload = {
        themeNo: this.themeNo,
        bizNos: this.selectedStk.map(el => el.bizNo),
      };
      const { result, data } = await this.$store.dispatch('tenbagger/theme/addCorpThemes', payload);
      if (result) {
        this.$popupAlert({
          title: '알림',
          msg: '저장이 완료되었습니다',
        });
      } else {
        this.$popupAlert({
          msg: `저장에 실패하였습니다(${data.error.msg})`,
        });
        this.loading2 = false;
        return;
      }

      if (skipClose) {
        await this.fnFetchThemeStk();
        this.loading2 = false;
      } else {
        this.fnClosePop();
      }
    },

    fnRemoveStk(index) {
      this.selectedStk.splice(index, 1);
    },

    fnClickSearch() {
      if (!this.loading1) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchStk();
      }
    },

    async fnFetchStk() {
      this.loading1 = true;
      const { result, data } = await this.$store.dispatch('tenbagger/fetchStk', this.form);
      this.loading1 = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `종목리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    async fnFetchThemeStk() {
      let form = {
        pageNo: 1,
        pageSize: 500,
        searchBelongThemeNo: this.themeNo,
        sortColumn: 'ISU_SRT_CD',
        sortOrdr: 'ASC',
      };
      this.loading2 = true;
      const { result, data } = await this.$store.dispatch('tenbagger/fetchStk', form);
      this.loading2 = false;
      if (result) {
        this.themeStk = data.contents;
        this.selectedStk = data.contents.map(el => ({
          bizNo: el.corp.bizno,
          isuSrtCd: el.isuSrtCd,
          isuKorNm: el.isuKorNm,
        }));
      } else {
        this.$popupAlert({
          msg: `소속테마의 종목리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },

    fnClickRefresh() {
      this.selectedStk = this.themeStk.map(el => ({
        bizNo: el.corp.bizno,
        isuSrtCd: el.isuSrtCd,
        isuKorNm: el.isuKorNm,
      }));
    },
  },

  mounted() {
    this.fnFetchThemeStk();
    this.fnFetchStk();
  },
};
</script>
