<template>
  <div class="popup p_create">
    <div class="p_wrap">
      <div class="p_top">
        <h2>특허 선택</h2>
      </div>
      <div class="p_container">
        <div class="p_inner">
          <div class="c_top">
            <div class="tabs1"></div>
            <div class="btns mb10">
              <button to="" class="btn" @click="fnClickAdd">확인</button>
              <button to="" class="btn dark" @click="fnClosePop">취소</button>
            </div>
          </div>
          <div class="c_contents">
            <div class="area_search">
              <div class="unit">
                <label class="tit" for="searchPatn">특허번호</label>
                <Input id="searchPatn" :value.sync="search.searchPatn" name="search.searchPatn" type="text" placeholder="특허번호를 입력하세요." @enter="fnClickSearch" />
              </div>
              <div class="unit">
                <label class="tit" for="searchCd">IPC코드</label>
                <Input id="searchCd" :value.sync="search.searchCd" name="search.searchCd" type="text" placeholder="IPC코드를 입력하세요." @enter="fnClickSearch" />
              </div>
              <div class="unit">
                <label class="tit" for="searchEvGrade">등급</label>
                <Select id="searchEvGrade" :itemList="grades" :value.sync="search.searchEvGrade" name="search.searchEvGrade" type="text" />
              </div>
              <div class="unit">
                <label class="tit" for="searchStatus">상태</label>
                <Select id="searchStatus" :itemList="status" :value.sync="search.searchStatus" name="search.searchStatus" type="text" />
              </div>
              <div class="unit">
                <label class="tit" for="searchHldr">권리자</label>
                <Input id="searchHldr" :value.sync="search.searchHldr" name="search.searchHldr" type="text" placeholder="권리자를 입력하세요." @enter="fnClickSearch" />
              </div>
              <div class="unit">
                <label class="tit" for="searchAplc">출원인</label>
                <Input id="searchAplc" :value.sync="search.searchAplc" name="search.searchAplc" type="text" placeholder="출원인을 입력하세요." @enter="fnClickSearch" />
              </div>
              <div class="unit">
                <label class="tit" for="searchWrd">명칭</label>
                <Input id="searchWrd" :value.sync="search.searchWrd" name="search.searchWrd" type="text" placeholder="단어를 입력하세요." @enter="fnClickSearch" />
              </div>
            </div>
            <div class="colb mb5">
              <div class="col"></div>
              <div class="col unit_page">
                <LoadingSpinner class="mr5" v-if="loading" />
                <h4 class="l_tit">페이지당 개수</h4>
                <Select
                  id="pageSize"
                  title="20개"
                  :itemList="pageSizeList"
                  :value="form.pageSize"
                  @change="
                    item => {
                      if (!this.loading) {
                        this.form.pageNo = 1;
                        this.form.pageSize = item.value;
                        this.fnFetchPatn();
                      }
                    }
                  "
                />
                <button class="btn" @click="fnClickSearch">검색</button>
              </div>
            </div>
            <div class="contents">
              <div class="tbl_area" style="min-width: auto">
                <table class="tbl">
                  <colgroup>
                    <col width="10%" />
                    <col width="35%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="5%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th><b>특허번호</b></th>
                      <th><b>명칭</b></th>
                      <th><b>등급</b></th>
                      <th><b>IPC</b></th>
                      <th><b>권리자</b></th>
                      <th><b>출원인</b></th>
                      <th><b>상태</b></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in contents" :key="item.patnNo">
                      <td>{{ item.patn }}</td>
                      <td>{{ item.patnTitle }}</td>
                      <td>{{ item.evGrade }}</td>
                      <td>{{ item.ipcCd }}</td>
                      <td>{{ item.hldr }}</td>
                      <td>{{ item.aplc }}</td>
                      <td>{{ fnGetCodeName('PAS00', item.patnStatus) }}</td>
                      <td>
                        <button class="btn" @click="fnClickPatn(item)">+</button>
                      </td>
                    </tr>
                    <tr v-if="contents.length === 0">
                      <td colspan="8">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :page="form.pageNo"
                :perPage="form.pageSize"
                :totalCount="pagination.totalCount"
                @change="
                  p => {
                    if (!this.loading) {
                      this.form.pageNo = p;
                      this.fnFetchPatn();
                    }
                  }
                "
              />
            </div>
            <div>
              <h3>선택한 특허</h3>
              <ul class="form_list">
                <li>
                  <div class="list_patent">
                    <ItemPatent v-for="(el, idx) in selectedPatnList" :key="el.patn" :data="{ id: el.patn, tit: el.patnTitle }" @delete="fnRemovePatn(idx)" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '~/components/common/Input';
import Pagination from '~/components/common/Pagination';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';
import Select from '~/components/common/Select';
import ItemPatent from '~/components/common/ItemPatent.vue';

export default {
  props: ['selectedPatn', 'selectType'],
  data() {
    return {
      form: {
        pageNo: 1,
        pageSize: 10,
        searchPatn: null,
        searchCd: null,
        searchEvGrade: null,
        searchStatus: null,
        searchHldr: null,
        searchAplc: null,
        searchWrd: null,
      },
      search: {
        searchPatn: null,
        searchCd: null,
        searchEvGrade: null,
        searchStatus: null,
        searchHldr: null,
        searchAplc: null,
        searchWrd: null,
      },
      contents: [],
      pagination: {
        totalCount: 0,
      },
      selectedPatnList: JSON.parse(JSON.stringify(this.selectedPatn)),
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
      ],
      loading: false,
    };
  },

  computed: {
    grades() {
      let grades = this.$store.state.openIp.etc.grade ? this.$store.state.openIp.etc.grade.map(el => ({ name: el.codeName, value: el.code })) : [];
      grades.unshift({ name: '전체', value: null });
      return grades;
    },
    status() {
      let status = this.$store.state.openIp.common.PAS00 ? this.$store.state.openIp.common.PAS00.map(el => ({ name: el.codeDesc, value: el.code })) : [];
      status.unshift({ name: '전체', value: null });
      return status;
    },
  },

  components: {
    Input,
    Pagination,
    LoadingSpinner,
    Select,
    ItemPatent,
  },

  methods: {
    fnClosePop() {
      this.$emit('closePop');
    },

    fnClickPatn(item) {
      const canAdd = this.selectedPatnList.find(el => el.patnNo === item.patnNo);
      if (!canAdd) {
        if (this.selectType == 'PT01') {
          this.selectedPatnList.splice(0);
        }
        this.selectedPatnList.push(item);
      } else {
        this.$popupAlert({
          msg: '이미 선택한 특허입니다',
        });
      }
    },

    fnClickAdd() {
      if (this.selectedPatnList.length < 1) {
        this.$popupAlert({
          msg: '특허를 선택하세요',
        });
        return;
      }

      this.$emit('update:selectedPatn', this.selectedPatnList);
      this.fnClosePop();
    },

    fnRemovePatn(index) {
      this.selectedPatnList.splice(index, 1);
    },

    fnClickSearch() {
      if (!this.loading) {
        this.form.pageNo = 1;
        Object.keys(this.search).forEach(key => {
          this.form[key] = this.search[key];
        });
        this.fnFetchPatn();
      }
    },

    async fnFetchPatn() {
      this.loading = true;
      const { result, data } = await this.$store.dispatch('openIp/patnStore/fetchPatn', this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination = data.pagination;
      } else {
        this.$popupAlert({
          msg: `특허리스트를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },
    fnGetCodeName(codeGroup, code) {
      const list = this.$store.state.openIp.common[codeGroup];
      if (list) {
        const found = list.find(item => item.code === code);
        return found ? found.codeDesc : '';
      }
      return '';
    },
  },

  mounted() {
    this.fnFetchPatn();
  },
};
</script>
