<template>
  <div class="area_menu">
    <div class="am_top">
      <h1 class="logo"><nuxt-link to="/">유니콘랜치</nuxt-link></h1>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <!-- <Alert v-if="$store.state.ui.popAlertData.state == true" :key="$store.state.ui.popAlertData.state"/> -->
        <button class="btn_close" @click="fnClosePop" v-if="view == 'mobile'" :key="$store.state.ui.view">닫기</button>
      </transition>
    </div>
    <nav>
      <ul v-if="!toggled" class="list_menu">
        <li class="dep1" v-for="mainMenu in menus" :key="mainMenu.id">
          <button class="menu" @click="(e)=>fnToggle(e, mainMenu.id)">{{mainMenu.title}}</button>
          <ul class="dep2">
            <li v-for="item in mainMenu.subList" :key="item.id">
              <button
                :class="{'sub_menu': true, 'on': menuId==item.id}"
                @click="(e)=>fnSubToggle(e, item.id, item.page_url)"
              >
                {{ item.title }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <ul v-if="toggled" class="list_menu">
        <li class="dep1 on">
          <button class="menu" v-for="menu in menus" :key="menu.id" @click="(e)=>fnToggle(e, menu.id)">{{menu.title}}</button>
          <ul class="dep2">
            <li v-for="item in menu" :key="item.id">
              <button
                class="sub_menu on"
                @click="(e)=>fnSubToggle(e, item.id, item.page_url)"
              >
                {{ item.title }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['classNm'],
  data() {
    return {
      view: undefined,
      menuId: '',
      toggled: true
    };
  },
  computed: {
    menus() {
      const menus = this.$store.state.backoffice.configMenuStore.menus;
      return menus;
    },
    menu() {
      if(this.$store.state.authUser){
        if(!this.$store.state.authUser.redirect_url){
          if (this.$store.state.backoffice.configMenuStore.selectedRootMenu)
            return this.$store.state.backoffice.configMenuStore.selectedRootMenu.subList;
          return [];
        } else {
          const rootMenu = this.$store.state.backoffice.configMenuStore.menus[0];
          if(rootMenu){
            const depth1Menu = rootMenu.subList
            return depth1Menu
          }
        }
      }
      return []
    },
  },
  components: {},
  mounted() {
    if(this.$store.state.authUser){
      if(this.$store.state.authUser.redirect_url){
        this.toggled=true
      } else {
        this.toggled = false
      }
    } else
      this.toggled = false
    this.view = this.$store.state.ui.view || "mobile";
    if(this.$store.state.ui.view == "pc") this.$store.dispatch("ui/setMenuState", true);
    window.addEventListener("resize",() => {
      this.view = this.$store.state.ui.view;
      if(this.view == "mobile") this.$store.dispatch("ui/setMenuState", false);
    },true);
    $nuxt.$router.beforeEach((to, from, next) => {
    if(this.$store.state.ui.view == "mobile") this.fnClosePop();
      next();
    });
  },
  methods: {
    fnToggle(e, id) {
      let target = e.target.parentNode;
      let on = target.classList.contains('on');
      if (on == true) {
        target.classList.remove('on');
      } else {
        target.classList.add('on');
      }
      this.$store.commit('backoffice/configMenuStore/SELECT_ROOT_MENU', id);
      // document.querySelector(".btn_top").classList.add("show");
    },
    fnClosePop() {
      this.$store.dispatch('ui/setMenuState', false);
    },
    fnSubToggle(e, id, url){
      if(!this.$store.state.authUser.redirect_url){
        this.menuId=id;
        this.$store.commit('backoffice/configMenuStore/SELECT_DEPTH1_MENU', id);
      }
      this.$store.dispatch('ui/setPopCsState', false);
      if(url=='/allOk/customers'){
        window.open(process.env.allOkAdmin)
      } else{
        this.$router.push(`${url}`)
      }
    }
  },
};
</script>
