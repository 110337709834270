<template>
  <section class="container">
    <Location />
    <div class="inner bg_white">
      <div class="colb">
        <div class="top">
          <button class="btn" @click="fnChangePopState('popStateCreate', true)">새로운 뉴스</button>
          <button class="btn" @click="fnClickOpen">열기</button>
          <button class="btn" @click="fnClickDelete">삭제</button>
          <strong class="ml10 mt15">
            검색결과 : 현재페이지 [ {{ Math.min(form.pageSize * (form.pageNo - 1) + 1, pagination.totalCount) }} ~ {{ Math.min(form.pageSize * form.pageNo, pagination.totalCount) }} ] / 전체 : [
            {{ pagination.totalCount }} ]
          </strong>
        </div>
        <div class="col unit_page">
          <LoadingSpinner class="mr5" v-if="loading" />
          <h4 class="l_tit ml10">페이지당 개수 :</h4>
          <Select
            id="pageSize"
            title="20개"
            :itemList="pageSizeList"
            :value="form.pageSize"
            @change="
              item => {
                if (!this.loading) {
                  this.form.pageNo = 1;
                  this.form.pageSize = item.value;
                  this.fnFetchData();
                }
              }
            " />
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <colgroup>
              <col v-for="(el, idx) in headers" :key="idx" :width="el.colWidth" />
            </colgroup>
            <thead>
              <tr>
                <th v-for="(el, idx) in headers" :key="idx">
                  <b>{{ el.colName }}</b>
                  <div class="bx_srot" v-if="el.colSortNm">
                    <button
                      class="btn_up"
                      :class="{
                        on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'ASC',
                      }"
                      @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'ASC' })">
                      오름차순정렬
                    </button>
                    <button
                      class="btn_down"
                      :class="{
                        on: form.sortColumn !== el.colSortNm || form.sortOrdr === 'DESC',
                      }"
                      @click="() => fnClickSort({ sortColumn: el.colSortNm, sortOrdr: 'DESC' })">
                      내림차순정렬
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contents" :key="item.id" :class="{ on: item.id === selectedNews }" @click="fnClickNews(item.id)" @dblclick="fnClickOpen">
                <td>{{ item.id }}</td>
                <td><img :src="item.attributes.ThumbnailUrl" style="width: 100px; height: 100px; aspect-ratio: 1" /></td>
                <td>{{ item.attributes.Title }}</td>
                <td>{{ item.attributes.updatedAt && fnUtcToDate(item.attributes.updatedAt) }}</td>
                <td>{{ item.attributes.createdAt && fnUtcToDate(item.attributes.createdAt) }}</td>
              </tr>
              <tr v-if="pagination.totalCount == 0">
                <td colspan="5">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :page="form.pageNo"
          :perPage="form.pageSize"
          :totalCount="pagination.totalCount"
          @change="
            p => {
              if (!this.loading) {
                this.form.pageNo = p;
                this.fnFetchData();
              }
            }
          " />
      </div>
    </div>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <UrnewsCreate v-if="popStateCreate == true" @reloadData="fnFetchData" @closePop="fnChangePopState('popStateCreate', false)" />
      <UrnewsDetail v-if="popStateDetail == true" @reloadData="fnFetchData" @closePop="fnChangePopState('popStateDetail', false)" :selectedNews="selectedNews" />
    </transition>
  </section>
</template>

<script>
import urnewsService from '~/service/Cms/urnewsService';
import Location from '~/components/layouts/LocationDataWrapper';
import Pagination from '~/components/common/Pagination';
import Select from '~/components/common/Select';
import LoadingSpinner from '~/components/pages/openip/LoadingSpinner';
import UrnewsCreate from '~/components/pages/homepage/urnews/UrnewsCreate';
import UrnewsDetail from '~/components/pages/homepage/urnews/UrnewsDetail';

export default {
  components: {
    Location,
    Pagination,
    Select,
    LoadingSpinner,
    UrnewsCreate,
    UrnewsDetail,
  },

  data() {
    return {
      roles: ['UR_HOMEPAGE'],
      headers: [
        { colName: '번호', colSortNm: 'id', colWidth: '5%' },
        { colName: '대표이미지', colSortNm: null, colWidth: '8%' },
        { colName: '제목', colSortNm: 'Title', colWidth: '57%' },
        { colName: '수정일', colSortNm: 'updatedAt', colWidth: '15' },
        { colName: '생성일', colSortNm: 'createdAt', colWidth: '15' },
      ],
      form: {
        pageNo: 1,
        pageSize: 10,
        sortColumn: 'id',
        sortOrdr: 'DESC',
      },
      contents: [],
      pagination: {
        totalCount: null,
      },
      selectedNews: null,
      popStateCreate: false,
      popStateDetail: false,
      pageSizeList: [
        { name: '10개', value: 10 },
        { name: '20개', value: 20 },
        { name: '50개', value: 50 },
        { name: '100개', value: 100 },
      ],
      loading: false,
    };
  },

  methods: {
    fnClickNews(newsNo) {
      this.selectedNews = newsNo;
    },
    fnClickOpen() {
      if (!this.selectedNews) {
        this.$popupAlert({
          msg: '뉴스를 선택해주세요',
        });
        return;
      }
      this.fnChangePopState('popStateDetail', true);
    },
    fnClickDelete() {
      if (!this.selectedNews) {
        this.$popupAlert({
          msg: '뉴스를 선택해주세요',
        });
        return;
      }
      const btn = {
        name: '삭제',
        func: async () => {
          this.$closeAlert();
          const { result } = await urnewsService.deleteNews(this.$axios, this.selectedNews);
          if (result) {
            this.$popupAlert({
              title: '알림',
              msg: '뉴스가 삭제되었습니다',
            });
            this.fnFetchData();
          } else {
            this.$popupAlert({
              msg: '뉴스삭제를 실패했습니다',
            });
          }
        },
      };
      this._confirm('뉴스 삭제', `${this.selectedNews}번 뉴스를 삭제하시겠습니까?`, btn);
    },
    fnChangePopState(key, pop) {
      this[key] = pop;
    },
    fnClickSort({ sortColumn, sortOrdr }) {
      if (!this.loading) {
        if (this.form.sortColumn !== sortColumn) {
          this.form.sortColumn = sortColumn;
          this.form.sortOrdr = sortOrdr;
        } else if (this.form.sortOrdr !== sortOrdr) {
          this.form.sortOrdr = sortOrdr;
        } else {
          this.form.sortColumn = null;
          this.form.sortOrdr = null;
        }
        this.fnFetchData();
      }
    },
    async fnFetchData() {
      this.loading = true;
      const { result, data } = await urnewsService.getNews(this.$axios, this.form);
      this.loading = false;
      if (result) {
        this.contents = data.contents;
        this.pagination.totalCount = data.pagination.total;
        this.selectedNews = null;
      } else {
        this.$popupAlert({
          msg: `데이터를 가져오는데 실패했습니다(${data.error.msg})`,
        });
      }
    },
    fnUtcToDate(utc) {
      const date = new Date(utc);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();

      month = month >= 10 ? month : '0' + month;
      day = day >= 10 ? day : '0' + day;
      hour = hour >= 10 ? hour : '0' + hour;
      minute = minute >= 10 ? minute : '0' + minute;
      second = second >= 10 ? second : '0' + second;

      return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    },
    _confirm(title, msg, btn) {
      let data = {
        state: true,
        type: 'confirm',
        title: title,
        msg: msg,
        btn: btn,
      };
      this.$store.dispatch('ui/setPopAlertData', data);
    },
  },

  mounted() {
    this.fnFetchData();
  },
};
</script>
