<template>
  <section class="container">
    <Location
      :list="[
        {
          name: 'MAIN',
          link: '',
        },
      ]"
    />
    <div class="inner">
      <h1>BACK OFFICE</h1>
    </div>
  </section>
</template>


<script>
import Location from "~/components/layouts/Location";

export default {
  data() {
    return {}
  },
  components: {
    Location,
    // Grid
  },
  methods: {
  }
};
</script>
