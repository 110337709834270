<template>
  <section class="container">
    <Location :list="[{
      name:'OPENIP',
      link:'',
    },{
      name:'거래방식',
      link:'',
    }]"/>
    <div class="inner"> <!-- .no_padding 테이블 들어간 페이지에 추가해주세요 -->
      <div class="area_search">
        <div class="unit">
          <label class="tit" for="search1">사업자번호</label>
          <InputBox
            :data="{
              type: 'text',
              placeholder: '검색어를 입력하세요.',
              id:'search1'
            }"
          />
        </div>
        <div class="unit">
          <label class="tit" for="search1">고객명</label>
          <InputBox
            :data="{
              type: 'text',
              placeholder: '검색어를 입력하세요.',
              id:'search1'
            }"
          />
        </div>
        <div class="unit">
          <label class="tit" for="search2">담당자</label>
          <InputBox
            :data="{
              type: 'text',
              placeholder: '검색어를 입력하세요.',
              id:'search2'
            }"
          />
        </div>
        <br/>
        <div class="unit">
          <h3 class="tit">관리단계</h3>
          <Select
            title="상담 대기 중"
            :list="[
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
                { name: '셀렉트5' },
                { name: '셀렉트6' },
                { name: '셀렉트7' },
                { name: '셀렉트8' },
              ]"
          />
        </div>
        <div class="unit">
          <h3 class="tit">진행단계</h3>
          <Select
            title="실권자미팅시도중"
            :list="[
                { name: '셀렉트1' },
                { name: '셀렉트2' },
                { name: '셀렉트3' },
                { name: '셀렉트4' },
                { name: '셀렉트5' },
                { name: '셀렉트6' },
                { name: '셀렉트7' },
                { name: '셀렉트8' },
              ]"
          />
        </div>
        <div class="unit unit_date">
          <h3 class="tit">기간설정</h3>
          <div class="view_date">
            <span class="start">2022년 6월 16일</span>
            <span class="end">2022년 6월 16일</span>

            <div class="search_date on">
              <div class="s_top">
                <span class="start">2022년 12월 16일</span>
                <span class="end">2022년 12월 33일</span>
              </div>
              <div class="s_cont">
                <div class="item">
                  <h4 class="tit">
                    시작일
                  </h4>
                  <div class="bx">
                    <Select
                      title="2022년"
                      :list="[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                      classNm="ipt_year"
                    />
                    <Select
                      title="12월"
                      :list="[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                      classNm="ipt_month"
                    />
                    <Select
                      title="31일"
                      :list="[
                          { name: '셀렉트1' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                      classNm="ipt_day"
                    />
                  </div>
                </div>
                <div class="item">
                  <h4 class="tit">
                    종료일
                  </h4>
                  <div class="bx">
                    <Select
                      title="2022년"
                      :list="[
                          { name: '2002년' },
                        ]"
                      classNm="ipt_year"
                    />
                    <Select
                      title="12월"
                      :list="[
                          { name: '12월' },
                          { name: '셀렉트2' },
                          { name: '셀렉트3' },
                          { name: '셀렉트4' },
                          { name: '셀렉트5' },
                          { name: '셀렉트6' },
                          { name: '셀렉트7' },
                          { name: '셀렉트8' },
                        ]"
                      classNm="ipt_month"
                    />
                    <Select
                      title="31일"
                      :list="[
                          { name: '31일' },
                        ]"
                      classNm="ipt_day"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <button class="btn l_gray">1개월</button>
          <button class="btn l_gray">3개월</button>
        </div>
      </div>
      <div class="colb">
        <div class="top">
          <button class="btn">상품 등록</button>
          <button class="btn">열기</button>
          <button class="btn dark">삭제</button>
        </div>
        <div class="col unit_page">
          <h4 class="l_tit">페이지당 개수 : </h4>
          <Select
            title="5개"
            :list="[
                { name: '5개' },
                { name: '10개' },
                { name: '15개' },
              ]"
          />
          <button class="btn">검색</button>
        </div>
      </div>
      <div class="contents">
        <div class="tbl_area">
          <table class="tbl">
            <!--
              직접 셀크기를 조정하고 싶을때 사용하면 됩니다.
              이경우 c1~c7 클래스 삭제해주세요
              -->
            <!-- <colgroup>
              <col width="200"/>
              <col width="130"/>
              <col width="110"/>
              <col width="150"/>
              <col width="150"/>
              <col width="140"/>
              <col width="140"/>
            </colgroup> -->
            <thead>
            <tr>
              <th class="c1"><b>사업자번호</b></th>
              <th class="c2"><b>고객명</b></th>
              <th class="c3"><b>담당자</b></th>
              <th class="c4"><b>관리단계</b></th>
              <th class="c5">
                <b>진행상태</b>
                <div class="bx_srot">
                  <button class="btn_up">오름차순정렬</button>
                  <button class="btn_down">내림차순정렬</button>
                </div>
              </th>
              <th class="c6">
                <b>신청일</b>
                <div class="bx_srot">
                  <button class="btn_up">오름차순정렬</button>
                  <button class="btn_down">내림차순정렬</button>
                </div>
              </th>
              <th class="c7">
                <b>마지막처리일</b>
                <div class="bx_srot">
                  <button class="btn_up">오름차순정렬</button>
                  <button class="btn_down">내림차순정렬</button>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="on">
              <td class="c1">519801234</td>
              <td class="c2">히즈케어스(주)</td>
              <td class="c3">나애리</td>
              <td class="c4">상담중</td>
              <td class="c5">실권자미팅시도중</td>
              <td class="c6">2022-09-01</td>
              <td class="c7">2022-09-02</td>
            </tr>
            <tr v-for="i in 9" :key="i">
              <td class="c1">519801234</td>
              <td class="c2">히즈케어스(주)</td>
              <td class="c3">나애리</td>
              <td class="c4">상담중</td>
              <td class="c5">실권자미팅시도중</td>
              <td class="c6">2022-09-01</td>
              <td class="c7">2022-09-02</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <button class="btn_start">맨 앞으로</button>
          <button class="btn_prev">이전</button>
          <a href="#" class="btn_page">1</a>
          <button class="btn_page">2</button>
          <button class="btn_page">3</button>
          <button class="btn_page">4</button>
          <button class="btn_page">5</button>
          <button class="btn_page on">6</button>
          <button class="btn_page">7</button>
          <button class="btn_page">8</button>
          <button class="btn_page">9</button>
          <button class="btn_page">10</button>

          <button class="btn_next">다음</button>
          <button class="btn_end">맨 뒤로</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "~/components/layouts/Header";
import Location from "~/components/layouts/Location";
import InputBox from "~/components/common/Input";
import Select from "~/components/common/Select";

export default {
  data() {
    return {}
  },
  created() {
  },
  components: {
    Header,
    Location,
    InputBox,
    Select
    // Grid
  },
  methods: {
  }
};
</script>
