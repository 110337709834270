<template>
  <div class="slct" :class="[classNm, on]" :style="styleAttr" v-click-outside="fnClickOutside">
    <button class="s_tit" type="button" @click="toggleSlct">{{ name }}</button>
    <ul class="s_cont">
      <li v-for="item in itemList"
        :class="{ select: fnCheckSelected(item.value) }"
        :key="'slt_'+item.value"
        @click="()=>fnClick(item)">
        <span>
          {{ item.name }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    itemList: {
      type:Array,
      required:true,
    },
    classNm:{
      type:String
    },
    // 스타일
    styleAttr:{
      type:Object
    },
    value:{
      required: false
    },
    defaultName:{
      type:String,
      default : '선택해주세요'
    },
    // 사용여부
    disabled:{
      type:Boolean,
      default: false
    },
  },
  data() {
    return {
      on: null,
    }
  },
  methods: {
    onFocus(){
      this.toggleSlct();
    },
    toggleSlct() {
      if(!this.disabled)
        if(this.on === 'on'){
          this.on = null
        }else {
          this.on = 'on'
        }
    },
    fnClick(item) {
      this.toggleSlct();
      this.$emit('update:value', item.value)
      this.$emit('change', item)
    },
    fnCheckSelected(itemValue){
      return itemValue === this.value;
    },
    fnClickOutside() {
      this.on = null;
    },
  },
  computed:{
    name(){
      if(this.itemList){
        const foundItem = this.itemList.find(item => item.value === this.value)
        if(foundItem)
          return foundItem.name;
      }
      return this.defaultName;
    }
  }
};
</script>
